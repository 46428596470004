import { Component, OnInit } from '@angular/core';
import { JobRequisitionModel, JobRequisitionFormBuilder } from '../../shared/models/job-requisition-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { JobRequisitionService } from '../../core/services/job-requisition.service';
import { JobDescriptionService } from '../../core/services/job-description.service';
import { JobTitleService } from '../../core/services/job-title.service';
import { JobPositionService } from '../../core/services/job-position.service';
import { OrganizationService } from '../../core/services/organization.service';
import { DepartmentService } from '../../core/services/department.service';
import { UserService } from '../../core/services/user.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { LoaderService } from '../../shared/factory/loader.service';

@Component({
  selector: 'app-job-requisition-add-edit',
  templateUrl: './job-requisition-add-edit.component.html',
  styleUrls: ['./job-requisition-add-edit.component.css']
})
export class JobRequisitionAddEditComponent implements OnInit {

  jobRequisition: JobRequisitionFormBuilder = new JobRequisitionFormBuilder();
  jobRequisitionModel: JobRequisitionModel = this.jobRequisition.jobRequisitionModel;
  jobRequisitionForm: FormGroup = this.jobRequisition.jobRequisitionForm;
  jobDescriptions = [];
  jobTitles = [];
  jobPositions = [];
  organizations = [];
  departments = [];
  employees = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private jobRequisitionService: JobRequisitionService,
    private jobDescriptionService: JobDescriptionService,
    private jobTitleService: JobTitleService,
    private jobPositionService: JobPositionService,
    private organizationService: OrganizationService,
    private departmentService: DepartmentService,
    private userService: UserService,
    private messageService: MessageService,
    public location: Location,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    (this.router.url.indexOf('/view/') > -1) ? this.jobRequisitionForm.disable() : null;
    this.jobRequisitionModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.fetchJobDescriptions();
    this.fetchJobTitles();
    this.fetchOrganizations();
    this.fetchDepartments();
    this.fetchEmployees();
    this.jobRequisitionModel.id ? this.fetchJobRequisition() : null;
  }

  fetchJobDescriptions() {
    this.jobDescriptionService.fetchJobDescriptions().subscribe((res) => {
      this.jobDescriptions = res;
    });
  }

  fetchOrganizations() {
    this.organizationService.fetchOrganization().subscribe((res) => {
      this.organizations = res;
    });
  }

  fetchJobTitles() {
    this.jobTitleService.fetchJobTitles().subscribe((res) => {
      this.jobTitles = res;
    });
  }

  fetchJobPositionsWithJobTitleId(jobPositionId) {
    this.jobPositionService.fetchJobPositionsWithJobTitleId(jobPositionId).subscribe((res) => {
      this.jobPositions = res;
    });
  }

  fetchDepartments() {
    this.departmentService.fetchDepartments().subscribe((res) => {
      this.departments = res;
    });
  }

  fetchEmployees() {
    this.userService.fetchEmployeesLite().subscribe((res) => {
      this.employees = res;
    });
  }

  fetchJobRequisition() {
    this.jobRequisitionService.fetchJobRequisition(this.jobRequisitionModel.id).subscribe((res: JobRequisitionModel) => {
      this.jobRequisitionModel.id ? this.fetchJobPositionsWithJobTitleId(res.jobPositionId) : null;
      this.jobRequisition.setModelVals(res);
    });
  }

  addUpdateJobRequisition() {
    if (this.jobRequisitionModel.id) {
      this.jobRequisitionService.updateJobRequisition(this.jobRequisitionModel).subscribe((res) => {
        this.messageService.display('Job requisition update is successfull! Job Requisition ID : ' + this.jobRequisitionModel.id, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/job-requisition']);
      });
    } else {
      this.jobRequisitionService.addJobRequisition(this.jobRequisitionModel).subscribe((res) => {
        this.messageService.display('Job requisition add is successfull! Job Requisition ID : ' + res, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/job-requisition']);
      });
    }
  }

}
