import { Component, OnInit, ViewChild } from '@angular/core';
import { ReferFormBuilder, ReferModel } from '../../shared/models/refer-model';
import { CandidateService } from '../../core/services/candidate.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements OnInit {

  @ViewChild('f') myNgForm;
  refer: ReferFormBuilder = new ReferFormBuilder();
  ReferModel: ReferModel = this.refer.referModel;
  referForm: FormGroup = this.refer.referForm;
  selectedFileName = "";
  resumeFile: any = null;

  constructor(
    private employeeService: EmployeeService,
    private candidateService: CandidateService,
    private messageService: MessageService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  onFileInput($event) {
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.resumeFile = $event.srcElement.files[0];
    }
  }

  referFriend() {
    let formData = new FormData();
    if (this.resumeFile !== null) formData.append('resumeFile', this.resumeFile, this.resumeFile.name);
    let candidateDTO = new Blob([JSON.stringify(this.ReferModel)], {
      type: 'application/json'
    });
    formData.append('candidateDTO', candidateDTO);
    this.candidateService.referFriend(formData).subscribe(res => {
      this.myNgForm.resetForm();
      this.messageService.display('You have successfully referred a friend! Refer ID: ' + res, 'OK', 'success');
    });
  }


  findHelpUrlPage(){
    const currentPage = "/#_3_3_refer_a_friend";
   
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
   
  }

}
