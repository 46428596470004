import { Component, OnInit, ViewChild } from '@angular/core';
import { RecruiterService } from '../../core/services/recruiter.service';
import { FormControl } from '@angular/forms';
import { RecruiterModel } from '../../shared/models/recruiter-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-recruiter',
  templateUrl: './recruiter.component.html',
  styleUrls: ['./recruiter.component.css']
})
export class RecruiterComponent implements OnInit {

  displayedColumns = ['id', 'name', 'website', 'phone', 'active', 'action'];
  dataSource: MatTableDataSource<RecruiterModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private recruiterService: RecruiterService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router

  ) { }

  ngOnInit() {
    this.fetchRecruiters();
    this.dataSource = new MatTableDataSource<RecruiterModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchRecruiters() {
    this.recruiterService.fetchRecruiters().subscribe((res: RecruiterModel[]) => {
      this.dataSource = new MatTableDataSource<RecruiterModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_7_6_manage_recruiters";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
