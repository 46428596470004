import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { compareFn } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { HelpService } from '../../core/services/help.service';
import { UserCalendarFormBuilder, UserCalendarModel } from 'src/app/shared/models/user-calendar-model';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';


@Component({
  selector: 'app-link-calendar-add-edit',
  templateUrl: './link-calendar-add-edit.component.html',
  styleUrls: ['./link-calendar-add-edit.component.css']
})
export class LinkCalendarAddEditComponent implements OnInit {

  userCalendar : UserCalendarFormBuilder = new UserCalendarFormBuilder();
  userCalendarModel : UserCalendarModel = this.userCalendar.userCalendarModel;
  userCalendarForm: FormGroup = this.userCalendar.userCalendarForm;
  
  compareFn = compareFn;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private userCalendarService: UserCalendarService,
    private messageService: MessageService,
    private helpService: HelpService,
    public location: Location,

  ) { }

  ngOnInit() {
    this.userCalendarModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    // this.fetchSkills();
  }
  
  // addUpdateJobDescription() {
  //   if (this.jobDescriptionModel.id) {
  //     this.jobDescriptionService.updateJobDescription(this.jobDescriptionModel).subscribe((res) => {
  //       this.messageService.display('Job description update is successfull! Job Description ID : ' + this.jobDescriptionModel.id, 'OK', 'success');
  //       this.router.navigate(['/job-description']);
  //     });
  //   } else {
  //     this.jobDescriptionService.addJobDescription(this.jobDescriptionModel).subscribe((res) => {
  //       this.messageService.display('Job description add is successfull! Job Description ID : ' + res, 'OK', 'success');
  //       this.router.navigate(['/job-description']);
  //     });
  //   }
  // }

  connectToUserCalendar() {
    this.userCalendarModel.calendarEventType = 'INTERVIEW_SCHEDULE';
      this.userCalendarService.connectToUserCalendar(this.userCalendarModel).subscribe((res) => {
        this.messageService.display('User Calendar Linked Successfully. ', 'OK', 'success');
        window.open(res.authenticationUrl);
        this.router.navigate(['/link-calendar']);
      });
  }
  findHelpUrlPage(){
    const currentPage = "/#_4_1_add_job_description";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }
}
