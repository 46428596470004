import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { NotesModel } from 'src/app/shared/models/notes-model';
import { CandidateNotesModel } from 'src/app/shared/models/candidate-notes-model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
    

  private stateUrl = 'api/v1/notes/';

  constructor(private httpRequest: HttpRequestService) { }

  fetchNotes(candidateID): Observable<CandidateNotesModel> {
    return this.httpRequest.get(this.stateUrl + candidateID);
  }
  
  addNotes(notesModel: NotesModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl , notesModel);
  }

  updateNotes(notesModel: NotesModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl , notesModel);
  }

}
