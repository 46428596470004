import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CandidateModel } from 'src/app/shared/models/candidate-model';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { fetchCachedData } from 'src/app/shared/factory/data-caching.service';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.css']
})
export class KanbanBoardComponent implements OnInit {
  pageNo: number = 0;
  pageSize: number = 100;
  totalCount = 0;
  jobRequisitions = fetchCachedData('jobRequisitions');
  selectedJobprofile: string;
  columnName = '';
  kanbanColumns = [];

  allCandidates = [];
  candidates = []; // QUEUED
  screening = []; // SCHEDULED
  interview = []; // SHORTLISTED, ONHOLD
  rejected = []; // OFFER_REJECTED, REJECTED
  offered = []; // OFFERED, OFFER_NOSHOWUP
  confirmed = []; // OFFER_ACCEPTED

  constructor(
    private candidateService: CandidateService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.fetchCandidates(this.pageNo, this.pageSize);
  }

  fetchCandidates(pageNo, pageSize) {
    this.candidateService.fetchCandidates(pageNo, pageSize).subscribe((res: CandidateModel[]) => {
      this.allCandidates = res;
      this.candidates = [];
      this.screening = [];
      this.interview = [];
      this.rejected = [];
      this.offered = [];
      this.confirmed = [];
      this.groupByStatus();
    });
  }

  groupByStatus() {
    if (this.allCandidates) {
      this.allCandidates.forEach((item: CandidateModel) => {
        if (item.candidateStatus === 'QUEUED') {
          this.candidates.push(item);
        }
        if (item.candidateStatus === 'SCHEDULED') {
          this.screening.push(item);
        }
        if (item.candidateStatus === 'SHORTLISTED' || item.candidateStatus === 'ONHOLD') {
          this.interview.push(item);
        }
        if (item.candidateStatus === 'OFFERED' || item.candidateStatus === 'OFFER_NOSHOWUP') {
          this.offered.push(item);
        }
        if (item.candidateStatus === 'OFFER_REJECTED' || item.candidateStatus === 'REJECTED') {
          this.rejected.push(item);
        }
        if (item.candidateStatus === 'OFFER_ACCEPTED') {
          this.confirmed.push(item);
        }
      });
    }
  }

  filterCandidate(requisitionId?) {
    this.selectedJobprofile = requisitionId;
    let obj = {};
    requisitionId ? (obj['requisitionId'] = requisitionId) : null;

    obj != {} ? this.candidateService.fetchCandidateSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.candidateService.fetchCandidateSearchFilter(obj, this.pageNo, this.pageSize).subscribe(res => {
        this.candidates = [];
        this.screening = [];
        this.interview = [];
        this.rejected = [];
        this.offered = [];
        this.confirmed = [];
        this.allCandidates = res;
        this.groupByStatus();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  addColumns(title: string) {
    var column = new Array();
    this.kanbanColumns.push({ ['title']: title, ['columnData']: column });
  }

  deleteColumn(i: number) {
    this.kanbanColumns.splice(i, 1);
  }

}
