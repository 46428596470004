import { EmployeeService } from 'src/app/core/services/employee.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { InterviewFeedbackListModel } from '../../shared/models/candidate-model';
import { HelpService } from '../../core/services/help.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackRequestDialogComponent, RevokeFeedbackComponent } from 'src/app/shared/popups/popups';
import { LoaderService } from 'src/app/shared/factory/loader.service';

@Component({
  selector: 'app-revoke-feedback-request',
  templateUrl: './revoke-feedback-request.component.html',
  styleUrls: ['./revoke-feedback-request.component.css']
})
export class RevokeFeedbackRequestComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['Sr.No','name','interviewerName', 'interviewRoundStatus', 'scheduleDate', 'action'];
  dataSource: MatTableDataSource<InterviewFeedbackListModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dateTimeFormatters = dateTimeFormatters;

  constructor(private helpService: HelpService,
    private candidateService: CandidateService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.fetchPaddingInterviewFeedbacks();
    this.dataSource = new MatTableDataSource<InterviewFeedbackListModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_3_interview_feedback_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  fetchPaddingInterviewFeedbacks() {
    this.candidateService.fetchPaddingInterviewFeedbacks().subscribe(res=>{
      this.dataSource = new MatTableDataSource<InterviewFeedbackListModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  revokeFeedbackDialog(row, index) {
    const dialogRef = this.dialog.open(RevokeFeedbackComponent, {
      disableClose: true,
      width: '30%',
      data: { res: row,
        index: index }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.fetchPaddingInterviewFeedbacks();
        this.loaderService.updateInboxAction(true);
      }
    });
  }

  openFeedbackRequestDialog(roundData) {
    const dialogRef = this.dialog.open(FeedbackRequestDialogComponent, {
      data: { res: roundData }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.fetchPaddingInterviewFeedbacks();
        this.loaderService.updateInboxAction(true);
      };
    });
  }

}
