import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface QualificationModel {
    id: number;
    name: string;
    description: string;
}
export class QualificationFormBuilder {
    qualificationModel: QualificationModel = {
        id: null,
        name: '',
        description: ''
    };
    qualificationForm: FormGroup = new FormGroup({
        name: new FormControl(this.qualificationModel.name, [Validators.required]),
        description: new FormControl(this.qualificationModel.description, [Validators.required])
    });
    constructor() {
        this.qualificationForm.valueChanges.subscribe(val => {
            this.qualificationModel.name = val.name;
            this.qualificationModel.description = val.description;
        });
    }
    setModelVals(res: QualificationModel) {
        this.qualificationForm.controls.name.setValue(res.name);
        this.qualificationForm.controls.description.setValue(res.description);
    }
}
