import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SkillModel } from './skill-model';
import { QualificationModel } from './qualification-model';
export interface JobDescriptionModel {
    id: number;
    name:string,
    description: string;
    skillSet: SkillModel[];
    requiredQualification: QualificationModel[];
}
export class JobDescriptionFormBuilder {
    jobDescriptionModel: JobDescriptionModel = {
        id: null,
        name:'',
        description: '',
        skillSet: [],
        requiredQualification: []
    };
    jobDescriptionForm: FormGroup = new FormGroup({
        name: new FormControl(this.jobDescriptionModel.name, [Validators.required]),
        description: new FormControl(this.jobDescriptionModel.description, [Validators.required]),
        skillSet: new FormControl(this.jobDescriptionModel.skillSet, [Validators.required]),
        requiredQualification: new FormControl(this.jobDescriptionModel.requiredQualification, [Validators.required])
    });
    constructor() {
        this.jobDescriptionForm.valueChanges.subscribe(val => {
            this.jobDescriptionModel.name=val.name;
            this.jobDescriptionModel.description = val.description;
            this.jobDescriptionModel.skillSet = val.skillSet;
            this.jobDescriptionModel.requiredQualification = val.requiredQualification;
        });
    }
    setModelVals(res: JobDescriptionModel) {
        this.jobDescriptionForm.controls.name.setValue(res.name);
        this.jobDescriptionForm.controls.description.setValue(res.description);
        this.jobDescriptionForm.controls.skillSet.setValue(res.skillSet);
        this.jobDescriptionForm.controls.requiredQualification.setValue(res.requiredQualification);
    }
}