import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private activityUrl = 'api/v1/activity';

  constructor(private httpRequestService: HttpRequestService) { }

  getAllActivity(pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequestService.get(this.activityUrl + '?page=' + pageNo + '&size=' + pageSize);
  }
}
