import { MessageService } from './../../shared/factory/message.service';
import { CandidateTagsService } from './../../core/services/candidate-tags.service';
import { CandidateTagsModel } from './../../shared/models/candidate-tags-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-candidate-tags',
  templateUrl: './candidate-tags.component.html',
  styleUrls: ['./candidate-tags.component.css']
})
export class CandidateTagsComponent implements OnInit {

  displayedColumns = ['id', 'status', 'code', 'color', 'action']
  dataSource: MatTableDataSource<CandidateTagsModel>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private tagService: CandidateTagsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.fetchAllCandidateTags();
    this.dataSource = new MatTableDataSource<CandidateTagsModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchAllCandidateTags() {
    this.tagService.fetchAllCandidateTags().subscribe((res: CandidateTagsModel[]) => {
      this.dataSource = new MatTableDataSource<CandidateTagsModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  deleteTagById(tagId: number) {
    this.tagService.deleteCandidateTagsById(tagId).subscribe(res => {
      this.messageService.display('Candidate Tag deleted successfully! Tag ID : ' + tagId, 'OK', 'success');
      this.fetchAllCandidateTags();
    });
  }

}
