import { HelpService } from './../../core/services/help.service';
import { CandidatePoolService } from './../../core/services/candidate-pool.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidatePoolModel } from '../../shared/models/candidate-pool-model';
import { NewCandidateModel, NewCandidateFormBuilder } from '../../shared/models/new-candidate-model';
import { MatSidenav } from '@angular/material/sidenav';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/shared/factory/message.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-candidate-pool',
  templateUrl: './candidate-pool.component.html',
  styleUrls: ['./candidate-pool.component.css']
})
export class CandidatePoolComponent implements OnInit { 

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'category', 'action'];
  dataSource: MatTableDataSource<CandidatePoolModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  newCandidate: NewCandidateFormBuilder = new NewCandidateFormBuilder();
  newCandidateModel: NewCandidateModel = this.newCandidate.newCandidateModel;
  newCandidateForm: FormGroup = this.newCandidate.newCandidateForm;
  candidateName: string;
  selectedFileName = "";
  resumeFile: any = null;
  candidatePoolId:number = null;
  buttonType: string = "";

  constructor( 
    private candidatePoolService: CandidatePoolService,
    private helpService: HelpService,
    private eleRef: ElementRef,
    private messageService: MessageService,) { }

  ngOnInit() {
    this.getCandidatePoolList();
    this.dataSource = new MatTableDataSource<CandidatePoolModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCandidatePoolList() {
    this.candidatePoolService.getCandidatePool().subscribe((res:CandidatePoolModel[]) => {
      this.dataSource = new MatTableDataSource<CandidatePoolModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_6_1_manage_candidates";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  openSidenav(id) {
    this.buttonType = "submit";
    this.candidatePoolId = id;
    this.fetchPoolName(id)
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
    
  }

  closeSidenav() {
    this.buttonType = "reset";
    this.newCandidateForm.reset();
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  fetchPoolName(id) {
    this.candidatePoolService.getCandidatePoolById(id).subscribe(res=> {
      this.candidateName = res.name;
    });
  }

  onFileInput($event) {
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.resumeFile = $event.srcElement.files[0];
    }
  }

    addNewCandidateToPool() {
    this.newCandidateModel.candidatePoolId = this.candidatePoolId;
    let formData: FormData = new FormData();
    var candidateDTOBlob = new Blob([JSON.stringify(this.newCandidateModel)], {
      type: 'application/json'
    });
    formData.append('candidateDTO', candidateDTOBlob);
    if (this.resumeFile !== null) formData.append('resumeFile', this.resumeFile, this.resumeFile.name);
     this.candidatePoolService.addNewCandidateToPool(formData).subscribe(()=>{
      this.messageService.display('Candidate  successfully added to candidate pool! Candidate ID : ' + this.newCandidateModel.candidatePoolId, 'OK', 'success');
    });
    this.closeSidenav();
    }
}

