import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface DepartmentModel {
    id: number;
    name: string;
    description: string;
    code: string;
    organization: {
        id: number;
        name: string;
    };
    headOfDepartment: {
        id: number;
        firstName: string;
        lastName: string;
    };
    timezone: string;
    address: {
        address1: string;
        address2: string;
        country: string;
        state: string;
        city: string;
        zipCode: string;
    };
}

export class DepartmentFormBuilder {
    departmentModel: DepartmentModel = {
        id: null,
        name: '',
        code: '',
        description: '',
        timezone: null,
        address: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: ''
        },
        headOfDepartment: null,
        organization: null
    };
    departmentForm: FormGroup = new FormGroup({
        name: new FormControl(this.departmentModel.name, [Validators.required]),
        timezone: new FormControl(this.departmentModel.timezone),
        description: new FormControl(this.departmentModel.description),
        address1: new FormControl(this.departmentModel.address.address1),
        address2: new FormControl(this.departmentModel.address.address2),
        country: new FormControl(this.departmentModel.address.country),
        state: new FormControl(this.departmentModel.address.state),
        city: new FormControl(this.departmentModel.address.city),
        zipCode: new FormControl(this.departmentModel.address.zipCode),
        hod: new FormControl(this.departmentModel.headOfDepartment, [Validators.required]),
        organization: new FormControl(this.departmentModel.organization, [Validators.required])
    });
    constructor() {
        this.departmentForm.valueChanges.subscribe(val => {
            this.departmentModel.name = val.name;
            this.departmentModel.description = val.description;
            this.departmentModel.timezone = val.timezone;
            this.departmentModel.address.address1 = val.address1;
            this.departmentModel.address.address2 = val.address2;
            this.departmentModel.address.city = val.city;
            this.departmentModel.address.country = val.country;
            this.departmentModel.address.state = val.state;
            this.departmentModel.address.zipCode = val.zipCode;
            this.departmentModel.headOfDepartment = val.hod;
            this.departmentModel.organization = val.organization;
        });
    }
    setModelVals(res: DepartmentModel) {
        this.departmentForm.controls.name.setValue(res.name);
        this.departmentForm.controls.description.setValue(res.description);
        this.departmentForm.controls.timezone.setValue(res.timezone);
        this.departmentForm.controls.address1.setValue(res.address.address1);
        this.departmentForm.controls.address2.setValue(res.address.address2);
        this.departmentForm.controls.country.setValue(res.address.country);
        this.departmentForm.controls.state.setValue(res.address.state);
        this.departmentForm.controls.city.setValue(res.address.city);
        this.departmentForm.controls.zipCode.setValue(res.address.zipCode);
        this.departmentForm.controls.hod.setValue(res.headOfDepartment);
        this.departmentForm.controls.organization.setValue(res.organization);
    }
}