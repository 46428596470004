import { MessageService } from 'src/app/shared/factory/message.service';
import { CandidateTagsService } from './../../core/services/candidate-tags.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CandidateTagsFormBuilder, CandidateTagsModel } from './../../shared/models/candidate-tags-model';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-candidate-tags-add-edit',
  templateUrl: './candidate-tags-add-edit.component.html',
  styleUrls: ['./candidate-tags-add-edit.component.css']
})
export class CandidateTagsAddEditComponent implements OnInit {
  
  candidateFormBuilder: CandidateTagsFormBuilder = new CandidateTagsFormBuilder();
  tagsModel: CandidateTagsModel = this.candidateFormBuilder.candidateTagsModel;
  candidateForm: FormGroup = this.candidateFormBuilder.candidateTagsForm;
  
  public color2: string = '#ebb4b4';

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private candidateTagService: CandidateTagsService,
    private messageService:MessageService,
    private location: Location
  ) { }

  ngOnInit() {
    this.tagsModel.id=parseInt(this._activatedRoute.snapshot.paramMap.get('id'), 0);
    this.tagsModel.id? this.fetchCandidateTag() : null;
  }

  fetchCandidateTag(){
    this.candidateTagService.findCandidateTagsById(this.tagsModel.id).subscribe((res: CandidateTagsModel) => {
      this.candidateFormBuilder.setModelValues(res);
      this.color2 = res.color;
    })
  }

  addOrUpdateCandidateTag(){
    this.tagsModel.color = this.color2;
    if(this.tagsModel.id){
      this.candidateTagService.updateCandidateTag(this.tagsModel).subscribe(res=>{
        this.messageService.display('Candidate Tag update is successfull! Tag ID : ' + this.tagsModel.id, 'OK', 'success');
        this._router.navigate(['/candidate-tags']);
      });
    }else{
      this.candidateTagService.createCandidateTags(this.tagsModel).subscribe(res=>{
        this.messageService.display('Candidate Tag creation is successfull! Tag ID : ' + res, 'OK', 'success');
        this._router.navigate(['/candidate-tags']);
      });
    }
  }

}
