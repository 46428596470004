import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { AppConfigService } from 'src/app/configs';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Observable } from 'rxjs';
import { CandidateQualificationApproval, CandidateQualificationModel } from 'src/app/shared/models/candidate-qualification-model';


@Injectable({
  providedIn: 'root'
})
export class CandidateQualificationService {

  private stateUrl = 'api/v1/candidate';

  constructor(
    private httpRequest: HttpRequestService,
    public _configUrls: AppConfigService,
    private messageService: MessageService

  ) { }


  fetchPendingActionCandidate(): Observable<any> {
    return this.httpRequest.get('api/v1/candidate/inprogress');
  }

  fetchCandidates(pageNo: number, pageSize: number): Observable<CandidateQualificationModel> {
    return this.httpRequest.get(this.stateUrl  + '/fetch-details'  + '?page=' + pageNo + '&size=' + pageSize);
  }

  approveCandidate(candidateQualificationRequestDTO: CandidateQualificationApproval): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/qualification-approve',candidateQualificationRequestDTO);
  }

  rejectCandidate(candidateQualificationRequestDTO: CandidateQualificationApproval): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/qualification-reject',candidateQualificationRequestDTO);
  }

  fetchEmployeesLite(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/candidate-qualification-req-approvers');
  }

 


  sendCandidateQualificationRequest(candidateQualificationRequestDto:CandidateQualificationModel): Observable<any>
  {
  return this.httpRequest.post(this.stateUrl + '/candidate-qualification-validate',candidateQualificationRequestDto)
  }
  
}
