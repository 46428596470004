import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from '../../core/services/help.service';
import { UserCalendarModel } from 'src/app/shared/models/user-calendar-model';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CalendarSummaryComponent, ConfirmationBoxComponent } from '../../shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/factory/loader.service';

@Component({
  selector: 'app-link-calendar',
  templateUrl: './link-calendar.component.html',
  styleUrls: ['./link-calendar.component.css']
})
export class LinkCalendarComponent implements OnInit {

  displayedColumns = ['id', 'calendar_type', 'email_id', 'action'];
  dataSource: MatTableDataSource<UserCalendarModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  hideAddCalenderLinkBtn: boolean = false;

  constructor(
    private userCalendarService: UserCalendarService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.fetchUserCalendars();
    this.dataSource = new MatTableDataSource<UserCalendarModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchUserCalendars() {
    this.userCalendarService.fetchUserCalendars(['INTERVIEW_SCHEDULE']).subscribe((res: UserCalendarModel[]) => {
      if (res.length >= 1) {
        this.hideAddCalenderLinkBtn = true;
      }
      else {
        this.hideAddCalenderLinkBtn = false;
      }
      this.dataSource = new MatTableDataSource<UserCalendarModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  /**
   * @todo : Need to change this help page for User Calendar link to the GMAIL / Outlook
   */
  findHelpUrlPage(){
    const currentPage = "/#_6_8_manage_job_descriptions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  unlinkUserCalendar(rowId){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        disableClose: true,
        width: '30%',
        data: { calendarId : rowId, title: 'Confirm Deletion', message: 'Are you sure you want to remove the calendar'}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
          this.onYesClick(rowId)
        }
      });
  }

  onYesClick(rowId) {
    this.userCalendarService.unlinkUserCalendar(rowId).subscribe(() => {
      this.messageService.display('User Calendar Link Removed Successfully. ', 'OK', 'success');
      this.fetchUserCalendars();
      this.loaderService.updateInboxAction(true);
}, 
() => {
  this.messageService.display('Error while unlinking user account. ', 'OK', 'error');
});
  }

  openDialogCalendarSummaryComponent(rowId) {
    this.userCalendarService.getCalendarSummary(rowId).subscribe((res) => {
      const dialogRef = this.dialog.open(CalendarSummaryComponent, {
          width: '30%',
          data: { res : res, calendarId : rowId }
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'applied') {
            this.fetchUserCalendars();
            this.loaderService.updateInboxAction(true);
          }
        });
    }, 
    (err) => {
      this.messageService.display('Error while unlinking user account. ', 'OK', 'error');
    });
  }
}
