import { JobRequisitionService } from './../../core/services/job-requisition.service';
import { JobPositionService } from './../../core/services/job-position.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, dateTimeFormatters } from '../../shared/factory/common.service';
import { Location } from '@angular/common';
import { MessageService } from 'src/app/shared/factory/message.service';
import { AppConfigService } from 'src/app/configs';


@Component({
  selector: 'app-interview-round-details',
  templateUrl: './interview-round-details.component.html',
  styleUrls: ['./interview-round-details.component.css']
})
export class InterviewRoundDetailsComponent implements OnInit {

  interviewId;
  roundId;
  roundDetails;
  message;
  dateTimeFormatters = dateTimeFormatters;
  jobRequisitionId;
  jobPositionId;
  jobPosition;
  loginData: any;
  showJobPosition : boolean = false;
  auth_token = sessionStorage.getItem('x-auth-token');
  

  constructor(
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private messageService: MessageService,
    private jobPositionService: JobPositionService,
    private jobRequisitionService: JobRequisitionService,
    private loaderService: LoaderService,
    public _configUrls: AppConfigService
  ) { }

  ngOnInit() {

    this.interviewId = parseInt(this.route.snapshot.paramMap.get('interviewId'), 0);
    this.roundId = parseInt(this.route.snapshot.paramMap.get('roundId'), 0);
    this.fetchRoundDetails(this.roundId);
    this.fetchInterviewDetals(this.interviewId)
    this.message = localStorage.getItem('showActionButton');
   }

  ngOnDestroy() {
    localStorage.removeItem('showActionButton');
  }

  fetchRoundDetails(roundId) {
    this.candidateService.fetchRoundDetails(roundId).subscribe(res => {
      this.roundDetails = res;
        if(!this.roundDetails.interviewers[0].isFeedbackGive){
            this.location.back();
            this.messageService.display('No feedback available for this round ! ','ok','error');
          }
    });
  }
  
  performInterviewAction(interviewId:number,roundId:number,action:string){
    let eventData = { "id": interviewId, "interviewRoundId": roundId, "interviewRoundStatus": action };
    this.candidateService.updateInterviewAction(eventData).subscribe(res => {
      this.loaderService.updateInboxAction(true);
      this.messageService.display('Candidate Status successfully updated!', 'OK', 'success');
      this.router.navigate(['/interview-actions']);
    });
  }

  fetchInterviewDetals(interviewId) {
      this.candidateService.fetchInterviewDetails(interviewId).subscribe(res => {
        this.jobRequisitionId = res.jobRequisition.id;
      });
   
    this.jobRequisitionService.fetchJobRequisitionsInProgress().subscribe(JobRequisitionsdata=>{
       JobRequisitionsdata.filter(res=>{
         if(this.jobRequisitionId == res.id) {
          this.jobPositionId = res.jobPositionId;
         }
     });
     this.jobPositionService.fetchJobPosition(this.jobPositionId).subscribe(res=>{
       if(this.jobPositionId == res.id) {
        this.jobPosition = res;
       }
     });
    });
    this.loginData = sessionStorage.getItem('loginData');
     JSON.parse(this.loginData).userRoles.forEach( role => {
         if (role.name=='Manager') {
            this.showJobPosition = true;
         }
    });
 }

 downloadUploadedFile(interviewerId, InterviewerRoundId) {
   this.candidateService.downloadUploadedFile(interviewerId, InterviewerRoundId);
 }

}
