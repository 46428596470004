import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/core/services/activity.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {

  pageNo: number = 0;
  pageSize: number = 200;
  totalCount = 0;
  candidateActivity = [];
  candidateActivityData: any;
  interviewActivity = [];
  interviewActivityData: any;
  requisitionActivity = [];
  requisitionActivityData: any;
  allActivity = [];
  allUsers = [];
  spinner = false;
  employeeId = [];
  profilePic = [];
  employeesId = [];
  isCheckRecord: boolean;
  img = "../../../assets/images/avatar.jpg";

  constructor(
    private activityService: ActivityService,
    private userService: UserService,
    private employeeService: EmployeeService) { }

  ngOnInit() {
    this.getEployeeId(this.pageNo, this.pageSize);
    this.validateLoginUser();
  }

  getEployeeId(pageNo: number, pageSize: number) {
    this.activityService.getAllActivity(pageNo, pageSize).subscribe(res => {
      res?.candidateActivity.find(res=> {
        if(this.employeesId.indexOf(res.employeeId) == -1) {
          this.employeesId.push(res.employeeId)
          this.fetchEmpProfilePic(res.employeeId);
        }
      });
      res?.interviewActivity.find(res=> {
        if(this.employeesId.indexOf(res.employeeId) == -1) {
           this.employeesId.push(res.employeeId)
           this.fetchEmpProfilePic(res.employeeId);
        }
      });
      res?.requisitionActivity.find(res=> {
        if(this.employeesId.indexOf(res.employeeId) == -1) {
           this.employeesId.push(res.employeeId)
           this.fetchEmpProfilePic(res.employeeId);
        }
      });
    });
  }

  getAllActivity(pageNo: number, pageSize: number) {
    this.spinner = true;
    this.activityService.getAllActivity(pageNo, pageSize).subscribe(res => {
      this.candidateActivityData = res.candidateActivity;
      this.interviewActivityData = res.interviewActivity;
      this.requisitionActivityData = res.requisitionActivity;
      this.spinner = false;
      for (var i = 0; i < res.candidateActivity.length; i++) {
        res.candidateActivity[i].employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.candidateActivity[i].employeeId)]
        this.candidateActivity.push(res.candidateActivity[i]);
      }
      for (var j = 0; j < res.interviewActivity.length; j++) {
        res.interviewActivity[j].employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.interviewActivity[j].employeeId)]
        this.interviewActivity.push(res.interviewActivity[j]);
      }
      for (var k = 0; k < res.requisitionActivity.length; k++) {
        res.requisitionActivity[k].employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.requisitionActivity[k].employeeId)]
        this.requisitionActivity.push(res.requisitionActivity[k]);
      }
      res.candidateActivity.find(res => {
          res.employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.employeeId)]
        this.allActivity.push(res)
        this.spinner = false;
      });
      res.interviewActivity.find(res => {
          res.employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.employeeId)]
        this.allActivity.push(res)
        this.spinner = false;
      });
      res.requisitionActivity.find(res => {
          res.employeeProfilePic = this.profilePic[this.employeeId.indexOf(res.employeeId)]
        this.allActivity.push(res)
        this.spinner = false;
      });
      this.allActivity.sort((n1, n2) => Number(n2.createDateTime) - Number(n1.createDateTime));
      this.totalCount = this.allActivity.length;
      this.spinner = false;
    });
  }

  loadMoreEmployeeData() {
    this.pageNo++;
    this.getAllActivity(this.pageNo, 200);
  }

  activityData() {
    this.spinner = true;
    this.pageSize = 200;
    this.pageNo = 0;
    this.totalCount = 0;
    this.candidateActivity = [];
    this.interviewActivity = [];
    this.requisitionActivity = [];
    this.allActivity = [];
    this.getAllActivity(this.pageNo, this.pageSize);
  }

  getAllUsers() {
    this.userService.fetchUsers().subscribe(res => {
      this.allUsers = res;
    });
  }

  validateLoginUser() {
    const loginData = sessionStorage.getItem('loginData');
    JSON.parse(loginData).userRoles.filter(res => {
      res.permissions.filter(res => {
        if (res.name === 'ROLE_MANAGE_CANDIDATE') {
          this.getAllUsers();
        }
      });
    });
  }

  fetchEmpProfilePic(employeeId) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      this.employeeId.push(employeeId);
     if (res.isProfileImageExist) {
      this.profilePic.push(res.imageBase64Encoded)
     }
     else {
      this.profilePic.push("../../../assets/images/avatar.jpg")
     }
    if(this.employeesId.length == this.employeeId.length) {
    this.isCheckRecord = true;
      this.getAllActivity(this.pageNo, this.pageSize);
    }
    });
  }
}