import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { RoleModel } from '../../shared/models/role-model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private stateUrl = 'api/v1/role';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchRoles(): Observable<RoleModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchRole(roleId): Observable<RoleModel> {
    return this.httpRequest.get(this.stateUrl + '/' + roleId);
  }

  updateRole(roleModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, roleModel);
  }

  addRole(roleModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, roleModel);
  }
}
