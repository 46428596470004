import { FormControl, Validators } from '@angular/forms';
export interface PermissionModel {
    id: number;
    name: string;
    description: string;
}
export class PermissionFormBuilder {
    id = new FormControl();
    name = new FormControl('', [Validators.required]);
    description = new FormControl('', [Validators.required]);
}