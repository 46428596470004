import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';
import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddCandidateToPoolComponent, CandidateOfferDialogComponent,ConfirmationDialogComponent } from '../../shared/popups/popups';
import { AppConfigService } from '../../configs/app-config.service';
import { MatSidenav } from '@angular/material/sidenav';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { fetchDisplayData, customValidators } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { CandidateFormBuilder, CandidateModel } from 'src/app/shared/models/candidate-model';

@Component({
  selector: 'app-shortlisted-candidates',
  templateUrl: './shortlisted-candidates.component.html',
  styleUrls: ['./shortlisted-candidates.component.css']
})
export class ShortlistedCandidatesComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'jobPosition', 'experience', 'email', 'mobile', 'addedBy', 'status', 'action'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  auth_token = sessionStorage.getItem('x-auth-token');
  fetchDisplayData = fetchDisplayData;
  jobPositions = fetchCachedData('jobPositions');
  jobTitles = fetchCachedData('jobTitles');
  jobRequisitions = fetchCachedData('jobRequisitions');
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    requisitionId: '',
    email: '',
    phoneNumber: '',
    candidateStatus: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    requisitionId: new FormControl(this.searchFormData.requisitionId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    candidateStatus: new FormControl(this.searchFormData.candidateStatus)
  });
  searched = false;
  expanded = true;
 
  constructor(
    private candidateService: CandidateService,
    public dialog: MatDialog,
    public _configUrls: AppConfigService,
    private eleRef: ElementRef,
    private messageService: MessageService,
    private helpService: HelpService,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    public router: Router,
    private candidatePoolService: CandidatePoolService

  ) { }

  ngOnInit() {
    this.fetchShortListedCandidatesCount();
    this.fetchShortListedCandidates(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;


  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchShortListedCandidates() : this.fetchShortListedCandidates(this.pageNo, this.pageSize);
    });
  }

  fetchShortListedCandidatesCount() {
    this.candidateService.fetchShortListedCandidatesCount().subscribe((res: any) => {
      this.totalCount = res;
    });
  }

  fetchShortListedCandidates(pageNo, pageSize) {
    
    this.candidateService.fetchShortListedCandidates(pageNo, pageSize).subscribe(res => {
      
      let data = [];
      for (var i = 0; i < res.length; i++) {
        for (var j = 0; j < res[i].candidates.length; j++) {
          let temp = {
            id: res[i].id,
            candidate: {
              id: res[i].candidates[j].id,
              firstName: res[i].candidates[j].firstName,
              lastName: res[i].candidates[j].lastName,
              experience: res[i].candidates[j].experienceInMonth,
              email: res[i].candidates[j].contact.email,
              mobile: res[i].candidates[j].contact.primaryPhoneNumber,
              addedBy: res[i].candidates[j].addedBy
            }, jobRequisition: {
              id: res[i].jobRequisitionLiteDTO.id,
              jobRequisitionCode: res[i].jobRequisitionLiteDTO.jobRequisitionCode,
              jobTitleName: res[i].jobRequisitionLiteDTO.jobTitleName,
              name: res[i].jobRequisitionLiteDTO.name
            },
            jobOfferStatus: res[i].candidates[j].candidateStatus
          };
          data.push(temp);
        }
      }
      this.dataSource = new MatTableDataSource<any>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openOfferDialog(data) {
    const dialogRef = this.dialog.open(CandidateOfferDialogComponent, {
      data: data,
      panelClass: "modal-offershortlist",
      id:"offerEmail"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.pageNo = 0;
        this.pageSize = 10;
        this.searched ? this.searchShortListedCandidates() : this.fetchShortListedCandidates(this.pageNo, this.pageSize);
      }
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchShortListedCandidates() {
    this.searchFormData = this.searchFormFields.value;
    let obj = {};
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.requisitionId ? (obj['requisitionId'] = this.searchFormData.requisitionId) : null;
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.candidateStatus ? (obj['status'] = this.searchFormData.candidateStatus) : null;
    obj != {} ? this.candidateService.fetchShortlistSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.candidateService.fetchShortlistSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        let data = [];
        for (var i = 0; i < resp.length; i++) {
          for (var j = 0; j < resp[i].candidates.length; j++) {
            let temp = {
              id: resp[i].id,
              candidate: {
                id: resp[i].candidates[j].id,
                firstName: resp[i].candidates[j].firstName,
                lastName: resp[i].candidates[j].lastName,
                experience: resp[i].candidates[j].experienceInMonth,
                email: resp[i].candidates[j].contact.email,
                mobile: resp[i].candidates[j].contact.primaryPhoneNumber,
                addedBy: resp[i].candidates[j].addedBy
              }, jobRequisition: {
                id: resp[i].jobRequisitionLiteDTO.id,
                jobRequisitionCode: resp[i].jobRequisitionLiteDTO.jobRequisitionCode,
                jobTitleName: resp[i].jobRequisitionLiteDTO.jobTitleName,
                name: resp[i].jobRequisitionLiteDTO.name
              },
              jobOfferStatus: resp[i].candidates[j].candidateStatus
            };
            data.push(temp);
          }
        }
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.requisitionId ? (canBeSearched = true) : null;
    this.searchFormData.candidateStatus ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchShortListedCandidates();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.fetchShortListedCandidatesCount();
      this.fetchShortListedCandidates(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      requisitionId: '',
      email: '',
      phoneNumber: '',
      candidateStatus: ''
    });
    this.searchFormData = this.searchFormFields.value;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.fetchShortListedCandidatesCount();
      this.fetchShortListedCandidates(this.pageNo, this.pageSize);
    }
  }

  findHelpUrlPage() {
    const currentPage = "/#_5_2_shortlisted_candidates";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  openAddCandidateDialog(candidatePoolId) {
    this.candidatePoolService.getCandidatePool().subscribe((res) => {
      this.openModal(res, candidatePoolId);
    });
  }
  openModal(res, candidatePoolId) {
    this.dialog.open(AddCandidateToPoolComponent, {
      width: '30%',
      data: { res: res, candidatePoolId: candidatePoolId }
    });
  }
  reinitiateCandidatesApplication(candidate: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: 'Are you sure you want to Re-initiate this candidate?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) { 
        const candidateModel = {
          id: candidate.candidate.id,
          firstName: candidate.candidate.firstName,
          lastName: candidate.candidate.lastName,
          jobRequisitionId: candidate.jobRequisition.id
        };
        this.candidateService.reinitiateCandidates(candidateModel).subscribe((res) => {
       
          this.fetchShortListedCandidates(this.pageNo, this.pageSize);
        });
      } else {
        this.messageService.display('Re-initiate candidate action cancelled', 'OK', 'info');
      }

    });
  }
  
}
