import { CandidateTagsService } from './../../core/services/candidate-tags.service';
import { NotesService } from './../../core/services/notes.service';
import { CandidateNotesModel } from './../../shared/models/candidate-notes-model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CandidateModel } from '../../shared/models/candidate-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { AppConfigService } from '../../configs';
import { MatSidenav } from '@angular/material/sidenav';
import { fetchDisplayData, fetchMultiDisplayData, dateTimeFormatters, customValidators } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { AddCandidateToPoolComponent, NotesComponent, DeleteCandidateComponent, ConfirmationDialogComponent, CandidateResumeUploadDialogComponent } from '../../shared/popups/popups';
import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';


@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.css']
})
export class CandidateComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'jobPosition', 'experience', 'email', 'mobile', 'addedBy', 'status', 'action'];
  dataSource: MatTableDataSource<CandidateModel>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  interviewId = 0;
  allCandidateTag: any;
  jobPositions = fetchCachedData('jobPositions');
  jobTitles = fetchCachedData('jobTitles');
  jobRequisitions = fetchCachedData('jobRequisitions');
  skills = fetchCachedData('skills');
  employees = fetchCachedData('employees');
  recruiters = fetchCachedData('recruiters');
  qualifications = fetchCachedData('qualifications');
  auth_token = sessionStorage.getItem('x-auth-token');
  fetchDisplayData = fetchDisplayData;
  fetchMultiDisplayData = fetchMultiDisplayData;
  dateFormat = dateTimeFormatters;
  customValidators = customValidators;
  candidatePool: any;
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    requisitionId: '',
    recruiterId: '',
    email: '',
    phoneNumber: '',
    addedByEmpId: '',
    status: '',
    skills: [],
    qualifications: [],
    experience: 0,
    fromDate: null,
    toDate: null,
    candidateTags: [],
    candidatePool: []
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    requisitionId: new FormControl(this.searchFormData.requisitionId),
    recruiterId: new FormControl(this.searchFormData.recruiterId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    addedByEmpId: new FormControl(this.searchFormData.addedByEmpId),
    status: new FormControl(this.searchFormData.status),
    skills: new FormControl(this.searchFormData.skills),
    qualifications: new FormControl(this.searchFormData.qualifications),
    experience: new FormControl(this.searchFormData.experience),
    fromDate: new FormControl(this.searchFormData.fromDate),
    toDate: new FormControl(this.searchFormData.toDate),
    candidateTags: new FormControl(this.searchFormData.candidateTags),
    candidatePool: new FormControl(this.searchFormData.candidatePool)
  });
  searched = false;
  expanded = true;
  candidatePoolId = null;

  constructor(
    private candidateService: CandidateService,
    public _configUrls: AppConfigService,
    private eleRef: ElementRef,
    private helpService: HelpService,
    private messageService: MessageService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private notesService: NotesService,
    private candidateTagsService: CandidateTagsService,
    private candidatePoolService: CandidatePoolService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.candidatePoolId = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.fetchCandidatesCount();
    this.fetchAllCandidateTags();
    this.fetchAllCandidatePool();
    if (this.candidatePoolId) {
      this.searchCandidate();
    }
    else {
      this.fetchCandidates(this.pageNo, this.pageSize);
    }
    this.dataSource = new MatTableDataSource<CandidateModel>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchCandidate() : this.fetchCandidates(this.pageNo, this.pageSize);
    });
  }

  fetchCandidatesCount() {
    this.candidateService.fetchCandidatesCount().subscribe((res: any) => {
      this.totalCount = res;
    });
  }

  fetchCandidates(pageNo, pageSize) {
    this.candidateService.fetchCandidates(pageNo, pageSize).subscribe((res: CandidateModel[]) => {
      this.dataSource = new MatTableDataSource<CandidateModel>(res);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  fetchAllCandidateTags() {
    this.candidateTagsService.fetchAllCandidateTags().subscribe(candidateTag => {
      // console.log(candidateTag)
      this.allCandidateTag = candidateTag
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchCandidate() {
    if (this.searchFormFields.get('experience') && this.searchFormFields.get('experience').value) {
      let experienceInYear = this.searchFormFields.get('experience').value;
      let index = experienceInYear.indexOf('.')
      if (index !== -1) {
        let year = Number(experienceInYear.slice(0, index))
        let month = Number(experienceInYear.slice(index + 1, experienceInYear.length))
        this.searchFormFields.controls['experience'].setValue(month + (year * 12));
      }
      else {
        this.searchFormFields.controls['experience'].setValue(Number(experienceInYear) * 12);
      }
    }
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromDate ? (this.searchFormData.fromDate = this.dateFormat.formatDate(this.searchFormData.fromDate)) : null;
    this.searchFormData.toDate ? (this.searchFormData.toDate = this.dateFormat.formatDate(this.searchFormData.toDate)) : null;
    let obj = {};
    this.searchFormData.addedByEmpId ? (obj['addedByEmpId'] = this.searchFormData.addedByEmpId) : null;
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.experience ? (obj['experience'] = this.searchFormData.experience) : null;
    this.searchFormData.fromDate ? (obj['fromDate'] = this.searchFormData.fromDate) : null;
    this.searchFormData.toDate ? (obj['toDate'] = this.searchFormData.toDate) : null;
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.qualifications.length ? (obj['qualifications'] = this.searchFormData.qualifications) : null;
    this.searchFormData.requisitionId ? (obj['requisitionId'] = this.searchFormData.requisitionId) : null;
    this.searchFormData.recruiterId ? (obj['recruiterId'] = this.searchFormData.recruiterId) : null;
    this.searchFormData.skills.length ? (obj['skills'] = this.searchFormData.skills) : null;
    this.searchFormData.status ? (obj['status'] = this.searchFormData.status) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.candidateTags.length > 0 ? (obj['candidateTags'] = this.searchFormData.candidateTags) : null;
    (this.searchFormData.candidatePool.length || this.candidatePoolId) ? (obj['candidatePool'] = this.searchFormData.candidatePool) : null;
    obj != {} ? this.candidateService.fetchCandidateSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.candidateService.fetchCandidateSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        this.dataSource = new MatTableDataSource<CandidateModel>(resp);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.addedByEmpId ? (canBeSearched = true) : null;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.experience ? (canBeSearched = true) : null;
    this.searchFormData.fromDate ? (canBeSearched = true) : null;
    this.searchFormData.toDate ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.qualifications.length ? (canBeSearched = true) : null;
    this.searchFormData.requisitionId ? (canBeSearched = true) : null;
    this.searchFormData.skills.length ? (canBeSearched = true) : null;
    this.searchFormData.status ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    this.searchFormData.recruiterId ? (canBeSearched = true) : null;
    this.searchFormData.candidatePool ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchCandidate();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.fetchCandidatesCount();
      this.fetchCandidates(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      recruiterId: '',
      requisitionId: '',
      email: '',
      phoneNumber: '',
      addedByEmpId: '',
      status: '',
      skills: [],
      qualifications: [],
      experience: 0,
      fromDate: null,
      toDate: null,
      candidateTags: [],
      candidatePool: []
    });
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromDate ? (this.searchFormData.fromDate = this.dateFormat.formatDate(this.searchFormData.fromDate)) : null;
    this.searchFormData.toDate ? (this.searchFormData.toDate = this.dateFormat.formatDate(this.searchFormData.toDate)) : null;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.fetchCandidatesCount();
      this.fetchCandidates(this.pageNo, this.pageSize);
    }
    if (this.candidatePoolId) {
      this.router.navigate(['./candidate'])
    }
  }
  findHelpUrlPage() {
    const currentPage = "/#_6_1_manage_candidates";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

  findInterviewIdShowInterviewDetails(candidateId) {
    this.candidateService.fetchInterviewIdUsingCandidateId(candidateId).subscribe((res: any) => {
      this.interviewId = res;
      this.router.navigate(['/candidate/candidatefeedback', this.interviewId]);
    });
  }



  openAddNotesDialog(candidateId) {
    this.notesService.fetchNotes(candidateId).subscribe((res: CandidateNotesModel) => {
      this.openModal(res, candidateId);
    });
  }
  openModal(res, candidateId) {
    const dialogRef = this.dialog.open(NotesComponent, {
      width: '70%',
      panelClass: 'candidate-notes-dialog',
      data: { res: res, candidateId: candidateId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        // this.fetchAllNotes();
        this.loaderService.updateInboxAction(true);
      }
    });
  }

  fetchAllCandidatePool() {
    this.candidatePoolService.getCandidatePool().subscribe(res => {
      this.candidatePool = res;
      this.candidatePool.find(res => {
        if (this.candidatePoolId == res.id) {
          this.searchFormData.candidatePool.push(res.id)
        }
      })
    });
  }

  openAddCandidateDialog(candidatePoolId) {
    this.candidatePoolService.getCandidatePool().subscribe((res) => {
      this.openModalforCandidatePool(res, candidatePoolId);
    });
  }
  openModalforCandidatePool(res, candidatePoolId) {
    this.dialog.open(AddCandidateToPoolComponent, {
      width: '30%',
      data: { res: res, candidatePoolId: candidatePoolId }
    });
  }

  deleteCandidateDialog(candidateId) {
    const dialogRef = this.dialog.open(DeleteCandidateComponent, {
      disableClose: true,
      width: '30%',
      data: { candidateId: candidateId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.fetchCandidates(this.pageNo, this.pageSize);
        this.loaderService.updateInboxAction(true);
      }
    });
  }


  reinitiateCandidateApplication(candidate: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: 'Are you sure you want to Re-initiate this candidate?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.candidateService.reinitiateCandidates(candidate).subscribe((res) => {

          this.fetchCandidates(this.pageNo, this.pageSize);
        });
      } else {
        this.messageService.display('Re-initiate candidate action cancelled', 'OK', 'info');
      }

    });

  }

  attachCandidateResume() {
    const dialogRef = this.dialog.open(CandidateResumeUploadDialogComponent, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === null) {
      }
     else if (result && result.success) {
        if (result.candidateId) {
          this.router.navigate(['/candidate', result.candidateId]);
        } else {
          this.messageService.display('Error while creating candidate', 'OK', 'warn');
        }
      } else {
        this.messageService.display('Something went wrong', 'OK', 'warn');
      }
    });
  }
}