import { FormControl, Validators, FormGroup } from '@angular/forms';
export interface ReferModel {
    firstName: string;
    lastName: string;
}
export class ReferFormBuilder {
    referModel: ReferModel = {
        firstName: '',
        lastName: ''
    };
    referForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.referModel.firstName, [Validators.required]),
        lastName: new FormControl(this.referModel.lastName, [Validators.required]),
        resumeFile: new FormControl(null, [Validators.required])
    });

    constructor() {
        this.referForm.valueChanges.subscribe(val => {
            this.referModel.firstName = val.firstName;
            this.referModel.lastName = val.lastName;
        });
    }
}