import { Component, OnInit, ViewChild } from '@angular/core';
import { QualificationService } from '../../core/services/qualification.service';
import { FormControl } from '@angular/forms';
import { QualificationModel } from '../../shared/models/qualification-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'action'];
  dataSource: MatTableDataSource<QualificationModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private qualificationService: QualificationService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchQualifications();
    this.dataSource = new MatTableDataSource<QualificationModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchQualifications() {
    this.qualificationService.fetchQualifications().subscribe((res: QualificationModel[]) => {
      this.dataSource = new MatTableDataSource<QualificationModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_6_7_manage_qualifications";
   
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
