import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { PermissionModel } from '../../shared/models/permission-model';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private stateUrl = 'api/v1/permission';

  constructor(private httpRequest: HttpRequestService) { }

  fetchPermissions(): Observable<PermissionModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchPermission(permissionId): Observable<PermissionModel> {
    return this.httpRequest.get(this.stateUrl + '/' + permissionId);
  }

  updatePermission(permissionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, permissionModel);
  }

  addPermission(permissionModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, permissionModel);
  }
}
