import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { compareFn } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { HelpService } from '../../core/services/help.service';
import { UserMailFormBuilder, UserMailModel } from 'src/app/shared/models/user-mail-model';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';
 

@Component({
  selector: 'app-link-mail-add-edit',
  templateUrl: './link-mail-add-edit.component.html',
  styleUrls: ['./link-mail-add-edit.component.css']
})
export class LinkMailAddEditComponent implements OnInit {

  userMail: UserMailFormBuilder = new UserMailFormBuilder();
  userMailModel: UserMailModel = this.userMail.userMailModel;
  userMailForm: FormGroup = this.userMail.userMailForm;

  compareFn = compareFn;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private userMailService: UserCalendarService,
    private messageService: MessageService,
    private helpService: HelpService,
    public location: Location,
  ) { }

  ngOnInit(): void {
    this.userMailModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
  }

  connectToUserEmail() {
    this.userMailService.connectToUserMail(this.userMailModel).subscribe((res) => {
      this.messageService.display('User Mail Linked Successfully. ', 'OK', 'success');
      window.open(res.authenticationUrl);
      this.router.navigate(['/link-email']);
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_1_add_job_description";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
