import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface UploadFileModel {
    candidatesZipFile: string;
}

export class UploadFileFormBuilder {
    uploadFileModel: UploadFileModel = {
        candidatesZipFile: ''
    };
    uploadFileForm: FormGroup = new FormGroup({
        candidatesZipFile: new FormControl(this.uploadFileModel.candidatesZipFile, [Validators.required]),
    });
    constructor() {
        this.uploadFileForm.valueChanges.subscribe(val => {
            this.uploadFileModel.candidatesZipFile = val.candidatesZipFile;
        });
    }
}

export interface BulkImportModel {
    
}

export class BulkImportFormBuilder {
    bulkImportModel: BulkImportModel = {
    };

    bulkImportForm: FormGroup = new FormGroup({
    });

  group: any;
    constructor() {}
}
