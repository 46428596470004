import { Component, ViewChild, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, scheduled } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


const COMMA = 188;

@Component({
  selector: 'app-mail-new-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './mail-new-message.component.html',
  styleUrls: ['./mail-new-message.component.css']
})
export class MailNewMessageComponent implements OnInit {
  separatorKeysCodes = [ENTER, COMMA];

  contacts: string[] = [
    'Austin Mcdaniel',
    'Jeremy Elbourn',
    'Jules Kremer',
    'Brad Green',
    'Tina Gao'
  ];
  recipients: string[] = [];
  subjectCtrl = new FormControl();
  bodyCtrl = new FormControl();
  recipientsCtrl = new FormControl();
  filteredContacts: Observable<any[]>;

  @ViewChild('recipientInput') recipientInput;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.to && data.subject) {
      this.recipients.push(data.to);
      this.subjectCtrl.setValue(data.subject);
    }

    this.filteredContacts = this.recipientsCtrl.valueChanges.pipe(
      startWith(null), 
      map(contact => contact ? this.filterContacts(contact) : this.contacts.slice()));
  }

  ngOnInit(): void {

  }

  addRecipient(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our person
    if ((value || '').trim()) {
      this.recipients.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeRecipient(recipient: string): void {
    const index = this.recipients.indexOf(recipient);
    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  filterContacts(name: string): string[] {
    return this.contacts.filter(contact =>
      contact.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.recipients.push(event.option.value);
    this.recipientInput.nativeElement.value = '';
  }



}
