import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService, dateTimeFormatters } from '../../shared/factory/common.service';
import { MatDialog } from '@angular/material/dialog';
import { InterviewActionDialogComponent } from '../../shared/popups/popups';
import { LoaderService } from '../../shared/factory/loader.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MessageService } from '../../shared/factory/message.service';
import { CandidateModel } from '../../shared/models/candidate-model';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-interview-action',
  templateUrl: './interview-action.component.html',
  styleUrls: ['./interview-action.component.css']
})
export class InterviewActionComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'scheduleDate', 'location', 'interviewType', 'interviewRoundStatus', 'action'];
  
  dataSource: MatTableDataSource<CandidateModel>;
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  dateTimeFormatters = dateTimeFormatters;
  searchFormData = {
    name: '',
    fromScheduleDate: null,
    toScheduleDate: null,
    interviewRoundStatus: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    fromScheduleDate: new FormControl(this.searchFormData.fromScheduleDate),
    toScheduleDate: new FormControl(this.searchFormData.toScheduleDate),
    interviewRoundStatus: new FormControl(this.searchFormData.interviewRoundStatus)
  });
  searched = false;
  expanded = true;
  showAllCheck: boolean = false;

  constructor(
    private candidateService: CandidateService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private eleRef: ElementRef,
    private helpService: HelpService,
    private messageService: MessageService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router, private commonService: CommonService
  ) { }

  ngOnInit() {
    this.fetchAwaitingActionInterviewsCount();
    this.fetchAwaitingActionInterviews(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<CandidateModel>([]);  
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;     
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
     this.searched ? this.searchInterviewActions()  : this.fetchAwaitingActionInterviews(this.pageNo, this.pageSize)    
    });
  }

  fetchAwaitingActionInterviews(pageNo, pageSize) {
   this.candidateService.fetchAwaitingActionInterviews(pageNo, pageSize).subscribe((res: CandidateModel[]) => {
      this.dataSource = new MatTableDataSource<any>(res);
    // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
 

  fetchAwaitingActionInterviewsCount(){
    this.candidateService.fetchAwaitingActionInterviewsCount().subscribe((res: any) =>{
      this.totalCount = res;
    });
  }

  openActionDialog(interviewId, roundId) {
    const dialogRef = this.dialog.open(InterviewActionDialogComponent, {
      data: { interviewId: interviewId, roundId: roundId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.loaderService.updateInboxAction(true);
        this.searched ? this.searchInterviewActions() : this.fetchAwaitingActionInterviews(this.pageNo, this.pageSize);
      
      };
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchInterviewActions() {
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromScheduleDate ? (this.searchFormData.fromScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromScheduleDate)) : null;
    this.searchFormData.toScheduleDate ? (this.searchFormData.toScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.toScheduleDate)) : null;
    let obj = {};
    this.searchFormData.fromScheduleDate ? (obj['fromScheduleDate'] = this.searchFormData.fromScheduleDate) : null;
    this.searchFormData.toScheduleDate ? (obj['toScheduleDate'] = this.searchFormData.toScheduleDate) : null;
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;

    if ( this.searchFormData.interviewRoundStatus ) {
      obj['interviewRoundStatus'] = this.searchFormData.interviewRoundStatus;
    } else {
      this.showAllCheck = true;
      obj['interviewRoundStatus'] = null;
    }

    obj != {} ? this.candidateService.fetchInterviewActionSearchFilterCount(obj).subscribe(res => {
      this.totalCount=res;
      (res > 0) ? this.candidateService.fetchInterviewActionSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        this.dataSource = new MatTableDataSource<CandidateModel>(resp);
       // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
        
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  showAll(event){
    if(event.checked){
      let obj = {};
      obj != {} ? this.candidateService.fetchInterviewActionSearchFilterCount(obj).subscribe(res => {
        this.totalCount = res;
       
        (res > 0) ? this.candidateService.fetchInterviewActionSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
          this.dataSource = new MatTableDataSource<CandidateModel>(resp);
          this.dataSource.sort = this.sort;
          this.searched = true;          
        }) : this.messageService.display('No records found!', 'OK', 'warn');
      }) : null;
    }else{
      this.resetForm();
    }
  }

  refreshSearch() {
    this.showAllCheck = false;
    let canBeSearched = false;
    this.searchFormData.fromScheduleDate ? (canBeSearched = true) : null;
    this.searchFormData.toScheduleDate ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchInterviewActions();
    } else {
      this.searched = false;
      this.fetchAwaitingActionInterviewsCount();
      this.fetchAwaitingActionInterviews(this.pageNo, this.pageSize); 
    }
  }

  resetForm() {
    this.showAllCheck = false;
    this.searchFormFields.reset({
      name: '',
      fromScheduleDate: null,
      toScheduleDate: null
    });
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromScheduleDate ? (this.searchFormData.fromScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromScheduleDate)) : null;
    this.searchFormData.toScheduleDate ? (this.searchFormData.toScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.toScheduleDate)) : null;
    if (this.searched) {
      this.searched = false;
      this.fetchAwaitingActionInterviewsCount();
      this.fetchAwaitingActionInterviews(this.pageNo, this.pageSize);
     
    }
  }

  findHelpUrlPage(){
    const currentPage = "/#_4_2_interview_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
     
  }

  interviewAction() {
    localStorage.setItem('showActionButton','true');
  }
  

}
