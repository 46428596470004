import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


const proxyUrl = "http://cors-anywhere.herokuapp.com/"



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  search = '';
  timeout = null;
  show = false;
  searchTickers = [];
  filteredOptions = [];
  totalCount;
  fetchedData;
  selectedSearch: string;
  pageNo: number = 0;
  pageSize: number = 6;
  control = new FormControl();

  constructor(
    private http: HttpClient,
    private candidateService: CandidateService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    let obj = {};
    obj != {} ? this.candidateService.fetchCandidateSearchCountFilter(obj).subscribe(res => {
      (res > 0) ? this.candidateService.fetchCandidateSearchFilter(obj, this.pageNo, 100).subscribe(res => {
        this.filteredOptions = res;
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }


  searchCandidate(val?) {
    let obj = {};

    if (val != '') {
      this.search = val;
      this.search ? (obj['requisitionId'] = this.search) : null;

      obj != {} ? this.candidateService.fetchCandidateSearchCountFilter(obj).subscribe(res => {
        this.totalCount = res;
        (res > 0) ? this.candidateService.fetchCandidateSearchFilter(obj, this.pageNo, this.pageSize).subscribe(res => {
          this.fetchedData = res;
        }) : this.messageService.display('No records found!', 'OK', 'warn');
      }) : null;
    }

  }

}
