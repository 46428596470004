import { Component, OnInit, ViewChild } from '@angular/core';
import { DepartmentService } from '../../core/services/department.service';
import { FormControl } from '@angular/forms';
import { DepartmentModel } from '../../shared/models/department-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'code', 'organization', 'hod', 'action'];
  dataSource: MatTableDataSource<DepartmentModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private departmentService: DepartmentService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private helpService: HelpService,
    private _router: Router

  ) { }

  ngOnInit() {
    this.fetchDepartments();
    this.dataSource = new MatTableDataSource<DepartmentModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchDepartments() {
    this.departmentService.fetchDepartments().subscribe((res: DepartmentModel[]) => {
      this.dataSource = new MatTableDataSource<DepartmentModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_7_5_manage_departments";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
