import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface CandidatePoolModel {
    id:number,
    name: string,
    category: string,
}

export class CandidatePoolFormBuilder {
    candidatePoolModel: CandidatePoolModel = {
        id: null,
        name: '',
        category: ''
    };
    candidatePoolForm: FormGroup = new FormGroup({
        name: new FormControl(this.candidatePoolModel.name, [Validators.required]),
        category: new FormControl(this.candidatePoolModel.category, [Validators.required]),
    });
    constructor() {
        this.candidatePoolForm.valueChanges.subscribe(val => {
            this.candidatePoolModel.name = val.name;
            this.candidatePoolModel.category = val.category;
        });
    }

    setModelVals(res: CandidatePoolModel) {
        this.candidatePoolForm.controls.name.setValue(res.name);
        this.candidatePoolForm.controls.category.setValue(res.category);
    }

}