import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppConfigService } from '../../configs/app-config.service';
import { fetchDisplayData, fetchMultiDisplayData, customValidators } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MessageService } from '../../shared/factory/message.service';
import { DataSource } from '@angular/cdk/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { AddCandidateToPoolComponent,ConfirmationDialogComponent } from 'src/app/shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rejected-candidates',
  templateUrl: './rejected-candidates.component.html',
  styleUrls: ['./rejected-candidates.component.css']
})
export class RejectedCandidatesComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = [ 'name', 'jobPosition', 'experience', 'email', 'mobile', 'addedBy', 'status', 'action'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  auth_token = sessionStorage.getItem('x-auth-token');
  fetchMultiDisplayData = fetchMultiDisplayData;
  fetchDisplayData = fetchDisplayData;
  jobPositions = fetchCachedData('jobPositions');
  jobTitles = fetchCachedData('jobTitles');
  jobRequisitions = fetchCachedData('jobRequisitions');
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    requisitionId: '',
    email: '',
    phoneNumber: '',
    candidateStatus: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    requisitionId: new FormControl(this.searchFormData.requisitionId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    candidateStatus: new FormControl(this.searchFormData.candidateStatus)
  });
  searched = false;
  expanded = true;

  constructor(
    private candidateService: CandidateService,
    public _configUrls: AppConfigService,
    private eleRef: ElementRef,
    private messageService: MessageService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    public candidatePoolService: CandidatePoolService
  ) { }

  ngOnInit() {
    this.fetchRejectedCandidatesCount();
    this.fetchRejectedCandidates(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.fetchRejectedCandidates(this.pageNo, this.pageSize);
      this.searched ? this.searchRejectedCandidates() : this.fetchRejectedCandidates(this.pageNo, this.pageSize);
    });
  }

  fetchRejectedCandidatesCount() {
    this.candidateService.fetchRejectedCandidatesCount().subscribe((res: any) => {
      this.totalCount = res;
    });
  }

  fetchRejectedCandidates(pageNo, pageSize) {
    this.candidateService.fetchRejectedCandidates(pageNo, pageSize).subscribe(res => {
      let data = [];
      for (var i = 0; i < res.length; i++) {
        for (var j = 0; j < res[i].candidates.length; j++) {
          let temp = {
            id: res[i].candidates[j].id,
            candidateName: res[i].candidates[j].firstName + ' ' + res[i].candidates[j].lastName,
            experience: res[i].candidates[j].experienceInMonth,
            email: res[i].candidates[j].contact.email,
            mobile: res[i].candidates[j].contact.primaryPhoneNumber,
            addedBy: res[i].candidates[j].addedBy,
            jobOfferStatus: res[i].candidates[j].candidateStatus,
            jobRequisitionName: res[i].jobRequisitionLiteDTO.name,
            jobRequisitionId: res[i].jobRequisitionLiteDTO.id
          };
          data.push(temp);
        }
      }
      this.dataSource = new MatTableDataSource<any>(data);
    //  this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchRejectedCandidates() {
    this.searchFormData = this.searchFormFields.value;
    let obj = {};
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.requisitionId ? (obj['requisitionId'] = this.searchFormData.requisitionId) : null;
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.candidateStatus ? (obj['candidateStatus'] = this.searchFormData.candidateStatus) : null;
    obj != {} ? this.candidateService.fetchRejectedSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.candidateService.fetchRejectedSearchFilter(obj, this.pageNo, this.pageSize).subscribe(res => {
        let data = [];
        for (var i = 0; i < res.length; i++) {
          for (var j = 0; j < res[i].candidates.length; j++) {
            let temp = {
              id: res[i].candidates[j].id,
              candidateName: res[i].candidates[j].firstName + ' ' + res[i].candidates[j].lastName,
              experience: res[i].candidates[j].experienceInMonth,
              email: res[i].candidates[j].contact.email,
              mobile: res[i].candidates[j].contact.primaryPhoneNumber,
              addedBy: res[i].candidates[j].addedBy,
              jobOfferStatus: res[i].candidates[j].candidateStatus,
              jobRequisitionName: res[i].jobRequisitionLiteDTO.name,
              jobRequisitionId: res[i].jobRequisitionLiteDTO.id
            };
            data.push(temp);
          }
        }
        this.dataSource = new MatTableDataSource<any>(data);
     //   this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.requisitionId ? (canBeSearched = true) : null;
    this.searchFormData.candidateStatus ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchRejectedCandidates();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.fetchRejectedCandidatesCount();
      this.fetchRejectedCandidates(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      requisitionId: '',
      email: '',
      phoneNumber: '',
      candidateStatus: ''
    });
    this.searchFormData = this.searchFormFields.value;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.fetchRejectedCandidatesCount();
      this.fetchRejectedCandidates(this.pageNo, this.pageSize);
    }
  }

  findHelpUrlPage(){
    const currentPage = "/#_5_3_rejected_candidates";
   
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

  openAddCandidateDialog(candidatePoolId) {
    this.candidatePoolService.getCandidatePool().subscribe((res) => {
      this.openModal(res, candidatePoolId);
    });
  }
  openModal(res, candidatePoolId) {
    this.dialog.open(AddCandidateToPoolComponent, {
      width: '30%',
      data: { res:res, candidatePoolId:candidatePoolId }
    });
  }

  reinitiateCandidatesApplication(candidate: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: 'Are you sure you want to Re-initiate this candidate?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) { 
        const [firstname, lastname] = candidate.candidateName.split(' ');
        const CandidateModel = {
          id: candidate.id,
          firstName: firstname,
          lastName: lastname,
          jobRequisitionId: candidate.jobRequisitionId
        };
        this.candidateService.reinitiateCandidates(CandidateModel).subscribe((res) => {
       
          this.fetchRejectedCandidates(this.pageNo, this.pageSize);
        });
      } else {
        this.messageService.display('Re-initiate candidate action cancelled', 'OK', 'info');
      }

    });
  }

 

}
