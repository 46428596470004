import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/app/configs';
import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';
import { CandidateQualificationService } from 'src/app/core/services/candidate-qualification.service';
import { CandidateTagsService } from 'src/app/core/services/candidate-tags.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { HelpService } from 'src/app/core/services/help.service';
import { NotesService } from 'src/app/core/services/notes.service';
import { customValidators, dateTimeFormatters, fetchDisplayData, fetchMultiDisplayData } from 'src/app/shared/factory/common.service';
import { fetchCachedData } from 'src/app/shared/factory/data-caching.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CandidateModel } from 'src/app/shared/models/candidate-model';
import { CandidateQualificationModel } from 'src/app/shared/models/candidate-qualification-model';
import { ViewCandidateQualificationDetailsComponent } from 'src/app/shared/popups/popups';
@Component({
  selector: 'app-candidate-qualification-action',
  templateUrl: './candidate-qualification-action.component.html',
  styleUrls: ['./candidate-qualification-action.component.css']
})
export class CandidateQualificationActionComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'jobPosition', 'experience', 'email', 'mobile','candidateWorkPreference','addedBy', 'requestedBy','status', 'action'];
  dataSource: MatTableDataSource<CandidateQualificationModel>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  jobPositions = fetchCachedData('jobPositions');
  jobTitles = fetchCachedData('jobTitles');
  jobRequisitions = fetchCachedData('jobRequisitions');
  skills = fetchCachedData('skills');
  employees = fetchCachedData('employees');
  recruiters = fetchCachedData('recruiters');
  qualifications = fetchCachedData('qualifications');
  auth_token = sessionStorage.getItem('x-auth-token');
  fetchDisplayData = fetchDisplayData;
  fetchMultiDisplayData = fetchMultiDisplayData;
  dateFormat = dateTimeFormatters;
  customValidators = customValidators;
  candidatePool: any;
  searched = false;
  expanded = true;
  candidatePoolId = null;

  constructor(private candidateQualificationService: CandidateQualificationService,
    public _configUrls: AppConfigService,
    private helpService: HelpService, public dialog: MatDialog,  private route: ActivatedRoute,
    public router: Router
 ) { }

  ngOnInit(): void {
    this.fetchPendingCandidateQualification();
    this.dataSource = new MatTableDataSource<CandidateQualificationModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  fetchPendingCandidateQualification() {
    this.candidateQualificationService.fetchPendingActionCandidate().subscribe((res: CandidateQualificationModel[]) => {
      this.dataSource = new MatTableDataSource<CandidateQualificationModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_3_interview_feedback_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  openOfferDialog(row) {
    const dialogRef = this.dialog.open(ViewCandidateQualificationDetailsComponent, {
      disableClose: false,
      width: '80%',
      data: { dataSource: row, isEdit: false ,id:row?.id, qualifications: this.qualifications, skills: this.skills,jobRequisitions: this.jobRequisitions  // Pass skills data here
      },
      panelClass:'custom-poppup'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchPendingCandidateQualification();
        this.router.navigate(['/qualification-action']).then(() => {
          this.fetchPendingCandidateQualification();
        });
      } else {
        this.router.navigate(['/qualification-action']).then(() => {
          this.fetchPendingCandidateQualification();
        });
      }
    });
  }
  
}
