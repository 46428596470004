import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSidenav } from '@angular/material/sidenav';
import { PanoramicViewService } from 'src/app/core/services/panoramic-view.service';



@Component({
  selector: 'app-panoramic-view',
  templateUrl: './panoramic-view.component.html',
  styleUrls: ['./panoramic-view.component.css']
})

export class PanoramicViewComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  columnMetaArray;
  displayedColumns;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  
  constructor(
    private panoramicService: PanoramicViewService){
  }

  ngOnInit() {
    this.fetchPanoramicViewData();
    this.dataSource = new MatTableDataSource<any>();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.paginator.page.subscribe(options => {
    //   this.pageNo = options.pageIndex;
    //   this.pageSize = options.pageSize;
    // });
  }

  fetchPanoramicViewData() {
    
    this.panoramicService.fetchPanoramicViewData().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.tableData);
      this.columnMetaArray = res.columnArray;
      this.displayedColumns = this.columnMetaArray.map(column => column.field);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
