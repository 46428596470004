import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { JobRequisitionService } from '../../core/services/job-requisition.service';
import { JobRequisitionModel } from '../../shared/models/job-requisition-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { fetchDisplayData } from '../../shared/factory/common.service';
import { LoaderService } from '../../shared/factory/loader.service';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-job-requisition-action',
  templateUrl: './job-requisition-action.component.html',
  styleUrls: ['./job-requisition-action.component.css']
})
export class JobRequisitionActionComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'code', 'name', 'jobTitle', 'jobPosition', 'raisedBy','requisitionStatusType', 'noOfPosition', 'action'];
  dataSource: MatTableDataSource<JobRequisitionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  jobTitles = fetchCachedData('jobTitles');
  jobPositions = fetchCachedData('jobPositions');
  employees = fetchCachedData('employees');
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  fetchDisplayData = fetchDisplayData;
  showAllCheck: boolean = false;
  
  searchFormData = {
    rasiedByEmployeeId: '',
    positionId: '',
    titleId: '',
    requisitionStatusType: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    rasiedByEmployeeId: new FormControl(this.searchFormData.rasiedByEmployeeId),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    requisitionStatusType: new FormControl(this.searchFormData.requisitionStatusType)
  });
  searched = false;
  expanded = true;

  constructor(
    private jobRequisitionService: JobRequisitionService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private eleRef: ElementRef,
    private helpService: HelpService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchOpenJobRequisitionCount();
    this.fetchOpenJobRequisitions(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<JobRequisitionModel>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchJobReqActions()  : this.fetchOpenJobRequisitions(this.pageNo, this.pageSize);
    });
  }

  fetchOpenJobRequisitionCount(){
    this.jobRequisitionService.fetchOpenJobRequisitionCount().subscribe((res: any) =>{
    this.totalCount=res;
  });
  }  

  fetchOpenJobRequisitions(pageNo, pageSize) {
    this.jobRequisitionService.fetchOpenJobRequisitions(pageNo, pageSize).subscribe((res: JobRequisitionModel[]) => {
      this.dataSource = new MatTableDataSource<JobRequisitionModel>(res);
    //  this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  acceptRequisition(id, type) {
    this.jobRequisitionService.updateStatus({ id: id, requisitionStatusType: type }).subscribe(res => {
      this.messageService.display('Job requisition is approved! Approval ID : ' + id, 'OK', 'success');
      this.searched ? this.searchJobReqActions() : this.fetchOpenJobRequisitions(this.pageNo, this.pageSize);
      this.loaderService.updateInboxAction(true);
      this.jobRequisitionService.fetchJobRequisitions().subscribe(res => {
        sessionStorage.setItem('jobRequisitions', JSON.stringify(res));
      });
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  showAll(event){
    if(event.checked){
      let obj = {};
      obj != {} ? this.jobRequisitionService.fetchJobRequisitionsSearchCountFilter(obj).subscribe(res => {
        this.totalCount = res;
       
        (res > 0) ? this.jobRequisitionService.fetchJobRequisitionsSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
          this.dataSource = new MatTableDataSource<JobRequisitionModel>(resp);
          this.dataSource.sort = this.sort;    
          this.searched = true;      
        }) : this.messageService.display('No records found!', 'OK', 'warn');
      }) : null;
    }else{
      this.resetForm();
    }
  }

  searchJobReqActions() {
    this.searchFormData = this.searchFormFields.value;
    let obj = {};
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.rasiedByEmployeeId ? (obj['rasiedByEmployeeId'] = this.searchFormData.rasiedByEmployeeId) : null;

    if ( this.searchFormData.requisitionStatusType ) {
      obj['requisitionStatusType'] = this.searchFormData.requisitionStatusType;
    } else {
      this.showAllCheck = true;
      obj['requisitionStatusType'] = null;
    } 

    obj != {} ? this.jobRequisitionService.fetchJobRequisitionsSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
     
      (res > 0) ? this.jobRequisitionService.fetchJobRequisitionsSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        this.dataSource = new MatTableDataSource<JobRequisitionModel>(resp);
     //   this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
        
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    this.showAllCheck = false;
    let canBeSearched = false;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    this.searchFormData.rasiedByEmployeeId ? (canBeSearched = true) : null;
    this.searchFormData.requisitionStatusType ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchJobReqActions();
    } else {
      this.searched = false;
      this.fetchOpenJobRequisitionCount();
      this.fetchOpenJobRequisitions(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.showAllCheck = false;
    this.searchFormFields.reset({
      rasiedByEmployeeId: '',
      positionId: '',
      titleId: '',
      requisitionStatusType: ''
    });
    this.searchFormData = this.searchFormFields.value;
    if (this.searched) {
      this.searched = false;
      this.fetchOpenJobRequisitionCount();
      this.fetchOpenJobRequisitions(this.pageNo, this.pageSize);
    }
  }

  findHelpUrlPage(){
    const currentPage = "/#_4_1_job_requisition_approval";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
   
  }

}
