import { Component, OnInit } from '@angular/core';
import { RoleModel, RoleFormBuilder } from '../../shared/models/role-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RoleService } from '../../core/services/role.service';
import { PermissionModel } from '../../shared/models/permission-model';
import { PermissionService } from '../../core/services/permission.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-role-add-edit',
  templateUrl: './role-add-edit.component.html',
  styleUrls: ['./role-add-edit.component.css']
})
export class RoleAddEditComponent implements OnInit {

  roleForm: RoleFormBuilder = new RoleFormBuilder();
  roleModel: RoleModel = {
    id: null,
    name: '',
    description: '',
    permissions: []
  };
  permissions: PermissionModel[];
  addUpdateText = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.fetchPermissions();
    this.roleModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.addUpdateText = this.roleModel.id ? 'Update' : 'Add';
    this.roleModel.id ? this.fetchRole() : null;
  }

  compareFn(obj1: PermissionModel, obj2: PermissionModel) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  fetchPermissions() {
    this.permissionService.fetchPermissions().subscribe((res: PermissionModel[]) => {
      this.permissions = res;
    });
  }

  fetchRole() {
    this.roleService.fetchRole(this.roleModel.id).subscribe((res: RoleModel) => {
      this.roleForm.name.setValue(res.name);
      this.roleForm.description.setValue(res.description);
      this.roleForm.permissions.setValue(res.permissions);
    });
  }

  addUpdateRole() {
    this.roleModel.name = this.roleForm.name.value;
    this.roleModel.description = this.roleForm.description.value;
    this.roleModel.permissions = this.roleForm.permissions.value;
    if (this.roleModel.id) {
      this.roleService.updateRole(this.roleModel).subscribe((res) => {
        this.messageService.display('Role update is successfull! Role ID : ' + this.roleModel.id, 'OK', 'success');
        this.router.navigate(['/role']);
      });
    } else {
      this.roleService.addRole(this.roleModel).subscribe((res) => {
        this.messageService.display('Role add is successfull! Role ID : ' + res, 'OK', 'success');
        this.router.navigate(['/role']);
      });
    }
  }

}
