import { Component, OnInit, ViewChild } from '@angular/core';
import { JobRequisitionService } from '../../core/services/job-requisition.service';
import { FormControl } from '@angular/forms';
import { JobRequisitionModel } from '../../shared/models/job-requisition-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { fetchDisplayData } from '../../shared/factory/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-job-requisition',
  templateUrl: './job-requisition.component.html',
  styleUrls: ['./job-requisition.component.css']
})
export class JobRequisitionComponent implements OnInit {

  displayedColumns = ['id', 'code', 'name', 'jobTitle', 'jobPosition', 'raisedBy', 'noOfPosition', 'status', 'action'];
  dataSource: MatTableDataSource<JobRequisitionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  jobTitles = fetchCachedData('jobTitles');
  jobPositions = fetchCachedData('jobPositions');
  fetchDisplayData = fetchDisplayData;

  constructor(
    private jobRequisitionService: JobRequisitionService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchJobRequisitions();
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchJobRequisitions() {
    this.jobRequisitionService.fetchJobRequisitions().subscribe((res: JobRequisitionModel[]) => {
      this.dataSource = new MatTableDataSource<JobRequisitionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_6_3_open_requisition";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
  

}
