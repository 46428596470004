import { Component, OnInit } from '@angular/core';
import { JobTitleModel, JobTitleFormBuilder } from '../../shared/models/job-title-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { JobTitleService } from '../../core/services/job-title.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-job-title-add-edit',
  templateUrl: './job-title-add-edit.component.html',
  styleUrls: ['./job-title-add-edit.component.css']
})
export class JobTitleAddEditComponent implements OnInit {

  jobTitle: JobTitleFormBuilder = new JobTitleFormBuilder();
  jobTitleModel: JobTitleModel = this.jobTitle.jobTitleModel;
  jobTitleForm: FormGroup = this.jobTitle.jobTitleForm;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private jobTitleService: JobTitleService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.jobTitleModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.jobTitleModel.id ? this.fetchJobTitle() : null;
  }

  fetchJobTitle() {
    this.jobTitleService.fetchJobTitle(this.jobTitleModel.id).subscribe((res: JobTitleModel) => {
      this.jobTitle.setModelVals(res);
    });
  }

  addUpdateJobTitle() {
    if (this.jobTitleModel.id) {
      this.jobTitleService.updateJobTitle(this.jobTitleModel).subscribe((res) => {
        this.messageService.display('Job title update is successfull! Job Title ID : ' + this.jobTitleModel.id, 'OK', 'success');
        this.router.navigate(['/job-title']);
      });
    } else {
      this.jobTitleService.addJobTitle(this.jobTitleModel).subscribe((res) => {
        this.messageService.display('Job title add is successfull! Job Title ID : ' + res, 'OK', 'success');
        this.router.navigate(['/job-title']);
      });
    }
  }

}
