import { HttpRequestService } from './../http/http-request.service';
import { CandidateTagsModel } from './../../shared/models/candidate-tags-model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CandidateTagsService {

  private URL = 'api/v1/candidate-tags';

  constructor(private httpRequest: HttpRequestService) { }

  createCandidateTags(candidateTagsModel: CandidateTagsModel): Observable<any> {
    return this.httpRequest.post(this.URL, candidateTagsModel);
  }

  fetchAllCandidateTags(): Observable<CandidateTagsModel[]> {
    return this.httpRequest.get(this.URL);
  }

  findCandidateTagsById(tagId: number): Observable<CandidateTagsModel> {
    return this.httpRequest.get(this.URL + "/" + tagId);
  }

  updateCandidateTag(candidateTagsModel: CandidateTagsModel): Observable<any> {
    return this.httpRequest.put(this.URL, candidateTagsModel);
  }

  deleteCandidateTagsById(tagId: number): Observable<any> {
    return this.httpRequest.delete(this.URL + "/remove/" + tagId);
  }

}
