import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface JobRequisitionModel {
    id: number,
    name: string,
    jobDescriptionId: number,
    jobTitleId: number,
    jobPositionId: number,
    numberOfPosition: number,
    requisitionStatusType: string,
    organizationId: number,
    departmentId: number,
    empApproverId: Array<number>,
    minExperience: number,
    maxExperience: number,
    employementType: string,
    priority: string
}
export class JobRequisitionFormBuilder {
    jobRequisitionModel: JobRequisitionModel = {
        id: null,
        name: '',
        jobDescriptionId: null,
        jobTitleId: null,
        jobPositionId: null,
        numberOfPosition: null,
        requisitionStatusType: 'OPEN',
        organizationId: null,
        departmentId: null,
        empApproverId: [],
        minExperience: null,
        maxExperience: null,
        employementType: '',
        priority: ''
    };
    jobRequisitionForm: FormGroup = new FormGroup({
        name: new FormControl(this.jobRequisitionModel.name, [Validators.required]),
        jobDescriptionId: new FormControl(this.jobRequisitionModel.jobDescriptionId, [Validators.required]),
        jobTitleId: new FormControl(this.jobRequisitionModel.jobTitleId, [Validators.required]),
        jobPositionId: new FormControl(this.jobRequisitionModel.jobPositionId, [Validators.required]),
        numberOfPosition: new FormControl(this.jobRequisitionModel.numberOfPosition, [Validators.required]),
        organizationId: new FormControl(this.jobRequisitionModel.organizationId, [Validators.required]),
        departmentId: new FormControl(this.jobRequisitionModel.departmentId, [Validators.required]),
        empApproverId: new FormControl(this.jobRequisitionModel.empApproverId, [Validators.required]),
        minExperience: new FormControl(this.jobRequisitionModel.minExperience, [Validators.required]),
        maxExperience: new FormControl(this.jobRequisitionModel.maxExperience, [Validators.required]),
        employementType: new FormControl(this.jobRequisitionModel.employementType, [Validators.required]),
        priority: new FormControl(this.jobRequisitionModel.priority, [Validators.required])
    });
    constructor() {
        this.jobRequisitionForm.valueChanges.subscribe(val => {
            this.jobRequisitionModel.name = val.name;
            this.jobRequisitionModel.jobDescriptionId = val.jobDescriptionId;
            this.jobRequisitionModel.jobTitleId = val.jobTitleId;
            this.jobRequisitionModel.jobPositionId = val.jobPositionId;
            this.jobRequisitionModel.numberOfPosition = val.numberOfPosition;
            this.jobRequisitionModel.organizationId = val.organizationId;
            this.jobRequisitionModel.departmentId = val.departmentId;
            this.jobRequisitionModel.empApproverId = val.empApproverId;
            this.jobRequisitionModel.minExperience = val.minExperience;
            this.jobRequisitionModel.maxExperience = val.maxExperience;
            this.jobRequisitionModel.employementType = val.employementType;
            this.jobRequisitionModel.priority = val.priority;
        });
    }
    setModelVals(res: JobRequisitionModel) {
        this.jobRequisitionForm.controls.name.setValue(res.name);
        this.jobRequisitionForm.controls.jobDescriptionId.setValue(res.jobDescriptionId);
        this.jobRequisitionForm.controls.jobTitleId.setValue(res.jobTitleId);
        this.jobRequisitionForm.controls.jobPositionId.setValue(res.jobPositionId);
        this.jobRequisitionForm.controls.numberOfPosition.setValue(res.numberOfPosition);
        this.jobRequisitionForm.controls.organizationId.setValue(res.organizationId);
        this.jobRequisitionForm.controls.departmentId.setValue(res.departmentId);
        this.jobRequisitionForm.controls.empApproverId.setValue(res.empApproverId);
        this.jobRequisitionForm.controls.minExperience.setValue(res.minExperience);
        this.jobRequisitionForm.controls.maxExperience.setValue(res.maxExperience);
        this.jobRequisitionForm.controls.employementType.setValue(res.employementType);
        this.jobRequisitionForm.controls.priority.setValue(res.priority);
    }
}