import { Component, OnInit } from '@angular/core';
import { RecruiterModel, RecruiterFormBuilder } from '../../shared/models/recruiter-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RecruiterService } from '../../core/services/recruiter.service';
import { FormGroup } from '@angular/forms';
import { CommonService } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-recruiter-add-edit',
  templateUrl: './recruiter-add-edit.component.html',
  styleUrls: ['./recruiter-add-edit.component.css']
})
export class RecruiterAddEditComponent implements OnInit {

  recruiter: RecruiterFormBuilder = new RecruiterFormBuilder();
  recruiterModel: RecruiterModel = this.recruiter.recruiterModel;
  recruiterForm: FormGroup = this.recruiter.recruiterForm;
  countries = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private recruiterService: RecruiterService,
    private _commonService: CommonService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.recruiterModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this._commonService.fetchCountries().subscribe(res => {
      this.countries = res.countries;
      this.recruiterModel.id ? this.fetchRecruiter() : null;
    });
  }

  fetchRecruiter() {
    this.recruiterService.fetchRecruiter(this.recruiterModel.id).subscribe((res: RecruiterModel) => {
      this.recruiterModel.externalRecruiterInfo.id = res.externalRecruiterInfo.id;
      this.recruiter.setModelVals(res);
    });
  }

  addUpdateRecruiter() {
    this.recruiterModel.id ? this.recruiterService.updateRecruiter(this.recruiterModel).subscribe((res) => {
      this.messageService.display('Recruiter update is successfull! Recruiter ID : ' + this.recruiterModel.id, 'OK', 'success');
      this.router.navigate(['/recruiter']);
    }) : this.recruiterService.addRecruiter(this.recruiterModel).subscribe((res) => {
      this.messageService.display('Recruiter add is successfull! Recruiter ID : ' + res, 'OK', 'success');
      this.router.navigate(['/recruiter']);
    });
  }

}
