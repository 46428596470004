import { HelpService } from './../../core/services/help.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { UploadFileModel, UploadFileFormBuilder, BulkImportModel, BulkImportFormBuilder } from '../../shared/models/bulk-import-model';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-bulk-import',
  templateUrl: './bulk-import.component.html',
  styleUrls: ['./bulk-import.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }, MatStepper]
})
export class BulkImportComponent implements OnInit {

  @ViewChild('stepper') private myStepper: MatStepper;

  uploadFile: UploadFileFormBuilder = new UploadFileFormBuilder();
  uploadFileModel: UploadFileModel = this.uploadFile.uploadFileModel;
  uploadFileForm: FormGroup = this.uploadFile.uploadFileForm;

  bulkImport: BulkImportFormBuilder = new BulkImportFormBuilder();
  bulkImportModel: BulkImportModel = this.bulkImport.bulkImportModel;
  bulkImportForm: FormGroup = this.bulkImport.bulkImportForm;

  selectedFileName = "Select (.zip)";
  candidatesZipFile: any = null;
  importData: boolean = false;
  allComplete: boolean = false;
  candidateProperties = [];
  excelHeaders = [];
  isInvalidFields = false;
  invalidFieldMessage = '';
  dynamicCandidateProperties = [];
  fileStorageReferenceId: any;
  disabledStepper = true;
  spinner = false;
  zipErrormessage= false;
  reviewData;

  constructor(
    private fromBuilder: FormBuilder,
    private candidateService: CandidateService,
    private messageService: MessageService,
    private helpService: HelpService,
  ) { }

  ngOnInit(): void {

  }

  importDetails() {
    this.allComplete = false;
    this.bulkImportForm.reset();
    this.spinner = true;
    this.candidateProperties = [];
    this.excelHeaders = [];
    let isMandatory;

    var requiredFieldLength;
    let formData = new FormData();
    if (this.candidatesZipFile !== null) formData.append('candidatesZipFile', this.candidatesZipFile, this.candidatesZipFile.name);
    this.candidateService.getimportedZipHeaders(formData).subscribe(res => {
      this.spinner = false;
      if (res) {
        this.importData = true;
        this.dynamicCandidateProperties = res.candidateProperties;
        this.fileStorageReferenceId = res.fileStorageReferenceId;
        res.candidateProperties.forEach(element => {
          if (element.isMandatory) {
            requiredFieldLength++;
          }
        });

        if (res.excelHeaders.length < requiredFieldLength) {
          this.messageService.display('Invalid fields in Excel file', 'OK', 'error');
          this.isInvalidFields = true;
          this.invalidFieldMessage = 'Uploaded excel has less than ' + requiredFieldLength + ' çolumns';
          return;
        } else {
          this.candidateProperties = res.candidateProperties;
            res.excelHeaders.forEach(element => {
              let excelElement= element.toUpperCase();
              let count=0;
              excelElement=excelElement.replace(/\s/g, '_')
              for(let i=0; i<this.candidateProperties.length;i++) {
              if(this.candidateProperties[i].name == excelElement) {
                count++;
                this.excelHeaders.push({ name: element, completed: false, isInvalid:  this.candidateProperties[i].isMandatory});
                break;
              }
              else {
                isMandatory = this.candidateProperties[i].isMandatory;
                count=0;
              }
            }
            if(count==0) {
              this.excelHeaders.push({ name: element, completed: false, isInvalid:  isMandatory});
            }
          });
          this.addControls();
        }
      }
      this.goForward();
    },err=> {
      if(err.error?.errors?.MandatoryFieldsMissing[0]?.message)
      this.messageService.display(err.error?.errors?.MandatoryFieldsMissing[0]?.message, 'OK', 'error');
  });
  }

  onFileInput($event) {
    var regex = /(.*?)\.(zip)$/;

    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0]?.name))) {
      this.selectedFileName = $event.srcElement?.files[0]?.name;
      this.candidatesZipFile = $event.srcElement.files[0];
      this.zipErrormessage = false;
      return
    }
      this.uploadFileForm.invalid;
      this.selectedFileName = $event.srcElement?.files[0]?.name;
      this.zipErrormessage = true;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.excelHeaders == null) {
      return;
    }
    this.excelHeaders.forEach((t,i) => {
      t.completed = completed
      if(t.completed) {
        this.bulkImportForm.get('excelheader' + i).setValue(t.name);
      }
      else {
        this.bulkImportForm.get('excelheader' + i).setValue('');
      }
    });
    this.addControls()
  }

  addControls() {
    for (let i = 0; i < this.excelHeaders.length; i++) {
      this.bulkImportForm.addControl('excelheader' + i, this.fromBuilder.control(''));
      if(this.excelHeaders[i].isInvalid) {
        this.bulkImportForm.controls['excelheader' + i].setValidators([Validators.required]);
      }
    }

    for (let i = 0; i < this.candidateProperties.length; i++) {
      this.bulkImportForm.addControl('candidateProperty' + i, this.fromBuilder.control(''));
    }
  }

  setCheckboxValue(event: MatCheckboxChange, i) {
   let count = 0 ;
    this.excelHeaders.filter((res,index)=> {
      if(i == index) {
        res.completed = event.checked;
      }
      if(res.completed) {
        count++;
      }
    });
    if (event.checked) {
      if(count == this.excelHeaders.length) {
        this.allComplete = true;
      }
      this.bulkImportForm.get('excelheader' + i).setValue(event.source.name);
      return
    }
   this.allComplete = event.checked;
    this.bulkImportForm.get('excelheader' + i).setValue('');
  }

  submitImportForm() {
    var excelHeaderWithDBFields = [];
    var checkRepeatedFields = [];
    var checkboxcount = [];
    var slectedFieldCount = []

    for (let i = 0; i < this.excelHeaders.length; i++) {
      if (this.bulkImportForm.get('excelheader' + i).value) {
        checkboxcount.push(this.bulkImportForm.get('excelheader' + i).value);
      }
      if(this.bulkImportForm.get('candidateProperty' + i).value) {
        slectedFieldCount.push(this.bulkImportForm.get('candidateProperty' + i).value)
      }

      var dataItem = {};
      if (this.bulkImportForm.get('excelheader' + i).value  && this.bulkImportForm.get('candidateProperty' + i).value) {
        dataItem['uploadField'] = this.bulkImportForm.get('excelheader' + i).value;
        dataItem['dataField'] = this.bulkImportForm.get('candidateProperty' + i).value;
        excelHeaderWithDBFields.push(dataItem);
      }
    }
    if (checkboxcount.length == slectedFieldCount.length) {
      excelHeaderWithDBFields.forEach((element, i) => {
        checkRepeatedFields.push(element['dataField']);
      });

      if (!this.checkIfArrayIsUnique(checkRepeatedFields)) {
       let payload ={"excelHeaderWithDBFields": excelHeaderWithDBFields ,'fileStorageReferenceId':this.fileStorageReferenceId }
        this.candidateService.sendExcelHeaderWithDBFields(Object.assign({}, payload)).subscribe(res=>{
          this.reviewData = res;
          this.messageService.display('File is imported successfully! ','OK', 'success');
          this.goForward()
        }, err=> {
            if(err.error?.errors?.MandatoryFieldsMissing[0]?.message)
            this.messageService.display(err.error?.errors?.MandatoryFieldsMissing[0]?.message, 'OK', 'error');
        });
      } else {
        this.messageService.display('Please map unique database fields according to uploaded excel headers.', 'OK', 'error');
        this.isInvalidFields = true;
        this.invalidFieldMessage = 'Please map unique database fields according to uploaded excel headers.';
        this.bulkImportForm.setErrors({ 'invalid': true });
        return;
      }
    } else {
      if (checkboxcount.length < slectedFieldCount.length) { 
        this.messageService.display('Please select checkbox.', 'OK', 'error');
        this.invalidFieldMessage = 'Please select checkbox.';
        }
      else {
        this.messageService.display('Please select data field.', 'OK', 'error');
        this.invalidFieldMessage = 'Please select data field.';
      }
      this.isInvalidFields = true;
      this.bulkImportForm.setErrors({ 'invalid': true });
      return;
    }
  }

  checkIfArrayIsUnique(array) {
    return array.some((val, i) => array.indexOf(val) !== i);
  }

  findHelpUrlPage() {
    const currentPage = "/#_6_1_manage_candidates";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  backToFirstStep() {
    this.selectedFileName = "Select (.zip)";
    this.myStepper.reset();
  }

  goForward() {
    this.myStepper.next();
}

}
