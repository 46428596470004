import { Component, OnInit } from '@angular/core';
import { DepartmentModel, DepartmentFormBuilder } from '../../shared/models/department-model';
import { OrganizationModel } from '../../shared/models/organization-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DepartmentService } from '../../core/services/department.service';
import { UserService } from '../../core/services/user.service';
import { OrganizationService } from '../../core/services/organization.service';
import { FormGroup } from '@angular/forms';
import { CommonService, compareFn } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-department-add-edit',
  templateUrl: './department-add-edit.component.html',
  styleUrls: ['./department-add-edit.component.css']
})
export class DepartmentAddEditComponent implements OnInit {

  department: DepartmentFormBuilder = new DepartmentFormBuilder();
  departmentModel: DepartmentModel = this.department.departmentModel;
  departmentForm: FormGroup = this.department.departmentForm;
  timeZones;
  countries;
  employees = [];
  organizations = [];
  compareFn = compareFn;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private departmentService: DepartmentService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private _commonServices: CommonService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.departmentModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this._commonServices.fetchTimeZones().subscribe(res => {
      this.timeZones = res;
    });
    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.departmentModel.id ? this.fetchDepartment() : null;
    this.fetchOrganizations();
    this.fetchEmployees();
  }

  fetchDepartment() {
    this.departmentService.fetchDepartment(this.departmentModel.id).subscribe((res: DepartmentModel) => {
      this.department.setModelVals(res);
    });
  }

  fetchOrganizations() {
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      const temp = [];
      res.forEach(function (value) {
        temp.push({ id: value.id, name: value.name });
      });
      this.organizations = temp;
    });
  }

  fetchEmployees() {
    this.userService.fetchEmployees().subscribe((res) => {
      this.employees = res;
    });
  }

  addUpdateDepartment() {
    if (this.departmentModel.id) {
      this.departmentService.updateDepartment(this.departmentModel).subscribe((res) => {
        this.messageService.display('Department update is successfull! Department ID : ' + this.departmentModel.id, 'OK', 'success');
        this.router.navigate(['/department']);
      });
    } else {
      this.departmentService.addDepartment(this.departmentModel).subscribe((res) => {
        this.messageService.display('Department add is successfull! Department ID : ' + res, 'OK', 'success');
        this.router.navigate(['/department']);
      });
    }
  }

}
