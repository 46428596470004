import { FormControl, Validators } from '@angular/forms';
import { RoleModel } from './role-model';
export interface UserModel {
    id: number;
    employeeLite: object; // id, firstName, lastName
    name: string;
    emailOrUsername: string;
    userRoles: RoleModel[];
    isForcePasswordReset: boolean;
}
export class UserFormBuilder {
    id = new FormControl();
    name = new FormControl('', [Validators.required]);
    emailOrUsername = new FormControl('', [Validators.required]);
    userRoles = new FormControl([], [Validators.required]);
    employeeLite = new FormControl(null, [Validators.required]);
    isForcePasswordReset = new FormControl(false);
}