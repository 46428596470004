import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { InterviewFeedbackListModel } from '../../shared/models/candidate-model';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-interview-feedback',
  templateUrl: './interview-feedback.component.html',
  styleUrls: ['./interview-feedback.component.css']
})
export class InterviewFeedbackComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'interviewType', 'interviewRoundStatus', 'scheduleDate', 'action'];
  dataSource: MatTableDataSource<InterviewFeedbackListModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dateTimeFormatters = dateTimeFormatters;
  showAllCheck: boolean = false;
  totalCount = 0;


  constructor(
    private candidateService: CandidateService,
    private helpService: HelpService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.fetchInterviewFeedbacks();
    this.dataSource = new MatTableDataSource<InterviewFeedbackListModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchInterviewFeedbacks() {
    this.candidateService.fetchInterviewFeedbacks().subscribe((res: InterviewFeedbackListModel[]) => {
      this.dataSource = new MatTableDataSource<InterviewFeedbackListModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_3_interview_feedback_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  showAll(event) {
    if (event.checked) {
      let obj = {};
      this.candidateService.fetchCandidatesForInterviewPendingFeedback(obj).subscribe((resp: InterviewFeedbackListModel[]) => {
        this.dataSource = new MatTableDataSource<any>(resp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    } else { 
      this.fetchInterviewFeedbacks();
    }
  }
} 
