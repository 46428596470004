import { Component, OnInit, ViewChild } from '@angular/core';
import { SkillService } from '../../core/services/skill.service';
import { FormControl } from '@angular/forms';
import { SkillModel } from '../../shared/models/skill-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css']
})
export class SkillComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'action'];
  dataSource: MatTableDataSource<SkillModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private skillService: SkillService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private helpService: HelpService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchSkills();
    this.dataSource = new MatTableDataSource<SkillModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchSkills() {
    this.skillService.fetchSkills().subscribe((res: SkillModel[]) => {
      this.dataSource = new MatTableDataSource<SkillModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage ="/#_6_6_manage_skills";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
