import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImapModel } from 'src/app/shared/models/imap-model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class ImapService {
  private stateUrl = 'api/v1/imap';

  constructor(private httpRequest: HttpRequestService) { }

  linkWithImap(imapModel: ImapModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, imapModel);
  }

  getImapDetails(): Observable<any> {
    return this.httpRequest.get(this.stateUrl);
  }
}
