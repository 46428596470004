import { Component, ElementRef } from '@angular/core';
import { LoaderService } from './shared/factory/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showProgressBar: boolean;

  constructor(
    private loaderService: LoaderService,
    public router: Router,
    private eleRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.showProgressBar = val;
      });
    });
    this.router.events.forEach((event) => {
      this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    });
  }
}
