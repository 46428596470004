import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from '../../core/services/permission.service';
import { FormControl } from '@angular/forms';
import { PermissionModel } from '../../shared/models/permission-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'action'];
  dataSource: MatTableDataSource<PermissionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private permissionService: PermissionService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private helpService: HelpService,
    private _router: Router
    ) { }

  ngOnInit() {
    this.fetchPermissions();
    this.dataSource = new MatTableDataSource<PermissionModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchPermissions() {
    this.permissionService.fetchPermissions().subscribe((res: PermissionModel[]) => {
      this.dataSource = new MatTableDataSource<PermissionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage =  "/#_7_1_manage_permissions";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
