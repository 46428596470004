import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CandidateService } from '../../core/services/candidate.service';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { EmployeeService } from '../../core/services/employee.service';
import { JobRequisitionService } from '../../core/services/job-requisition.service';
import { DashboardHelpDialogComponent } from '../../shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  activeInactiveData = {
    totalCount: 0,
    activeCount: 0,
    inactiveCount: 0
  };

  requisitionData = {
    totalCount: 0,
    approvedCount: 0,
    rejectedCount: 0,
    openCount: 0
  };

  candidatesCount = 0;

  interviewData = {
    totalCompletedCount: 0,
    totalSelectedCount: 0,
    totalRejectedCount: 0,
    totalInprogressCount: 0,
    totalEligibleCount: 0,
    rejectedRatio: 0,
    totalCandidateHold: 0
  };

  barVerticalOptions1 = {
    single: [],
    view: [347, 347],
    showXAxis: true, // options
    showYAxis: true,
    gradient: false,
    showLegend: false,
    showXAxisLabel: true,
    xAxisLabel: 'Month-Year',
    showYAxisLabel: true,
    yAxisLabel: 'Candidates',
    colorScheme: {
      domain: ['#000000', '#cccccc', '#AAAAAA']
    }
  };

  barVerticalOptions2 = {
    single: [],
    view: [347, 347],
    showXAxis: true, // options
    showYAxis: true,
    gradient: false,
    showLegend: false,
    showXAxisLabel: true,
    xAxisLabel: 'Month-Year',
    showYAxisLabel: true,
    yAxisLabel: 'Interviews',
    colorScheme: {
      domain: ['#000000', '#cccccc', '#AAAAAA']
    }
  };

  pieChartOptions = {
    single: [],
    view: [350, 350],
    showLegend: false, // options
    colorScheme: {
      domain: ['#000000', '#cccccc', '#AAAAAA']
    },
    showLabels: true, // pie
    explodeSlices: false,
    doughnut: false
  };

  /* ================================================================================================================= */

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: string = 'month';

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  /* ================================================================================================================= */

  constructor(
    private eleRef: ElementRef,
    private candidateService: CandidateService,
    private employeeService: EmployeeService,
    private jobRequisitionService: JobRequisitionService,
    public dialog: MatDialog,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchAllInterivews();
    this.fetchEmployeeStats();
    this.fetchJobRequisitionStats();
    this.fetchCandidatesCount();
    this.fetchInterviewStats();
    this.fetchShortlistedStats();
    this.fetchCandidateStats();
    this.fetchActivityStats();
  }

  onSelect(event) {
  }

  fetchAllInterivews(currentMonthDate?) {
    var dt = currentMonthDate ? new Date(currentMonthDate) : new Date();
    var month = dt.getMonth(), year = dt.getFullYear();
    var FirstDay = new Date(year, month, 1);
    var LastDay = new Date(year, month + 1, 0);
    this.candidateService.fetchAllInterivews(dateTimeFormatters.formatDate(FirstDay), dateTimeFormatters.formatDate(LastDay)).subscribe(res => {
      this.events = [];
      for (var i = 0; i < res.length; i++) {
        this.addEvent(res[i]); // require round id also for each event, to fetch or update particular event.
      }
    });
  }


  fetchEmployeeStats() {
    this.employeeService.fetchEmployeeStats().subscribe(res => {
      this.activeInactiveData = res;
    });
  }

  fetchJobRequisitionStats() {
    this.jobRequisitionService.fetchJobRequisitionStats().subscribe(res => {
      this.requisitionData = res;
    });
  }

  fetchCandidatesCount() {
    this.candidateService.fetchCandidatesCount().subscribe((res: any) => {
      this.candidatesCount = res;
    });
  }

  fetchInterviewStats() {
    this.candidateService.fetchInterviewStats().subscribe(res => {
      this.interviewData = res;
      if (this.interviewData.rejectedRatio.toString().indexOf(".") != -1) {
        this.interviewData.rejectedRatio = parseFloat(this.interviewData.rejectedRatio.toFixed(2));
      }
    });
  }

  fetchShortlistedStats() {
    this.candidateService.fetchShortlistedStats().subscribe(res => {
      for (let i = 0; i < res.length; i++) {
        let temp = {
          'name': res[i]['jobRequisitionName'],
          'value': res[i]['candidateCount']
        };
        this.pieChartOptions.single.push(temp);
        this.pieChartOptions.single = [...this.pieChartOptions.single];
      }
    });
  }

  fetchCandidateStats() {
    this.candidateService.fetchCandidateStats().subscribe(res => {
      for (let i = 0; i < res.length; i++) {
        let temp = {
          'name': res[i]['monthYear'],
          'value': res[i]['countCandidate']
        };
        this.barVerticalOptions1.single.push(temp);
        this.barVerticalOptions1.single = [...this.barVerticalOptions1.single];
      }
    });
  }

  fetchActivityStats() {
    this.candidateService.fetchActivityStats().subscribe(res => {
      for (let i = 0; i < res.length; i++) {
        let temp = {
          'name': res[i]['yearMonth'],
          'value': res[i]['interviewActivityCount']
        };
        this.barVerticalOptions2.single.push(temp);
        this.barVerticalOptions2.single = [...this.barVerticalOptions2.single];
      }
    });
  }

  viewHelpModal(contentId) {
    this.dialog.open(DashboardHelpDialogComponent, {
      data: contentId,
      width: '700px'
    });
  }

  /* ================================================================================================================= */

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.fetchFeedbackInfo(event.id);
  }

  addEvent(eventDetails): void {
    this.events.push({
      id: eventDetails.id,
      title: eventDetails.name + ' ( ' + new Date(eventDetails.scheduleDate) + ' )',
      start: addHours(new Date(eventDetails.scheduleDate), 0),
      end: addHours(new Date(eventDetails.scheduleDate), 1),
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false
      }
    });
    this.refresh.next();
  }

  handleHourSegament(event: CalendarEvent) {
  }

  monthNextPrevChange(event) {
    this.activeDayIsOpen = false;
    this.fetchAllInterivews(event);
  }
findHelpUrlPage(){
    const currentPage = "/#_3_1_dashboard";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
   
  }
  
  fetchFeedbackInfo(roundId) {
    this.candidateService.findCandidateByInterviewRoundId(roundId).subscribe(res => {
      this._router.navigate(['./candidate', res.id]);
    });
  }
}
