import { Component, OnInit, Input } from '@angular/core';
import { EmployerDetailsModel, EmployerDetailsFormBuilder } from '../../shared/models/employer-details-model';
import { customValidators } from '../../shared/factory/common.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-employer-details-add-edit',
  templateUrl: './employer-details-add-edit.component.html',
  styleUrls: ['./employer-details-add-edit.component.css']
})
export class EmployerDetailsAddEditComponent implements OnInit {

  @Input() employerDetails: EmployerDetailsModel;
  @Input() disabled: boolean;
  @Input() countries;
  employerDetailsForm: FormGroup;

  constructor() { }

  ngOnInit() {
    this.employerDetailsForm = new FormGroup({
      companyName: new FormControl(this.employerDetails.companyName),
      designation: new FormControl(this.employerDetails.designation),
      primaryPhoneNumber: new FormControl(this.employerDetails.companyContact.primaryPhoneNumber, [customValidators.numberValidator()]),
      secondaryPhoneNumber: new FormControl(this.employerDetails.companyContact.secondaryPhoneNumber, [customValidators.numberValidator()]),
      email: new FormControl(this.employerDetails.companyContact.email, [customValidators.emailValidator()]),
      address1: new FormControl(this.employerDetails.companyAddress.address1),
      address2: new FormControl(this.employerDetails.companyAddress.address2),
      country: new FormControl(this.employerDetails.companyAddress.country),
      state: new FormControl(this.employerDetails.companyAddress.state),
      city: new FormControl(this.employerDetails.companyAddress.city),
      zipCode: new FormControl(this.employerDetails.companyAddress.zipCode, [customValidators.numberValidator()]),
      fromDate: new FormControl(this.employerDetails.fromDate ? new Date(this.employerDetails.fromDate) : this.employerDetails.fromDate),
      toDate: new FormControl(this.employerDetails.toDate ? new Date(this.employerDetails.toDate) : this.employerDetails.toDate),
      website: new FormControl(this.employerDetails.website),
      noticePeriod: new FormControl(this.employerDetails.noticePeriod),
      isActiveEmployeer: new FormControl(this.employerDetails.isActiveEmployeer)
    });
    this.disabled ? this.employerDetailsForm.disable() : null;
    this.employerDetailsForm.valueChanges.subscribe(val => {
      this.employerDetails.companyName = val.companyName;
      this.employerDetails.designation = val.designation;
      this.employerDetails.companyContact.primaryPhoneNumber = val.primaryPhoneNumber;
      this.employerDetails.companyContact.secondaryPhoneNumber = val.secondaryPhoneNumber;
      this.employerDetails.companyContact.email = val.email;
      this.employerDetails.companyAddress.address1 = val.address1;
      this.employerDetails.companyAddress.address2 = val.address2;
      this.employerDetails.companyAddress.city = val.city;
      this.employerDetails.companyAddress.country = val.country;
      this.employerDetails.companyAddress.state = val.state;
      this.employerDetails.companyAddress.zipCode = val.zipCode;
      this.employerDetails.fromDate = val.fromDate;
      this.employerDetails.toDate = val.toDate;
      this.employerDetails.website = val.website;
      this.employerDetails.noticePeriod = val.noticePeriod;
      this.employerDetails.isActiveEmployeer = val.isActiveEmployeer;
    });
  }

}
