import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { MailService } from 'src/app/core/services/email.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CandidateModel } from 'src/app/shared/models/candidate-model';
import { EmailModel } from 'src/app/shared/models/email-model'
import { MailNewMessageComponent } from '../mail-new-message/mail-new-message.component';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-mail-landing',
  templateUrl: './mail-landing.component.html',
  styleUrls: ['./mail-landing.component.css']
})
export class MailLandingComponent implements OnInit {
  messages: EmailModel[];
  candidateId;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private mailService: MailService,
    private route: ActivatedRoute,
    private candidateService: CandidateService,
    private messageService: MessageService,
    private sanitized: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.candidateId = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.fetchCandidate(this.candidateId);
  }

  fetchCandidate(candidateId) {
    this.candidateService.fetchCandidate(candidateId).subscribe((res: CandidateModel) => {
      if (res) {
        this.getMailsofCandidate(res.contact.email);
      }
    }, error => {
      this.messageService.display('Error in fetching Emails:' + error, 'OK', 'error');
    });
  }

  getMailsofCandidate(emailId) {
    this.mailService.fetchEmailConversation(emailId).subscribe((res: EmailModel[]) => {
      this.messages = res;
      //console.log(res);
    })
  }

  onRemove(index: number): void {
    const copy = [...this.messages];
    copy.splice(index, 1);
    this.messages = copy;
  }

  onNewMessage(data: any = {}): void {
    const dialogRef = this.dialog.open(MailNewMessageComponent, {
      width: '75%',
      panelClass: 'new-message-dialog',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.snackBar.open('Email sent!', null, {
          duration: 2000
        });
      }
    });
  }

}
