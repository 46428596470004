import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PanoramicViewModel } from 'src/app/shared/models/panoramic-view-model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class PanoramicViewService {

  private stateUrl = 'api/v1/panoramicview';

  constructor(private httpRequest: HttpRequestService) { }

  fetchPanoramicViewData(): Observable<PanoramicViewModel> {
    return this.httpRequest.get(this.stateUrl);
  }
}
