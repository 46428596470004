import { Component, OnInit } from '@angular/core';
import { CandidateModel, CandidateFormBuilder } from '../../shared/models/candidate-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { compareFn, CommonService } from '../../shared/factory/common.service';
import { CandidateService } from '../../core/services/candidate.service';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.css']
})
export class CandidateDetailsComponent implements OnInit {

  candidate: CandidateFormBuilder = new CandidateFormBuilder();
  candidateModel: CandidateModel = this.candidate.candidateModel;
  candidateForm = this.candidate.candidateForm;
  isCandidateFetched: boolean = false;
  compareFn = compareFn;
  countries = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private candidateService: CandidateService,
    private _commonServices: CommonService
  ) { }

  ngOnInit() {
    this._commonServices.fetchCountries().subscribe(res => this.countries = res.countries);
    this.candidateForm.disable();
    this.candidateModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.fetchCandidate(this.candidateModel.id);
  }

  fetchCandidate(candidateId) {
    this.candidateService.fetchCandidate(candidateId).subscribe((res: CandidateModel) => {
      this.isCandidateFetched = true;
      this.candidate.setModelVals(res);
    });
  }

}
