import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { LoaderService } from '../../shared/factory/loader.service';

interface ScheduleRoundModel {
  id: number;
  scheduleDate: any;
  scheduleTime: any;
  interviewerEmpId: Array<number>;
  interviewType: string;
  location: string;
  notes: string;
  isIncludeCandidate:boolean;
  videoConferenceProvider:string;
}

@Component({
  selector: 'app-interview-round-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './interview-round-schedule.component.html',
  styleUrls: ['./interview-round-schedule.component.css']
})

export class InterviewRoundScheduleComponent implements OnInit {

  interviewId; roundId; candidate;
  employees = fetchCachedData('employees');
  scheduleRoundForm: FormGroup;
  scheduleRoundModel: ScheduleRoundModel = {
    id: null,
    scheduleDate: null,
    scheduleTime: null,
    interviewerEmpId: [],
    interviewType: '',
    location: '',
    notes: '',
    isIncludeCandidate: false,
    videoConferenceProvider : ''
  };

  /* ================================================================================================================= */

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: string = 'month';

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  /* ================================================================================================================= */

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private candidateService: CandidateService,
    private modal: NgbModal,
    private messageService: MessageService,
    public location: Location,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.interviewId = parseInt(this.route.snapshot.paramMap.get('interviewId'), 0);
    this.scheduleRoundModel.id = this.interviewId;
    this.roundId = parseInt(this.route.snapshot.paramMap.get('roundId'), 0);
    this.candidate = JSON.parse(sessionStorage.getItem('candidate'));
    this.scheduleRoundModel.interviewType = this.candidate.currentRound.interviewType;
    this.scheduleRoundForm = new FormGroup({
      scheduleDate: new FormControl(this.scheduleRoundModel.scheduleDate, [Validators.required]),
      scheduleTime: new FormControl(this.scheduleRoundModel.scheduleTime, [Validators.required]),
      interviewerEmpId: new FormControl(this.scheduleRoundModel.interviewerEmpId, [Validators.required]),
      interviewType: new FormControl(this.scheduleRoundModel.interviewType, [Validators.required]),
      location: new FormControl(this.scheduleRoundModel.location, [Validators.required]),
      notes: new FormControl(this.scheduleRoundModel.notes, [Validators.required]),
      isIncludeCandidate: new FormControl(this.scheduleRoundModel.isIncludeCandidate),
      // videoConferenceProvider : new FormControl(this.scheduleRoundModel.videoConferenceProvider)
      videoConferenceProvider : new FormControl('ZOOM') 

    });
    this.scheduleRoundForm.valueChanges.subscribe(val => {
      this.scheduleRoundModel.scheduleDate = dateTimeFormatters.formatDate(val.scheduleDate);
      this.scheduleRoundModel.scheduleTime = dateTimeFormatters.formatTime(val.scheduleTime);
      this.scheduleRoundModel.interviewerEmpId = val.interviewerEmpId;
      this.scheduleRoundModel.interviewType = val.interviewType;
      this.scheduleRoundModel.location = val.location;
      this.scheduleRoundModel.notes = val.notes;
      this.scheduleRoundModel.isIncludeCandidate = val.isIncludeCandidate;
      this.scheduleRoundModel.videoConferenceProvider = val.videoConferenceProvider;
    });
    this.candidate.currentRound.scheduleDate ? (this.fetchSelectedInterview(this.roundId), this.fetchAllInterivews(new Date(this.candidate.currentRound.scheduleDate)), this.viewDate = new Date(this.candidate.currentRound.scheduleDate)) : null;
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return d ? d >= currentDate : false;
  };

  fetchAllInterivews(currentMonthDate?) {
    var dt = currentMonthDate ? new Date(currentMonthDate) : new Date();
    var month = dt.getMonth(), year = dt.getFullYear();
    var FirstDay = new Date(year, month, 1);
    var LastDay = new Date(year, month + 1, 0);
    this.candidateService.fetchAllInterivews(dateTimeFormatters.formatDate(FirstDay), dateTimeFormatters.formatDate(LastDay)).subscribe(res => {
      this.events = [];
      for (var i = 0; i < res.length; i++) {
        this.addEvent(res[i]); // require round id also for each event, to fetch or update particular event.
      }
    });
  }

  fetchSelectedInterview(roundId) {
    this.candidateService.fetchSelectedInterview(roundId).subscribe(res => {
      this.scheduleRoundForm.controls.scheduleDate.setValue(new Date(res.scheduleDate));
      this.scheduleRoundForm.controls.scheduleTime.setValue(new Date(res.scheduleDate));
      let tempArr = [];
      for (var i = 0; i < res.interviewers.length; i++) {
        tempArr.push(res.interviewers[i].interviewer.id);
      }
      this.scheduleRoundForm.controls.interviewerEmpId.setValue(tempArr);
      this.scheduleRoundForm.controls.interviewType.setValue(res.interviewType);
      this.scheduleRoundForm.controls.location.setValue(res.location);
      if(this.scheduleRoundForm.controls.notes) {
        this.scheduleRoundForm.controls.notes.setValue(res.notes);
      }
      if(this.scheduleRoundForm.controls.isIncludeCandidate) {
        this.scheduleRoundForm.controls.isIncludeCandidate.setValue(res.isIncludeCandidate);
      }
    });
  }

  scheduleRound() {
    this.candidateService.scheduleRound(this.roundId, this.scheduleRoundModel).subscribe(res => {
      this.messageService.display('Round schedule is successfull! Round ID : ' + this.roundId, 'OK', 'success');
      this.loaderService.updateInboxAction(true);
      this.router.navigate(['interviews']);
    });
  }

  /* ================================================================================================================= */

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
    this.view = 'day';
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(eventDetails): void {
    this.events.push({
      id: eventDetails.id,
      title: eventDetails.name + ' ( ' + new Date(eventDetails.scheduleDate) + ' )',
      start: addHours(new Date(eventDetails.scheduleDate), 0),
      end: addHours(new Date(eventDetails.scheduleDate), 1),
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false
      }
    });
    this.refresh.next();
  }

  handleHourSegament(event: CalendarEvent) {
    this.scheduleRoundForm.controls.scheduleDate.setValue(event['date']);
    this.scheduleRoundForm.controls.scheduleTime.setValue(event['date']);
  }

  monthNextPrevChange(event) {
    this.activeDayIsOpen = false;
    this.fetchAllInterivews(event);
  }
  /* ================================================================================================================= */

}