import { Component, OnInit } from '@angular/core';
import { UserModel, UserFormBuilder } from '../../shared/models/user-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { RoleService } from '../../core/services/role.service';
import { RoleModel } from '../../shared/models/role-model';
import { Validators } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.css']
})
export class UserAddEditComponent implements OnInit {

  userForm: UserFormBuilder = new UserFormBuilder();
  userModel: UserModel = {
    id: null,
    emailOrUsername: '',
    employeeLite: null,
    name: '',
    userRoles: [],
    isForcePasswordReset: false
  };
  roles: RoleModel[] = [];
  employees = [];
  isEmployeeEnabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.fetchRoles();
    this.fetchEmployees();
    this.userModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.userModel.id ? this.fetchUser() : null;
  }

  fetchRoles() {
    this.roleService.fetchRoles().subscribe((res: RoleModel[]) => {
      this.roles = res;
    });
  }

  fetchEmployees() {
    this.userService.fetchNotUserEmployees().subscribe((res) => {
      this.employees = res;
    });
  }

  fetchUser() {
    this.userService.fetchUser(this.userModel.id).subscribe((res: UserModel) => {
      this.userForm.name.setValue(res.name);
      this.userForm.emailOrUsername.setValue(res.emailOrUsername);
      this.userForm.userRoles.setValue(res.userRoles);
      this.userForm.employeeLite.setValue(res.employeeLite);
    });
  }

  compareFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  addUpdateUser() {
    this.userModel.name = this.userForm.name.value;
    this.userModel.emailOrUsername = this.userForm.emailOrUsername.value;
    this.userModel.employeeLite = this.userForm.employeeLite.value;
    this.userModel.userRoles = this.userForm.userRoles.value;
    this.userModel.isForcePasswordReset = this.userForm.isForcePasswordReset.value;
    if (this.userModel.id) {
      this.userService.updateUser(this.userModel).subscribe((res) => {
        this.messageService.display('User update is successfull! User ID : ' + this.userModel.id, 'OK', 'success');
        this.router.navigate(['/user']);
      });
    } else {
      this.userService.addUser(this.userModel).subscribe((res) => {
        this.messageService.display('User add is successfull! User ID : ' + res, 'OK', 'success');
        this.router.navigate(['/user']);
      });
    }
  }

}
