import { Injectable } from '@angular/core';
import { finalize, tap, catchError, retry } from 'rxjs/operators';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { LoaderService } from '../../shared/factory/loader.service';
import { MessageService } from '../../shared/factory/message.service';
import { AppConfigService } from '../../configs/app-config.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  ongoingRequests: Array<string> = [];
   configs = new AppConfigService();

  constructor(
    public router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (this.ongoingRequests.length == 0) this.loaderService.display(true);
    this.ongoingRequests.push(req.url);
    const started = Date.now();
    // Get the auth token from the service.
    const authToken = sessionStorage.getItem('x-auth-token');
    if (authToken) {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      const authReq = req.clone({ setHeaders: { 'x-auth-token': authToken } });
      return next.handle(authReq).pipe(
        //retry(3), //temp to make retry req
        catchError((error: HttpErrorResponse) => this.handleError(error, this.router, this.ongoingRequests)),
        finalize(() => {
          // console.log(Date.now() - started);
          var a = this.ongoingRequests.indexOf(req.url);
          this.ongoingRequests.splice(a, 1);
          if (this.ongoingRequests.length == 0) this.loaderService.display(false);
        })
      );
    } else {
      return next.handle(req).pipe(
        //retry(3),
        catchError((error: HttpErrorResponse) => this.handleError(error, this.router, this.ongoingRequests)),
        finalize(() => {
          // console.log('Total time elapsed: ' + (Date.now() - started) + ' ms');
          var a = this.ongoingRequests.indexOf(req.url);
          this.ongoingRequests.splice(a, 1);
          if (this.ongoingRequests.length == 0) this.loaderService.display(false);
        })
      );
    }
  }

  private handleError(error: HttpErrorResponse, router: Router, ongoingRequests) {
    let msg = error.message;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      msg = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      if (error.status === 401) {
        msg = 'Unauthorized!';
        sessionStorage.clear();
        // if (ongoingRequests.length == 1) router.navigate(['/login'], { queryParams: { returnUrl: router.url } });
        //Comment-Uncomment below for SSO disable
        if(this.configs.authenticationConfigurationType=='local'){
          router.navigate(['/login']);
        }else{
          window.open(this.configs.identityServerUrl,'_self');
        }
      }
      if (error.status === 500) {
        msg = '500 Internal Server Error!';
      }
      if (error.status === 404) {
        msg = '404 Data not found!';
      }
    }
    this.messageService.display(msg, 'OK', 'error');
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
