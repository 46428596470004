import { Component, OnInit, ViewChild } from '@angular/core';
import { JobPositionService } from '../../core/services/job-position.service';
import { JobTitleService } from '../../core/services/job-title.service';
import { FormControl } from '@angular/forms';
import { JobPositionModel } from '../../shared/models/job-position-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JobTitleModel } from '../../shared/models/job-title-model';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-job-position',
  templateUrl: './job-position.component.html',
  styleUrls: ['./job-position.component.css']
})
export class JobPositionComponent implements OnInit {

  displayedColumns = ['id', 'name', 'code', 'jobtitle', 'action'];
  dataSource: MatTableDataSource<JobPositionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  jobTitles = [];

  constructor(
    private jobPositionService: JobPositionService,
    private jobTitleService: JobTitleService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchJobTitles();
    this.dataSource = new MatTableDataSource<JobPositionModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchJobTitles() {
    this.jobTitleService.fetchJobTitles().subscribe((res: JobTitleModel[]) => {
      this.jobTitles = res;
      this.fetchJobPositions();
    });
  }

  fetchJobPositions() {
    this.jobPositionService.fetchJobPositions().subscribe((res: JobPositionModel[]) => {
      for (var i = 0; i < res.length; i++) {
        const temp = this.jobTitles.find(function (element) {
          return element.id == res[i].jobTitleId;
        });
        res[i]['jobTitleName'] = temp.name;
      }
      this.dataSource = new MatTableDataSource<JobPositionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_6_5_manage_job_positions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
