import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfigService } from '../../configs/app-config.service';

export interface UserCalendarModel {
    id: number;
    calendarType:string,
    // emailId: string;
    googleRedirectUrl: string;
    calendarEventType: string;
}
export class UserCalendarFormBuilder {
    configs = new AppConfigService();
    userCalendarModel: UserCalendarModel = {
        id: null,
        calendarType:'',
        // emailId: '',
        googleRedirectUrl : this.configs.tempApiPath+"api/v1/google-calendar/receive-token",
        calendarEventType: ''
    };
    userCalendarForm: FormGroup = new FormGroup({
        calendar_type: new FormControl(this.userCalendarModel.calendarType, [Validators.required]),
        // email_id: new FormControl(this.userCalendarModel.emailId, [Validators.required])
    });
    constructor() {
        this.userCalendarForm.valueChanges.subscribe(val => {
            this.userCalendarModel.calendarType =val.calendar_type;
            // this.userCalendarModel.emailId=val.email_id;
        });
    }
    setModelVals(res: UserCalendarModel) {
        this.userCalendarForm.controls.calendar_type.setValue(res.calendarType);
        // this.userCalendarForm.controls.email_id.setValue(res.emailId);
    }

    
}