import { Component, OnInit } from '@angular/core';
import { JobDescriptionModel, JobDescriptionFormBuilder } from '../../shared/models/job-description-model';
import { SkillModel } from '../../shared/models/skill-model';
import { QualificationModel } from '../../shared/models/qualification-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { JobDescriptionService } from '../../core/services/job-description.service';
import { SkillService } from '../../core/services/skill.service';
import { QualificationService } from '../../core/services/qualification.service';
import { FormGroup } from '@angular/forms';
import { compareFn } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-job-description-add-edit',
  templateUrl: './job-description-add-edit.component.html',
  styleUrls: ['./job-description-add-edit.component.css']
})
export class JobDescriptionAddEditComponent implements OnInit {

  jobDescription: JobDescriptionFormBuilder = new JobDescriptionFormBuilder();
  jobDescriptionModel: JobDescriptionModel = this.jobDescription.jobDescriptionModel;
  jobDescriptionForm: FormGroup = this.jobDescription.jobDescriptionForm;
  skills = [];
  qualifications = [];
  compareFn = compareFn;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private jobDescriptionService: JobDescriptionService,
    private skillService: SkillService,
    private qualificationService: QualificationService,
    private messageService: MessageService,
    private helpService: HelpService,
    public location: Location,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router

  ) { }

  ngOnInit() {
    this.jobDescriptionModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.fetchSkills();
  }

  fetchSkills() {
    this.skillService.fetchSkills().subscribe((res: SkillModel[]) => {
      this.skills = res;
      this.fetchQualifications();
    });
  }

  fetchQualifications() {
    this.qualificationService.fetchQualifications().subscribe((res: QualificationModel[]) => {
      this.qualifications = res;
      this.jobDescriptionModel.id ? this.fetchJobDescription() : null;
    });
  }

  fetchJobDescription() {
    this.jobDescriptionService.fetchJobDescription(this.jobDescriptionModel.id).subscribe((res: JobDescriptionModel) => {
      this.jobDescription.setModelVals(res);
    });
  }

  addUpdateJobDescription() {
    if (this.jobDescriptionModel.id) {
      this.jobDescriptionService.updateJobDescription(this.jobDescriptionModel).subscribe((res) => {
        this.messageService.display('Job description update is successfull! Job Description ID : ' + this.jobDescriptionModel.id, 'OK', 'success');
        this.router.navigate(['/job-description']);
      });
    } else {
      this.jobDescriptionService.addJobDescription(this.jobDescriptionModel).subscribe((res) => {
        this.messageService.display('Job description add is successfull! Job Description ID : ' + res, 'OK', 'success');
        this.router.navigate(['/job-description']);
      });
    }
  }
  findHelpUrlPage(){
    const currentPage = "/#_4_1_add_job_description";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
