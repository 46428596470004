import { Component, OnInit } from '@angular/core';
import { ImapModel, ImapFormBuilder } from '../../shared/models/imap-model';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { HelpService } from '../../core/services/help.service';
import { ImapService } from 'src/app/core/services/imap.service';

@Component({
  selector: 'app-link-imap',
  templateUrl: './link-imap.component.html',
  styleUrls: ['./link-imap.component.css']
})
export class LinkImapComponent implements OnInit {
  hide = true;
  linkImap: ImapFormBuilder = new ImapFormBuilder();
  imapModel: ImapModel = this.linkImap.imapModel;
  imapForm: FormGroup = this.linkImap.imapForm;
  submitted = false;
  isImapLinked = false;

  MimeType = {
    '.PDF': 'application/pdf',
    '.DOC': 'application/msword',
    '.DOCX': 'application/vnd.openxmlformats-officedocument.wordprocessing',
  }

  constructor(
    private messageService: MessageService,
    private imapService: ImapService,
    private helpService: HelpService,
  ) { }

  ngOnInit(): void {
    this.getImapData();
  }

  getImapData() {
    this.imapService.getImapDetails().subscribe(res => {
      if (res) {
        this.imapForm.patchValue(res);
        this.imapModel.id = res.id;
        this.isImapLinked = true;
      } else {
        this.isImapLinked = false;
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.imapForm.invalid) {
      return;
    }
    this.imapService.linkWithImap(this.imapModel).subscribe(res => {
      this.messageService.display('You account is linked succesfully', 'OK', 'success');
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_6_5_manage_job_positions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
