import { Component, OnInit } from '@angular/core';
import { InterviewFeedbackFormModel } from '../../shared/models/candidate-model';
import { CandidateService } from '../../core/services/candidate.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { LoaderService } from '../../shared/factory/loader.service';
import { AppConfigService } from 'src/app/configs';

@Component({
  selector: 'app-interview-feedback-action',
  templateUrl: './interview-feedback-action.component.html',
  styleUrls: ['./interview-feedback-action.component.css']
})
export class InterviewFeedbackActionComponent implements OnInit {

  currentUserName = JSON.parse(sessionStorage.getItem('loginData')).name;
  roundName = '';
  feedbackAttachmentFile : any = null;
  selectedFileName: any = null;
  notes = '';
  interviewFeedbackFormModel: InterviewFeedbackFormModel = {
    id: null,
    skills: [],
    feedback: '',
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private candidateService: CandidateService,
    private messageService: MessageService,
    public location: Location,
    private loaderService: LoaderService,
    public _configUrls: AppConfigService,
  ) { }

  ngOnInit() {
    this.interviewFeedbackFormModel.id = parseInt(this.route.snapshot.paramMap.get('roundId'), 0);
    this.fetchFeedbackInfo();
  }

  fetchFeedbackInfo() {
    this.candidateService.fetchFeedbackInfo(this.interviewFeedbackFormModel.id).subscribe(res => {
      this.roundName = res.roundName;
      this.notes = res.notes;
      for (var i = 0; i < res.skills.length; i++) {
        let temp = {
          id: res.skills[i].id,
          name: res.skills[i].name,
          feedback: '',
          ratting: 0
        };
        this.interviewFeedbackFormModel.skills.push(temp);
      }
    });
  }

  addFeedback() {
    let formData: FormData = new FormData();
    var interviewFeedbackModel = new Blob([JSON.stringify(this.interviewFeedbackFormModel)], {
      type: 'application/json'
    });
    formData.append('interviewRoundFeedbackDTO', interviewFeedbackModel);
    if (this.feedbackAttachmentFile !== null) formData.append('feedbackAttachmentFile', this.feedbackAttachmentFile, this.feedbackAttachmentFile.name);
    this.candidateService.addFeedbackWithFile(formData).subscribe(res => {
      this.messageService.display('Feedback is added! Round ID : ' + this.interviewFeedbackFormModel.id, 'OK', 'success');
      this.router.navigate(['interview-feedbacks']);
      this.loaderService.updateInboxAction(true);
    });
  }

  onFileInput($event) {
  //  var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0)) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.feedbackAttachmentFile = $event.srcElement.files[0];
    }
  }

}
