import { Component, OnInit, ViewChild } from '@angular/core';
import { JobTitleService } from '../../core/services/job-title.service';
import { FormControl } from '@angular/forms';
import { JobTitleModel } from '../../shared/models/job-title-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.css']
})
export class JobTitleComponent implements OnInit {

  displayedColumns = ['id', 'name', 'code', 'action'];
  dataSource: MatTableDataSource<JobTitleModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private jobTitleService: JobTitleService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchJobTitles();
    this.dataSource = new MatTableDataSource<JobTitleModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchJobTitles() {
    setTimeout(() => {
      this.jobTitleService.fetchJobTitles().subscribe((res: JobTitleModel[]) => {
        this.dataSource = new MatTableDataSource<JobTitleModel>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_6_4_manage_job_titles";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

}
