import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserMailModel } from 'src/app/shared/models/user-mail-model';
import { MessageService } from 'src/app/shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from '../../core/services/help.service';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';

@Component({
  selector: 'app-link-mail',
  templateUrl: './link-mail.component.html',
  styleUrls: ['./link-mail.component.css']
})
export class LinkMailComponent implements OnInit {

  displayedColumns = ['id', 'emailType', 'email_id', 'action'];
  dataSource: MatTableDataSource<UserMailModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  hideAddEmailLinkBtn: boolean = false;

  constructor(
    private userMailService: UserCalendarService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.fetchUserEmails();
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 
  fetchUserEmails() {
    this.userMailService.fetchUserMails().subscribe((res: UserMailModel[]) => {
      if (res.length >= 1) {
        this.hideAddEmailLinkBtn = true;
      }
      else {
        this.hideAddEmailLinkBtn = false;
      }
      this.dataSource = new MatTableDataSource<UserMailModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  /**
   * @todo : Need to change this help page for User Calendar link to the GMAIL / Outlook
   */
  findHelpUrlPage(){
    const currentPage = "/#_6_8_manage_job_descriptions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  unlinkUserMail(rowId){
    this.userMailService.unlinkUserCalendar(rowId).subscribe((res) => {
      this.messageService.display('User Calendar Link Removed Successfully. ', 'OK', 'success');
      // this.router.navigate(['/link-calendar']);
      if(res) {
        this.fetchUserEmails();
      }   
    }, 
    (err) => {
      this.messageService.display('Error while unlinking user account. ', 'OK', 'error');
    });
  }
}
