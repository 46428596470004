import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators, dateTimeFormatters } from '../factory/common.service';

export interface NotesModel {
    id: number,
    note: string,
    candidateId: number,
   
}
export class NotesFormBuilder {
    notesModel: NotesModel = {
        id: null,
        note: '',
        candidateId: null,
       
    };
    notesForm: FormGroup = new FormGroup({
        note: new FormControl(this.notesModel.note),
        candidateId: new FormControl(this.notesModel.candidateId, [Validators.required]),
       
    });
    constructor() {
        this.notesForm.valueChanges.subscribe(val => {
            this.notesModel.note = val.note;
            this.notesModel.candidateId = val.candidateId;
            //this.notesModel.date = dateTimeFormatters.formatDate(val.date);
        });
    }
    setModelVals(res: NotesModel) {
        this.notesForm.controls.note.setValue(res.note);
        this.notesForm.controls.candidateId.setValue(res.candidateId);
        //this.notesForm.controls.date.setValue(res.date);
        
    }
}