import { CandidateService } from 'src/app/core/services/candidate.service';
import { CandidatePoolService } from './../../core/services/candidate-pool.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from './../../shared/factory/message.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CandidatePoolModel, CandidatePoolFormBuilder } from './../../shared/models/candidate-pool-model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-candidate-pool-add-edit',
  templateUrl: './candidate-pool-add-edit.component.html',
  styleUrls: ['./candidate-pool-add-edit.component.css']
})
export class CandidatePoolAddEditComponent implements OnInit {

  candidatePool: CandidatePoolFormBuilder = new CandidatePoolFormBuilder();
  candidatePoolModel: CandidatePoolModel = this.candidatePool.candidatePoolModel;
  candidatePoolForm: FormGroup = this.candidatePool.candidatePoolForm;
  categoryList:any;

  constructor(public location: Location, 
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private candidatePoolService: CandidatePoolService) { }

  ngOnInit() {
    this.candidatePoolModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.candidatePoolModel.id ? this.fetchCandidatePool() : null;
    this.getCategoryList();
  }

  fetchCandidatePool() {
    this.candidatePoolService.getCandidatePoolById(this.candidatePoolModel.id).subscribe((res: CandidatePoolModel) => {
      this.candidatePool.setModelVals(res);
    });
  }

  addUpdateCandidatePool() {
    if (this.candidatePoolModel.id) {
      this.candidatePoolService.updateCandidatePool(this.candidatePoolModel).subscribe(() => {
        this.messageService.display('Candidate pool successfully updated! Candidate ID : ' + this.candidatePoolModel.id, 'OK', 'success');
        this.router.navigate(['/candidate-pool']);
      });
    } else {
      this.candidatePoolService.addCandidatePool(this.candidatePoolModel).subscribe((res) => {
        this.messageService.display('Candidate pool successfully added! Candidate ID : ' + res, 'OK', 'success');
        this.router.navigate(['/candidate-pool']);
      });
    }
  }

  getCategoryList() {
    this.candidatePoolService.fetchCategoryList().subscribe(res=> {
      this.categoryList = res;
    })
  }

}
