import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface RecruiterModel {
    id: number,
    type: string,
    externalRecruiterInfo: {
        id: number,
        vendorName: string,
        vendorWebsite: string,
        vendorAddress: {
            address1: string,
            address2: string,
            country: string,
            state: string,
            city: string,
            zipCode: string
        },
        phone1: string,
        phone2: string,
        isActive: boolean
    }
}
export class RecruiterFormBuilder {
    recruiterModel: RecruiterModel = {
        id: null,
        type: 'EXTERNAL',
        externalRecruiterInfo: {
            id: null,
            vendorName: '',
            vendorWebsite: '',
            vendorAddress: {
                address1: '',
                address2: '',
                country: '',
                state: '',
                city: '',
                zipCode: ''
            },
            phone1: '',
            phone2: '',
            isActive: false
        }
    };
    recruiterForm: FormGroup = new FormGroup({
        vendorName: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorName, [Validators.required]),
        vendorWebsite: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorWebsite, [Validators.required]),
        address1: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.address1, [Validators.required]),
        address2: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.address2),
        country: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.country, [Validators.required]),
        state: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.state, [Validators.required]),
        city: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.city, [Validators.required]),
        zipCode: new FormControl(this.recruiterModel.externalRecruiterInfo.vendorAddress.zipCode, [Validators.required]),
        phone1: new FormControl(this.recruiterModel.externalRecruiterInfo.phone1, [Validators.required]),
        phone2: new FormControl(this.recruiterModel.externalRecruiterInfo.phone2),
        isActive: new FormControl(this.recruiterModel.externalRecruiterInfo.isActive)
    });
    constructor() {
        this.recruiterForm.valueChanges.subscribe(val => {
            this.recruiterModel.externalRecruiterInfo.vendorName = val.vendorName;
            this.recruiterModel.externalRecruiterInfo.vendorWebsite = val.vendorWebsite;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.address1 = val.address1;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.address2 = val.address2;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.country = val.country;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.state = val.state;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.city = val.city;
            this.recruiterModel.externalRecruiterInfo.vendorAddress.zipCode = val.zipCode;
            this.recruiterModel.externalRecruiterInfo.phone1 = val.phone1;
            this.recruiterModel.externalRecruiterInfo.phone2 = val.phone2;
            this.recruiterModel.externalRecruiterInfo.isActive = val.isActive;
        });
    }
    setModelVals(res: RecruiterModel) {
        this.recruiterForm.controls.vendorName.setValue(res.externalRecruiterInfo.vendorName);
        this.recruiterForm.controls.vendorWebsite.setValue(res.externalRecruiterInfo.vendorWebsite);
        this.recruiterForm.controls.address1.setValue(res.externalRecruiterInfo.vendorAddress.address1);
        this.recruiterForm.controls.address2.setValue(res.externalRecruiterInfo.vendorAddress.address2);
        this.recruiterForm.controls.country.setValue(res.externalRecruiterInfo.vendorAddress.country);
        this.recruiterForm.controls.state.setValue(res.externalRecruiterInfo.vendorAddress.state);
        this.recruiterForm.controls.city.setValue(res.externalRecruiterInfo.vendorAddress.city);
        this.recruiterForm.controls.zipCode.setValue(res.externalRecruiterInfo.vendorAddress.zipCode);
        this.recruiterForm.controls.phone1.setValue(res.externalRecruiterInfo.phone1);
        this.recruiterForm.controls.phone2.setValue(res.externalRecruiterInfo.phone2);
        this.recruiterForm.controls.isActive.setValue(res.externalRecruiterInfo.isActive);
    }
}
