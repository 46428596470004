import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfigService } from '../../configs/app-config.service';

export interface UserMailModel {
    id: number;
    emailType:string,
    // emailId: string;
    googleRedirectUrl: string;
}
export class UserMailFormBuilder {
    configs = new AppConfigService();
    userMailModel: UserMailModel = {
        id: null,
        emailType:'',
        // emailId: '',
        googleRedirectUrl : this.configs.tempApiPath+"api/v1/google-calendar/receive-gmail-token"
    };
    userMailForm: FormGroup = new FormGroup({
        emailType: new FormControl(this.userMailModel.emailType, [Validators.required]),
        // email_id: new FormControl(this.userMailModel.emailId, [Validators.required])
    });
    constructor() {
        this.userMailForm.valueChanges.subscribe(val => {
            this.userMailModel.emailType =val.emailType;
            // this.userMailModel.emailId=val.email_id;
        });
    }
    setModelVals(res: UserMailModel) {
        this.userMailForm.controls.emailType.setValue(res.emailType);
        // this.userMailForm.controls.email_id.setValue(res.emailId);
    }

    
}