import { Component, OnInit } from '@angular/core';
import { PermissionModel, PermissionFormBuilder } from '../../shared/models/permission-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PermissionService } from '../../core/services/permission.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-permission-add-edit',
  templateUrl: './permission-add-edit.component.html',
  styleUrls: ['./permission-add-edit.component.css']
})
export class PermissionAddEditComponent implements OnInit {

  permissionForm: PermissionFormBuilder = new PermissionFormBuilder();
  permissionModel: PermissionModel = {
    id: null,
    name: '',
    description: ''
  };

  addUpdateText = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private permissionService: PermissionService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.permissionModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.addUpdateText = this.permissionModel.id ? 'Update' : 'Add';
    this.permissionModel.id ? this.fetchPermission() : null;
  }

  fetchPermission() {
    this.permissionService.fetchPermission(this.permissionModel.id).subscribe((res: PermissionModel) => {
      this.permissionForm.name.setValue(res.name);
      this.permissionForm.description.setValue(res.description);
    });
  }

  addUpdatePermission() {
    this.permissionModel.name = this.permissionForm.name.value;
    this.permissionModel.description = this.permissionForm.description.value;
    if (this.permissionModel.id) {
      this.permissionService.updatePermission(this.permissionModel).subscribe((res) => {
        this.messageService.display('Permission update is successfull! Permission ID : ' + this.permissionModel.id, 'OK', 'success');
        this.router.navigate(['/permission']);
      });
    } else {
      this.permissionService.addPermission(this.permissionModel).subscribe((res) => {
        this.messageService.display('Permission add is successfull! Permission ID : ' + res, 'OK', 'success');
        this.router.navigate(['/permission']);
      });
    }
  }

}
