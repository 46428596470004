import { Component, OnInit } from '@angular/core';
import { JobPositionModel, JobPositionFormBuilder } from '../../shared/models/job-position-model';
import { JobTitleModel } from '../../shared/models/job-title-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { JobPositionService } from '../../core/services/job-position.service';
import { JobTitleService } from '../../core/services/job-title.service';
import { MessageService } from '../../shared/factory/message.service';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-job-position-add-edit',
  templateUrl: './job-position-add-edit.component.html',
  styleUrls: ['./job-position-add-edit.component.css']
})
export class JobPositionAddEditComponent implements OnInit {

  jobPosition: JobPositionFormBuilder = new JobPositionFormBuilder();
  jobPositionModel: JobPositionModel = this.jobPosition.jobPositionModel;
  jobPositionForm: FormGroup = this.jobPosition.jobPositionForm;
  jobTitles = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private jobPositionService: JobPositionService,
    private jobTitleService: JobTitleService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.jobPositionModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.jobPositionModel.id ? this.fetchJobPosition() : null;
    this.fetchJobtitles();
  }

  fetchJobtitles() {
    this.jobTitleService.fetchJobTitles().subscribe((res: JobTitleModel[]) => {
      this.jobTitles = res;
    });
  }

  fetchJobPosition() {
    this.jobPositionService.fetchJobPosition(this.jobPositionModel.id).subscribe((res: JobPositionModel) => {
      this.jobPosition.setModelVals(res);
    });
  }

  addUpdateJobPosition() {
    if (this.jobPositionModel.id) {
      this.jobPositionService.updateJobPosition(this.jobPositionModel).subscribe((res) => {
        this.messageService.display('Job position update is successfull! Job Position ID : ' + this.jobPositionModel.id, 'OK', 'success');
        this.router.navigate(['/job-position']);
      });
    } else {
      this.jobPositionService.addJobPosition(this.jobPositionModel).subscribe((res) => {
        this.messageService.display('Job position add is successfull! Job Position ID : ' + res, 'OK', 'success');
        this.router.navigate(['/job-position']);
      });
    }
  }

}
