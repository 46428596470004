import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators, dateTimeFormatters } from '../factory/common.service';

export interface CandidateNotesModel {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
//    candidateDTOLite: {
//     id: number,
//     firstName: string,
//     lastName: string,
//     fullName: String,
// },// id, firstName, lastName
    mobileNumber: string;
    email: string;
    address: string;
    note: string;
    notes: Array<string>;
  
}
export class CandidateNotesFormBuilder {
    candidateNotesModel: CandidateNotesModel = {
        id: null,
        firstName: '',
        lastName: '',
        fullName: '',
        // candidateDTOLite: {
        //     id: null,
        //     firstName: '',
        //     lastName: '',
        //     fullName: ''
        // },
		
       // fullName: '',
        mobileNumber: '',
        email: '',
        address: '',
        note: '',
        notes: []
    };
    candidateNotesForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.candidateNotesModel.firstName + this.candidateNotesModel.lastName , [Validators.required]),
        lastName: new FormControl(this.candidateNotesModel.lastName, [Validators.required]),
        fullName: new FormControl(this.candidateNotesModel.firstName + this.candidateNotesModel.lastName ),
    //    candidateDTOLite: new FormGroup({
    //     id: new FormControl(this.candidateNotesModel.candidateDTOLite.id),
    //     firstName: new FormControl(this.candidateNotesModel.candidateDTOLite.firstName),
    //     lastName: new FormControl(this.candidateNotesModel.candidateDTOLite.lastName),
    //     fullName: new FormControl(this.candidateNotesModel.candidateDTOLite.firstName + this.candidateNotesModel.candidateDTOLite.lastName ),
    // }),

        mobileNumber: new FormControl(this.candidateNotesModel.mobileNumber, [Validators.required]),
        email: new FormControl(this.candidateNotesModel.email, [Validators.required]),
        address: new FormControl(this.candidateNotesModel.address, [Validators.required]),
        note: new FormControl(this.candidateNotesModel.note),
        notes: new FormControl(this.candidateNotesModel.notes),
    });
    constructor() {
        this.candidateNotesForm.valueChanges.subscribe(val => {
            this.candidateNotesModel.firstName = val.firstName;
            this.candidateNotesModel.lastName = val.lastName;
          //this.candidateNotesModel.candidateDTOLite = val.candidateDTOLite;
            this.candidateNotesModel.mobileNumber = val.mobileNumber;
            this.candidateNotesModel.email = val.email;
            this.candidateNotesModel.address = val.address;
            this.candidateNotesModel.note = val.note;
            this.candidateNotesModel.notes = val.notes;
        });
    }
    setModelVals(res: CandidateNotesModel) {
        this.candidateNotesForm.controls.firstName.setValue(res.firstName + ' ' + res.lastName);
        this.candidateNotesForm.controls.lastName.setValue(res.lastName);
        this.candidateNotesForm.controls.fullName.setValue(res.fullName);
        // let candidateDetailformGroup = this.candidateNotesForm.get('candidateDTOLite') as FormGroup;
        // candidateDetailformGroup.controls.id.setValue(res.candidateDTOLite.id);
        // candidateDetailformGroup.controls.firstName.setValue(res.candidateDTOLite.firstName);
        // candidateDetailformGroup.controls.lastName.setValue(res.candidateDTOLite.lastName);
        // candidateDetailformGroup.controls.fullName.setValue(res.candidateDTOLite.firstName + res.candidateDTOLite.lastName);
        this.candidateNotesForm.controls.mobileNumber.setValue(res.mobileNumber);
        this.candidateNotesForm.controls.email.setValue(res.email);
        this.candidateNotesForm.controls.address.setValue(res.address);
        this.candidateNotesForm.controls.note.setValue(res.note);
        this.candidateNotesForm.controls.notes.setValue(res.notes);
    }
}