import { Component, HostBinding, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { MailService } from 'src/app/core/services/email.service';
import { AttachmentDetail } from 'src/app/shared/models/email-model';


@Component({
  selector: 'app-mail-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './mail-message.component.html',
  styleUrls: ['./mail-message.component.css']
})
export class MailMessageComponent {

  @HostBinding('class.message-opened')
  @Input() opened = true;

  @Input() message: any;

  @Output() removed = new EventEmitter<void>();
  @Output() reply = new EventEmitter<{ to: string, subject: string }>();

  constructor(
    private mailService: MailService
  ) {}

  onOpenToggle(): void {
    this.opened = !this.opened;
  }
  
  onReply(): void {
    // this.reply.emit({
    //   to: this.from,
    //   subject: `RE: ${this.subject}`
    // });
  }

  downloadAttachment(attachmentDetails: AttachmentDetail, msgId: string) {
    const attachment = {
      "messageId": msgId,
      "attachmentId": attachmentDetails.attachmentId,
    };
    this.mailService.downloadAttachment(attachment).subscribe((res) => {
      var url = window.URL.createObjectURL(res);
      var anchor = document.createElement("a");
      anchor.download = attachmentDetails.attachmentName;
      anchor.href = url;
      anchor.click();
    }, (error) => console.log("Error occured while downloading attachment"));
  }

}
