import { Component, OnInit } from '@angular/core';
import { OrganizationModel, OrganizationFormBuilder } from '../../shared/models/organization-model';
import { OrganizationService } from '../../core/services/organization.service';
import { FormGroup } from '@angular/forms';
import { CommonService } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  organization: OrganizationFormBuilder = new OrganizationFormBuilder();
  organizationModel: OrganizationModel = this.organization.organizationModel;
  organizationForm: FormGroup = this.organization.organizationForm;
  timeZones;
  countries;

  constructor(
    private organizationService: OrganizationService,
    private _commonServices: CommonService,
    private messageService: MessageService,
    private helpService: HelpService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this._commonServices.fetchTimeZones().subscribe(res => {
      this.timeZones = res;
    });
    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.fetchOrganization();
  }

  fetchOrganization() {
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      this.organization.setModelVals(res);
    });
  }

  updateOrganization() {
    this.organizationService.updateOrganization(this.organizationModel).subscribe(res => {
      this.messageService.display('Organization update is successfull! Organization ID : ' + this.organizationModel.id, 'OK', 'success');
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_7_4_manage_organization";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }
}
