import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {


  constructor(private permissionsService: NgxPermissionsService) { }

  loadAppAccessPermissions(): void {
   const perm = [];
      const userRoles = JSON.parse(sessionStorage.getItem('loginData')).userRoles;
      userRoles.forEach(userRole => {
          userRole.permissions.forEach(permission => {
            perm.push(permission.name);
          });
      });
      this.permissionsService.loadPermissions(perm);
  }

 
}
