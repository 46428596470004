import { Component, OnInit } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../configs/app-config.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-interview-details',
  templateUrl: './interview-details.component.html',
  styleUrls: ['./interview-details.component.css']
})
export class InterviewDetailsComponent implements OnInit {

  interviewId;
  interviewDetails: any;
  auth_token = sessionStorage.getItem('x-auth-token');

  constructor(
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    public router: Router,
    public _configUrls: AppConfigService,
    public location: Location
  ) { }

  ngOnInit() {
    this.interviewId = parseInt(this.route.snapshot.paramMap.get('interviewId'), 0);
    this.interviewId ? (this.fetchInterviewDetails(this.interviewId)) : (this.interviewId = 0, this.fetchInterviewDetailsTypeList(this.route.snapshot.paramMap.get('type'), parseInt(this.route.snapshot.paramMap.get('candidateId'), 0), parseInt(this.route.snapshot.paramMap.get('requisitionId'), 0)));
  }

  fetchInterviewDetails(interviewId) {
    this.candidateService.fetchInterviewDetails(interviewId).subscribe((res: any) => {
      this.interviewDetails = res;
    });
  }

  fetchInterviewDetailsTypeList(type, candidateId, requisitionId) {
    this.candidateService.fetchInterviewDetailsTypeList(type, candidateId, requisitionId).subscribe((res: any) => {
      this.interviewDetails = res;
      this.interviewId = res.id;
    });
  }
     setNextBasedOnPreviousURL(interviewId,roundId)
    {
    if(window.location.pathname=='/candidate/candidatefeedback/'+this.interviewId){
      this.router.navigate(['/candidate/interviews/interview/details/'+interviewId+'/'+roundId]);
    }
    else{
      this.router.navigate(['/interviews/interview/details/'+interviewId+'/'+roundId]);
    }
    }

   redirectBack(){
     this.location.back();
   }

  allow(scheduledDate) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    if (scheduledDate) {
      if (new Date(scheduledDate) < today) {
        return false;
      }
    }
    return true;
  }

}
