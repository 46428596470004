import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { EmailModel } from '../../shared/models/email-model';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private stateUrl = 'api/v1/google-mail';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchEmailConversation(candidateEmail): Observable<EmailModel[]> {
    return this.httpRequest.get(this.stateUrl + '/list-conversation?guestEmail=' + candidateEmail);
  }

  downloadAttachment(attachment:any): Observable<any> {
    return this.httpRequest.getFileBlob(this.stateUrl + '/download/'+attachment.messageId+'/'+attachment.attachmentId);
  }
  
}
