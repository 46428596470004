import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute,Router } from '@angular/router';
import { dateTimeFormatters, fetchDisplayData, fetchMultiDisplayData, customValidators } from '../../shared/factory/common.service';
import { AppConfigService } from '../../configs/app-config.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MessageService } from '../../shared/factory/message.service';
import { repeat } from 'rxjs/operators';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCandidateToPoolComponent } from '../../shared/popups/popups';



@Component({
  selector: 'app-candidate-interview',
  templateUrl: './candidate-interview.component.html',
  styleUrls: ['./candidate-interview.component.css']
})
export class CandidateInterviewComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = [ 'jobTitle', 'experience', 'mobile', 'roundName', 'roundStatus', 'addedBy', 'scheduledAt', 'interviewType', 'action'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:false}) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  dateTimeFormatters = dateTimeFormatters;
  fetchDisplayData = fetchDisplayData;
  auth_token = sessionStorage.getItem('x-auth-token');
  fetchMultiDisplayData = fetchMultiDisplayData;
  jobPositions = fetchCachedData('jobPositions');
  jobTitles = fetchCachedData('jobTitles');
  jobRequisitions = fetchCachedData('jobRequisitions');
  employees = fetchCachedData('employees');
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    requisitionId: '',
    email: '',
    phoneNumber: '',
    addedByEmpId: '',
    interviewType: '',
    roundStatus: '',
    fromScheduleDate: null,
    toScheduleDate: null,
    fromScheduleTime: null,
    toScheduleTime: null,
    roundTime: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    requisitionId: new FormControl(this.searchFormData.requisitionId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    addedByEmpId: new FormControl(this.searchFormData.addedByEmpId),
    roundStatus: new FormControl(this.searchFormData.roundStatus),
    fromScheduleDate: new FormControl(this.searchFormData.fromScheduleDate),
    toScheduleDate: new FormControl(this.searchFormData.toScheduleDate),
    fromScheduleTime: new FormControl(this.searchFormData.fromScheduleTime),
    toScheduleTime: new FormControl(this.searchFormData.toScheduleTime),
    interviewType: new FormControl(this.searchFormData.interviewType),
    roundTime: new FormControl(this.searchFormData.roundTime),
  });
  searched = false;
  expanded = true;

  constructor(
    private candidateService: CandidateService,
    public router: Router,
    public _configUrls: AppConfigService,
    private eleRef: ElementRef,
    private messageService: MessageService,
    private helpService: HelpService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public candidatePoolService: CandidatePoolService
  ) { }

  ngOnInit() {
    this.fetchCandidatesForInterviewCount();
    this.fetchCandidatesForInterview(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
  }
 
  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchEligibleCandidates() : this.fetchCandidatesForInterview(this.pageNo, this.pageSize);
    });
  }

  fetchCandidatesForInterviewCount() {
    this.candidateService.fetchCandidatesForInterviewCount().subscribe((res: any) => {
      this.totalCount = res;
  
    });
  }

  fetchCandidatesForInterview(pageNo, pageSize) {
    var i:number;
    
    this.candidateService.fetchCandidatesForInterview(pageNo, pageSize).subscribe((res: any) => {
     // for(i=0;i<res.length;i++){
       // res[i].candidate.experienceInMonth=(res[i].candidate.experienceInMonth)/12;
     // }
     
      this.dataSource = new MatTableDataSource<any>(res);
    });
  }
  

  navigate(row, interviewId, roundId) {
    sessionStorage.setItem('candidate', JSON.stringify(row));
    this.router.navigate(['/interviews', interviewId, roundId]);
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchEligibleCandidates() {
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromScheduleDate ? (this.searchFormData.fromScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromScheduleDate) + " " + this.dateTimeFormatters.formatTime(this.searchFormData.fromScheduleTime))  : null;
    this.searchFormData.toScheduleDate ? (this.searchFormData.toScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.toScheduleDate) + " " + this.dateTimeFormatters.formatTime(this.searchFormData.toScheduleTime)) : null;
    let obj = {};
    this.searchFormData.addedByEmpId ? (obj['addedByEmpId'] = this.searchFormData.addedByEmpId) : null;
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.fromScheduleDate? (obj['fromScheduleDate'] = this.searchFormData.fromScheduleDate) : null;
    this.searchFormData.toScheduleDate ? (obj['toScheduleDate'] = this.searchFormData.toScheduleDate) : null;

    
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.requisitionId ? (obj['requisitionId'] = this.searchFormData.requisitionId) : null;
    this.searchFormData.roundStatus ? (obj['roundStatus'] = this.searchFormData.roundStatus) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.interviewType ? (obj['interviewType'] = this.searchFormData.interviewType) : null;
   // this.searchFormData.roundTime ? (obj['roundTime'] = parseInt(this.searchFormData.roundTime)) : null;

    if(this.searchFormData.roundTime){

      var interviewFromDate = new Date();
      var interviewFromTime = new Date();

      interviewFromTime.setHours(interviewFromTime.getHours() - parseInt(this.searchFormData.roundTime));
      interviewFromDate.setDate(interviewFromTime.getDate());

      var interviewToDate = new Date();
      var interviewToTime = new Date();

      interviewToTime.setHours(interviewToTime.getHours() + parseInt(this.searchFormData.roundTime));
      interviewToDate.setDate(interviewToTime.getDate());
     
    this.searchFormData.fromScheduleDate? (obj['fromScheduleDate'] = interviewFromDate) : ( obj['fromScheduleDate'] = this.dateTimeFormatters.formatDate(interviewFromDate) + " " + this.dateTimeFormatters.formatTime(interviewFromTime));
    this.searchFormData.toScheduleDate ? (obj['toScheduleDate'] = interviewToDate) :(obj['toScheduleDate'] = this.dateTimeFormatters.formatDate(interviewToDate) + " " + this.dateTimeFormatters.formatTime(interviewToTime));
  
  }
    // obj != {} ? this.candidateService.fetchCandidatesForInterviewSearchCountFilter(obj).subscribe(res => {
    //   this.totalCount = res;
    //   (res > 0) ? this.candidateService.fetchCandidatesForInterviewSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
    //     this.dataSource = new MatTableDataSource<any>(resp);
    //     this.searched = true;
    //     this.closeSidenav();
    //   }) : this.messageService.display('No records found!', 'OK', 'warn');
    // }) : null;
    if (obj && Object.keys(obj).length > 0) {
      this.candidateService.fetchCandidatesForInterviewSearchCountFilter(obj).subscribe(res => {
        this.totalCount = res;
        if (res > 0) {
          this.candidateService.fetchCandidatesForInterviewSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
            this.dataSource = new MatTableDataSource<any>(resp);
            this.searched = true;
            this.closeSidenav();
          });
        } else {
          this.messageService.display('No records found!', 'OK', 'warn');
        }
      });
    } else {
      // Handle the case when obj is null or empty
      // For example, display an error message or reset the search
    }
    
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.addedByEmpId ? (canBeSearched = true) : null;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.fromScheduleDate ? (canBeSearched = true) : null;
    this.searchFormData.toScheduleDate ? (canBeSearched = true) : null;
    this.searchFormData.fromScheduleTime ? (canBeSearched = true) : null;
    this.searchFormData.toScheduleTime ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.requisitionId ? (canBeSearched = true) : null;
    this.searchFormData.interviewType ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    this.searchFormData.roundStatus ? (canBeSearched = true) : null;
    this.searchFormData.roundTime ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchEligibleCandidates();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.fetchCandidatesForInterviewCount();
      this.fetchCandidatesForInterview(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      requisitionId: '',
      email: '',
      phoneNumber: '',
      addedByEmpId: '',
      interviewType: '',
      roundStatus: '',
      fromScheduleDate: null,
      toScheduleDate: null,
      fromScheduleTime: null,
      toScheduleTime: null,
      roundTime: ''
    });
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromScheduleDate ? (this.searchFormData.fromScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromScheduleDate)) : null;
    this.searchFormData.toScheduleDate ? (this.searchFormData.toScheduleDate = this.dateTimeFormatters.formatDate(this.searchFormData.toScheduleDate)) : null;
    this.searchFormData.fromScheduleTime ? (this.searchFormData.fromScheduleTime = this.dateTimeFormatters.formatTime(this.searchFormData.fromScheduleTime)) : null;
    this.searchFormData.toScheduleTime ? (this.searchFormData.toScheduleTime = this.dateTimeFormatters.formatTime(this.searchFormData.toScheduleTime)) : null;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.fetchCandidatesForInterviewCount();
      this.fetchCandidatesForInterview(this.pageNo, this.pageSize);
    }
  }
  findHelpUrlPage(){
    const currentPage = "/#_5_1_eligible_candidates";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  openAddCandidateDialog(candidatePoolId) {
    this.candidatePoolService.getCandidatePool().subscribe((res) => {
      this.openModal(res, candidatePoolId);
    });
  }
  openModal(res, candidatePoolId) {
      this.dialog.open(AddCandidateToPoolComponent, {
        width: '30%',
      data: { res:res, candidatePoolId:candidatePoolId }
    });
  }

}
