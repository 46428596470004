import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface JobTitleModel {
    id: number;
    name: string;
    code: string;
}
export class JobTitleFormBuilder {
    jobTitleModel: JobTitleModel = {
        id: null,
        name: '',
        code: ''
    };
    jobTitleForm: FormGroup = new FormGroup({
        name: new FormControl(this.jobTitleModel.name, [Validators.required]),
    });
    constructor() {
        this.jobTitleForm.valueChanges.subscribe(val => {
            this.jobTitleModel.name = val.name;
        });
    }
    setModelVals(res: JobTitleModel) {
        this.jobTitleForm.controls.name.setValue(res.name);
    }
}