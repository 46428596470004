import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface JobPositionModel {
    id: number;
    name: string;
    code: string;
    jobTitleId: number;
}
export class JobPositionFormBuilder {
    jobPositionModel: JobPositionModel = {
        id: null,
        name: '',
        code: '',
        jobTitleId: null
    };
    jobPositionForm: FormGroup = new FormGroup({
        name: new FormControl(this.jobPositionModel.name, [Validators.required]),
        jobTitleId: new FormControl(this.jobPositionModel.jobTitleId, [Validators.required])
    });
    constructor() {
        this.jobPositionForm.valueChanges.subscribe(val => {
            this.jobPositionModel.name = val.name;
            this.jobPositionModel.jobTitleId = val.jobTitleId;
        });
    }
    setModelVals(res: JobPositionModel) {
        this.jobPositionForm.controls.name.setValue(res.name);
        this.jobPositionForm.controls.jobTitleId.setValue(res.jobTitleId);
    }
}