import { FormGroup, FormControl, Validators } from "@angular/forms";
import { customValidators } from "../factory/common.service";

export interface NewCandidateModel {
    candidatePoolId: number;
    firstName: string;
    lastName: string;
    contact: {
        primaryPhoneNumber: string;
        email: string;
    };
}

export class NewCandidateFormBuilder {
    newCandidateModel: NewCandidateModel = {
        candidatePoolId: null,
        contact: {
            primaryPhoneNumber: '',
            email: ''
        },
        firstName: '',
        lastName: '',
    };
    newCandidateForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.newCandidateModel.firstName, [Validators.required]),
        lastName: new FormControl(this.newCandidateModel.lastName, [Validators.required]),
        primaryPhoneNumber: new FormControl(this.newCandidateModel.contact.primaryPhoneNumber, [Validators.required, customValidators.numberValidator()]),
        email: new FormControl(this.newCandidateModel.contact.email, [Validators.required, customValidators.emailValidator()]),
        resumeFile: new FormControl(null),
    });
    constructor() {
        this.newCandidateForm.valueChanges.subscribe(val => {
            this.newCandidateModel.firstName = val.firstName;
            this.newCandidateModel.lastName = val.lastName;
            this.newCandidateModel.contact.primaryPhoneNumber = val.primaryPhoneNumber;
            this.newCandidateModel.contact.email = val.email;
        });
    }
}

export interface ExistingCandidateModel {
    candidateId: number;
    candidatePools: [];
}

export class ExistingCandidateFormBuilder {
    existingCandidateModel: ExistingCandidateModel = {
        candidateId: null,
        candidatePools: []
    };
    existingCandidateForm: FormGroup = new FormGroup({
        candidatePools: new FormControl(this.existingCandidateModel.candidatePools, [Validators.required]),
    });
    constructor() {
        this.existingCandidateForm.valueChanges.subscribe(val => {
            this.existingCandidateModel.candidatePools = val.candidatePools;
        });
    }
}