import { Component, OnInit, ViewChild } from '@angular/core';
import { JobDescriptionService } from '../../core/services/job-description.service';
import { FormControl } from '@angular/forms';
import { JobDescriptionModel } from '../../shared/models/job-description-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.css']
})
export class JobDescriptionComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'action'];
  dataSource: MatTableDataSource<JobDescriptionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private jobDescriptionService: JobDescriptionService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchJobDescriptions();
    this.dataSource = new MatTableDataSource<JobDescriptionModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchJobDescriptions() {
    this.jobDescriptionService.fetchJobDescriptions().subscribe((res: JobDescriptionModel[]) => {
      this.dataSource = new MatTableDataSource<JobDescriptionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_6_8_manage_job_descriptions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
