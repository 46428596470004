import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from "rxjs";
import { UserCalendarModel } from "../../shared/models/user-calendar-model";
import { UserMailModel } from 'src/app/shared/models/user-mail-model';

@Injectable({
  providedIn: 'root'
})
export class UserCalendarService {


  private stateUrl = 'api/v1/integration';

  private calendarLinkUrl = this.stateUrl + "/link-calendar";
  private emailLinkUrl = this.stateUrl + "/link-mail";

  private listCalendarUrl = this.stateUrl + "/list-calendar";
  private listEmailUrl = this.stateUrl + "/list-mail";

  private unlinkUrl = this.stateUrl + "/unlink";

  private calendarSummaryUrl = this.stateUrl + "/calendar-details";

  private calendarSummaryUpdaetUrl = this.stateUrl + "/link-calendar-summary";

  constructor(private httpRequest: HttpRequestService) { }

  fetchUserCalendars(calendarEventType): Observable<UserCalendarModel[]> {
    return this.httpRequest.get(this.listCalendarUrl+'?calendarEventType=' + calendarEventType);
  }

  fetchUserMails(): Observable<any> {
    return this.httpRequest.get(this.listEmailUrl);
  }

  /**
   * 
   * @param userCalendarModel 
   * 
   * @todo : Confirm the way URL is appended is ok ?
   */
  connectToUserCalendar(userCalendarModel: UserCalendarModel): Observable<any> {
    return this.httpRequest.post(this.calendarLinkUrl,userCalendarModel);
  }

  unlinkUserCalendar(userCalendarId): Observable<any>{
    return this.httpRequest.get(this.unlinkUrl + "/"+ userCalendarId);
  }

  getCalendarSummary(userCalendarId) {
    return this.httpRequest.get(this.calendarSummaryUrl + "/"+ userCalendarId);
  }

  /**
   * 
   * @param userMailModel 
   * 
   * @todo : Confirm the way URL is appended is ok ?
   */
  connectToUserMail(userMailModel: UserMailModel): Observable<any> {
    return this.httpRequest.post(this.emailLinkUrl,userMailModel);
  }

  updateCalendarLink(calendarSummaryDTO) {
    return this.httpRequest.put(this.calendarSummaryUpdaetUrl, calendarSummaryDTO);
  }

  fetchCalendarSummary(calendarId) {
      return this.httpRequest.get(this.stateUrl + "/get-user-calendar-link/" + calendarId)
  }
}
