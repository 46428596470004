import { FormGroup, FormControl, Validators } from "@angular/forms";
import { customValidators } from "../factory/common.service";

export interface EmployerDetailsModel {
    id: number,
    companyName: string,
    designation: string,
    fromDate: any,
    toDate: any,
    website: string,
    companyAddress: {
        address1: string,
        address2: string,
        country: string,
        state: string,
        city: string,
        zipCode: string
    },
    companyContact: {
        primaryPhoneNumber: string,
        secondaryPhoneNumber: string,
        email: string
    },
    noticePeriod: number,
    isActiveEmployeer: boolean
}

export class EmployerDetailsFormBuilder {
    employerDetailsModel: EmployerDetailsModel = {
        id: null,
        companyName: '',
        designation: '',
        fromDate: null,
        toDate: null,
        website: '',
        companyAddress: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: ''
        },
        companyContact: {
            primaryPhoneNumber: '',
            secondaryPhoneNumber: '',
            email: ''
        },
        noticePeriod: null,
        isActiveEmployeer: false
    };
}