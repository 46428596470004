import { Component, OnInit } from '@angular/core';
import { SkillModel, SkillFormBuilder } from '../../shared/models/skill-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SkillService } from '../../core/services/skill.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-skill-add-edit',
  templateUrl: './skill-add-edit.component.html',
  styleUrls: ['./skill-add-edit.component.css']
})
export class SkillAddEditComponent implements OnInit {

  skill: SkillFormBuilder = new SkillFormBuilder();
  skillModel: SkillModel = this.skill.skillModel;
  skillForm: FormGroup = this.skill.skillForm;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private skillService: SkillService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.skillModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.skillModel.id ? this.fetchSkill() : null;
  }

  fetchSkill() {
    this.skillService.fetchSkill(this.skillModel.id).subscribe((res: SkillModel) => {
      this.skill.setModelVals(res);
    });
  }

  addUpdateSkill() {
    if (this.skillModel.id) {
      this.skillService.updateSkill(this.skillModel).subscribe((res) => {
        this.messageService.display('Skill update is successfull! Skill ID : ' + this.skillModel.id, 'OK', 'success');
        this.router.navigate(['/skill']);
      });
    } else {
      this.skillService.addSkill(this.skillModel).subscribe((res) => {
        this.messageService.display('Skill add is successfull! Skill ID : ' + res, 'OK', 'success');
        this.router.navigate(['/skill']);
      });
    }
  }

}
