import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './';
import { SSOGatewayComponent } from './sso-gateway/sso-gateway.component';
import { MaterialModule } from '../shared/modules/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  // <-- #1 import module
import { LoginService } from '../core/services/login.service';
import { SSOGatewayService } from '../core/services/sso-gateway.service';

import { AppRoutingModule } from '..//app-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PermissionComponent } from './permission/permission.component';
import { PermissionAddEditComponent } from './permission-add-edit/permission-add-edit.component';
import { RoleComponent } from './role/role.component';
import { RoleAddEditComponent } from './role-add-edit/role-add-edit.component';
import { UserComponent } from './user/user.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { OrganizationComponent } from './organization/organization.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentAddEditComponent } from './department-add-edit/department-add-edit.component';
import { JobTitleComponent } from './job-title/job-title.component';
import { JobTitleAddEditComponent } from './job-title-add-edit/job-title-add-edit.component';
import { JobPositionComponent } from './job-position/job-position.component';
import { JobPositionAddEditComponent } from './job-position-add-edit/job-position-add-edit.component';
import { SkillComponent } from './skill/skill.component';
import { SkillAddEditComponent } from './skill-add-edit/skill-add-edit.component';
import { QualificationComponent } from './qualification/qualification.component';
import { QualificationAddEditComponent } from './qualification-add-edit/qualification-add-edit.component';
import { JobDescriptionComponent } from './job-description/job-description.component';
import { JobDescriptionAddEditComponent } from './job-description-add-edit/job-description-add-edit.component';
import { RecruiterComponent } from './recruiter/recruiter.component';
import { EmployerDetailsAddEditComponent } from './employer-details-add-edit/employer-details-add-edit.component';
import { RecruiterAddEditComponent } from './recruiter-add-edit/recruiter-add-edit.component';
import { JobRequisitionComponent } from './job-requisition/job-requisition.component';
import { JobRequisitionAddEditComponent } from './job-requisition-add-edit/job-requisition-add-edit.component';
import { CandidateComponent } from './candidate/candidate.component';
import { CandidateAddEditComponent } from './candidate-add-edit/candidate-add-edit.component';
import { JobRequisitionActionComponent } from './job-requisition-action/job-requisition-action.component';
import { CandidateInterviewComponent } from './candidate-interview/candidate-interview.component';
import { InterviewRoundScheduleComponent } from './interview-round-schedule/interview-round-schedule.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CalendarModule, DateAdapter  } from 'angular-calendar';
import { StarRatingModule } from 'angular-star-rating';
import { InterviewDetailsComponent } from './interview-details/interview-details.component';
import { InterviewFeedbackComponent } from './interview-feedback/interview-feedback.component';
import { InterviewFeedbackActionComponent } from './interview-feedback-action/interview-feedback-action.component';
import { InterviewRoundDetailsComponent } from './interview-round-details/interview-round-details.component';
import { InterviewActionComponent } from './interview-action/interview-action.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ShortlistedCandidatesComponent } from './shortlisted-candidates/shortlisted-candidates.component';
import { RejectedCandidatesComponent } from './rejected-candidates/rejected-candidates.component';
import { JobOfferComponent } from './job-offer/job-offer.component';
import { InterviewActionDialogComponent,AddCandidateToPoolComponent, CandidateOfferDialogComponent, EmployeeStartupDialogComponent, ProfilePictureDialogComponent, DashboardHelpDialogComponent, BoardingComponent, ConfirmationDialogComponent, NotesComponent, DeleteCandidateComponent, RevokeFeedbackComponent, FeedbackRequestDialogComponent, CalendarSummaryComponent, ConfirmationBoxComponent,CandidateResumeUploadDialogComponent, ViewCandidateQualificationDetailsComponent, candidateEvalutionRequestComponent} from '../shared/popups/popups';
import { CandidateDetailsComponent } from './candidate-details/candidate-details.component';
import { ReferComponent } from './refer/refer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MonthToYearPipe } from '../month-to-year.pipe';
import { LinkCalendarComponent } from './link-calendar/link-calendar.component';
import { LinkCalendarAddEditComponent } from './link-calendar-add-edit/link-calendar-add-edit.component';
import { PanoramicViewComponent } from './panoramic-view/panoramic-view.component';
import { CandidateTagsComponent } from './candidate-tags/candidate-tags.component';
import { CandidateTagsAddEditComponent } from './candidate-tags-add-edit/candidate-tags-add-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ActivitiesComponent } from './activities/activities.component';
import { KanbanBoardComponent } from './kanban-board/kanban-board.component';
import { CandidatePoolComponent } from './candidate-pool/candidate-pool.component';
import { CandidatePoolAddEditComponent } from './candidate-pool-add-edit/candidate-pool-add-edit.component';
import { SearchComponent } from './search/search.component';
import { MailLandingComponent } from './mail-landing/mail-landing.component';
import { MailNewMessageComponent } from './mail-new-message/mail-new-message.component';
import { MailMessageComponent } from './mail-message/mail-message.component';
import { AgoDatePipe } from '../pipes/ago-date.pipe';
import { UserNamePipe } from '../pipes/user-name.pipe';
import { LinkImapComponent } from './link-imap/link-imap.component';
import { LinkMailComponent } from './link-mail/link-mail.component';
import { LinkMailAddEditComponent } from './link-mail-add-edit/link-mail-add-edit.component';
import { BulkImportComponent } from './bulk-import/bulk-import.component';
import { SafehtmlPipe } from '../pipes/safehtml.pipe';
import { RevokeFeedbackRequestComponent } from './revoke-feedback-request/revoke-feedback-request.component';
import { QuillModule } from 'ngx-quill';
import { CandidateQualificationComponent } from './candidate-qualification/candidate-qualification.component';
import { CandidateQualificationActionComponent } from './candidate-qualification-action/candidate-qualification-action.component';
import { CandidateQualificationAddComponent } from './candidate-qualification-add/candidate-qualification-add.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    NgxChartsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StarRatingModule.forRoot(),
    InfiniteScrollModule,
    NgxPermissionsModule.forRoot(),
    ColorPickerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic'], // Toggle buttons
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }], // Custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }], // Superscript/Subscript
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }], // Dropdown with defaults from theme
          ['link'], // Link and Image
          ['clean'], // Remove formatting button
        ],
      },
    }), 
  ],
  providers: [
    LoginService,
    SSOGatewayService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }
  ],
  declarations: [
    LoginComponent,
    SSOGatewayComponent,
    DashboardComponent,
    HomeComponent,
    PermissionComponent,
    PermissionAddEditComponent,
    RoleComponent,
    RoleAddEditComponent,
    UserComponent,
    UserAddEditComponent,
    OrganizationComponent,
    DepartmentComponent,
    DepartmentAddEditComponent,
    JobTitleComponent,
    JobTitleAddEditComponent,
    JobPositionComponent,
    JobPositionAddEditComponent,
    SkillComponent,
    SkillAddEditComponent,
    QualificationComponent,
    QualificationAddEditComponent,
    JobDescriptionComponent,
    JobDescriptionAddEditComponent,
    RecruiterComponent,
    RecruiterAddEditComponent,
    EmployerDetailsAddEditComponent,
    JobRequisitionComponent,
    JobRequisitionAddEditComponent,
    CandidateComponent,
    CandidateAddEditComponent,
    JobRequisitionActionComponent,
    CandidateInterviewComponent,
    InterviewRoundScheduleComponent,
    InterviewDetailsComponent,
    InterviewFeedbackComponent,
    InterviewFeedbackActionComponent,
    InterviewRoundDetailsComponent,
    InterviewActionComponent,
    InterviewActionDialogComponent,
    AddCandidateToPoolComponent,
    ShortlistedCandidatesComponent,
    RejectedCandidatesComponent,
    CandidateOfferDialogComponent,
    JobOfferComponent,
    EmployeeStartupDialogComponent,
    ProfilePictureDialogComponent,
    DashboardHelpDialogComponent,
    CandidateDetailsComponent,
    ReferComponent,
    MonthToYearPipe,
    BoardingComponent,CandidateResumeUploadDialogComponent,
    ConfirmationDialogComponent,
    NotesComponent,
    LinkCalendarComponent,
    LinkCalendarAddEditComponent,
    PanoramicViewComponent,
    CandidateTagsComponent,
    CandidateTagsAddEditComponent,
    ActivitiesComponent,
    KanbanBoardComponent,
    CandidatePoolComponent,
    CandidatePoolAddEditComponent,
    SearchComponent,
    MailLandingComponent,
    MailNewMessageComponent,
    MailMessageComponent,
    AgoDatePipe,
    UserNamePipe,
    LinkImapComponent,
    LinkMailComponent,
    LinkMailAddEditComponent,
    BulkImportComponent,
    SafehtmlPipe,
    DeleteCandidateComponent,
    RevokeFeedbackRequestComponent,
    RevokeFeedbackComponent,
    FeedbackRequestDialogComponent,
    CalendarSummaryComponent,
    ConfirmationBoxComponent,
    CandidateQualificationComponent,
    CandidateQualificationActionComponent,
    CandidateQualificationAddComponent,ViewCandidateQualificationDetailsComponent,candidateEvalutionRequestComponent
  ],
  entryComponents: [
    InterviewActionDialogComponent,
    AddCandidateToPoolComponent,
    CandidateOfferDialogComponent,
    EmployeeStartupDialogComponent,
    ProfilePictureDialogComponent,
    DashboardHelpDialogComponent,
    ViewCandidateQualificationDetailsComponent,
    ConfirmationDialogComponent,
    BoardingComponent,
    CandidateResumeUploadDialogComponent,
    NotesComponent,
    RevokeFeedbackComponent,
    FeedbackRequestDialogComponent,
    CalendarSummaryComponent,
    ConfirmationBoxComponent,candidateEvalutionRequestComponent
  ]
})
export class ComponentsModule { }
