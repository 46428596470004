import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../core/services/role.service';
import { FormControl } from '@angular/forms';
import { RoleModel } from '../../shared/models/role-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'permissions', 'action'];
  dataSource: MatTableDataSource<RoleModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private roleService: RoleService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private helpService: HelpService,
    private _router: Router

  ) { }

  ngOnInit() {
    this.fetchRoles();
    this.dataSource = new MatTableDataSource<RoleModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchRoles() {
    this.roleService.fetchRoles().subscribe((res: RoleModel[]) => {
      this.dataSource = new MatTableDataSource<RoleModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  findHelpUrlPage(){
    const currentPage = "/#_7_2_manage_roles";
    
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }
  
}
