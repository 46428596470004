import { FormGroup,FormControl,Validators } from '@angular/forms';
export interface CandidateTagsModel {
    id:number,
    status:string,
    color:string
}
export class CandidateTagsFormBuilder{
    candidateTagsModel:CandidateTagsModel = {
        id:null,
        status:'',
        color:''
    };
    candidateTagsForm: FormGroup = new FormGroup({
        status: new FormControl(this.candidateTagsModel.status,[Validators.required]),
        color: new FormControl(this.candidateTagsModel.color) 
        /* color: new FormControl(this.candidateTagsModel.color,[Validators.required]) */
    });
    constructor(){
        this.candidateTagsForm.valueChanges.subscribe(val=>{
            this.candidateTagsModel.status = val.status;
            this.candidateTagsModel.color = val.color;
        })
    }
    setModelValues(res: CandidateTagsModel){
        this.candidateTagsForm.controls.status.setValue(res.status);
        this.candidateTagsForm.controls.color.setValue(res.color);
    }
}
