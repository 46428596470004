import { HttpRequestService } from './../http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidatePoolModel } from '../../shared/models/candidate-pool-model';

@Injectable({
  providedIn: 'root'
})
export class CandidatePoolService {

  private stateUrl = 'api/v1/candidate-pool';

  constructor(private httpRequestService: HttpRequestService) { }

  updateCandidatePool(data: CandidatePoolModel): Observable<any> {
    return this.httpRequestService.put(this.stateUrl, data);
  }

  addCandidatePool(data: CandidatePoolModel): Observable<any> {
    return this.httpRequestService.post(this.stateUrl, data);
  }

  getCandidatePool():Observable<CandidatePoolModel[]> {
    return this.httpRequestService.get(this.stateUrl);
  }

  getCandidatePoolById(id):Observable<CandidatePoolModel> {
     return this.httpRequestService.get(this.stateUrl + '/' + id);
  }

  addNewCandidateToPool(data: FormData) {
    return this.httpRequestService.filePost(this.stateUrl + '/add-candidate',data);
  }

  fetchCategoryList(): Observable<any> {
    return this.httpRequestService.get(this.stateUrl + '/get-category-list');
  }
}
