import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface SkillModel {
    id: number;
    name: string;
    description: string;
}
export class SkillFormBuilder {
    skillModel: SkillModel = {
        id: null,
        name: '',
        description: ''
    };
    skillForm: FormGroup = new FormGroup({
        name: new FormControl(this.skillModel.name, [Validators.required]),
        description: new FormControl(this.skillModel.description, [Validators.required])
    });
    constructor() {
        this.skillForm.valueChanges.subscribe(val => {
            this.skillModel.name = val.name;
            this.skillModel.description = val.description;
        });
    }
    setModelVals(res: SkillModel) {
        this.skillForm.controls.name.setValue(res.name);
        this.skillForm.controls.description.setValue(res.description);
    }
}
