import { CandidateTagsService } from './../../core/services/candidate-tags.service';
import { Component, OnInit } from '@angular/core';
import { CandidateModel, CandidateFormBuilder } from '../../shared/models/candidate-model';
import { EmployerDetailsModel, EmployerDetailsFormBuilder } from '../../shared/models/employer-details-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CandidateService } from '../../core/services/candidate.service';
import { FormGroup } from '@angular/forms';
import { CommonService, compareFn, customValidators } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { AppConfigService } from '../../configs/app-config.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { RecruiterService } from '../../core/services/recruiter.service';
@Component({
  selector: 'app-candidate-add-edit',
  templateUrl: './candidate-add-edit.component.html',
  styleUrls: ['./candidate-add-edit.component.css']
})
export class CandidateAddEditComponent implements OnInit {

  candidate: CandidateFormBuilder = new CandidateFormBuilder();
  candidateModel: CandidateModel = this.candidate.candidateModel;
  candidateForm: FormGroup = this.candidate.candidateForm;
  countries = [];
  qualifications = fetchCachedData('qualifications');
  skills = fetchCachedData('skills');
  recruiters = fetchCachedData('recruiters');
  jobRequisitions = fetchCachedData('jobRequisitions');
  auth_token = sessionStorage.getItem('x-auth-token');
  compareFn = compareFn;
  selectedFileName = "";
  resumeFile: any = null;
  candidateTags = fetchCachedData('candidateTags');
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private candidateService: CandidateService,
    private _commonServices: CommonService,
    public _configUrls: AppConfigService,
    private messageService: MessageService,
    public location: Location,
    private candidateTagsService: CandidateTagsService
  ) { }

  ngOnInit() {
    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.candidateModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.candidateModel.id ? this.fetchCandidate() : null;
   // console.log(this.candidateModel.id)
    this.fetchCandidateTag();   
  }

  fetchCandidate() {
    this.candidateService.fetchCandidate(this.candidateModel.id).subscribe((res: CandidateModel) => {
      this.candidate.setModelVals(res);
    });
  }

  onFileInput($event) {
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.resumeFile = $event.srcElement.files[0];
    }
  }

  addEmployerDetails() {
    const employeeDetailClass: EmployerDetailsFormBuilder = new EmployerDetailsFormBuilder();
    let employerDetails: EmployerDetailsModel = employeeDetailClass.employerDetailsModel;
    this.candidateModel.employerDetails.push(employerDetails);
  }

  addUpdateCandidate() {
    let experienceInYear = this.candidateForm.get('experienceInMonth').value
    let index = experienceInYear.indexOf('.')
    if( index !== -1) {
      let year = Number(experienceInYear.slice(0,index))
      let month = Number(experienceInYear.slice(index+1, experienceInYear.length))
      this.candidateForm.controls['experienceInMonth'].setValue(month + (year*12));
    }
    else {
      this.candidateForm.controls['experienceInMonth'].setValue(Number(experienceInYear)*12);
    }
    let formData: FormData = new FormData();
    var candidateDTOBlob = new Blob([JSON.stringify(this.candidateModel)], {
      type: 'application/json'
    });
    formData.append('candidateDTO', candidateDTOBlob);
    if (this.resumeFile !== null) formData.append('resumeFile', this.resumeFile, this.resumeFile.name);
    if (this.candidateModel.id) {
      this.candidateService.updateCandidate(formData).subscribe((res) => {
        this.messageService.display('Candidate update is successfull! Candidate ID : ' + this.candidateModel.id, 'OK', 'success');
        this.router.navigate(['/candidate']);
      });
    } else {
      this.candidateService.addCandidate(formData).subscribe((res) => {
        this.messageService.display('Candidate add is successfull! Candidate ID : ' + res, 'OK', 'success');
        this.router.navigate(['/candidate']);
      });
    }
  }

  fetchCandidateTag() {
    this.candidateTagsService.fetchAllCandidateTags().subscribe(res=> {
         this.candidateTags = res;
    })
  }

}
