import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators } from "../factory/common.service";

export interface ImapModel {
    id: number;
    username: string;
    password: string;
    emailToMonitor: string;
    imapUrl: string;
    attachments: [];
}
export class ImapFormBuilder {
    imapModel: ImapModel = {
        id: null,
        username: '',
        password: '',
        emailToMonitor: '',
        imapUrl: '',
        attachments: []
    };
    imapForm: FormGroup = new FormGroup({
        username: new FormControl(this.imapModel.username, [Validators.required]),
        password: new FormControl(this.imapModel.password, [Validators.required]),
        emailToMonitor: new FormControl(this.imapModel.emailToMonitor, [Validators.required, customValidators.emailValidator()]),
        attachments: new FormControl(this.imapModel.attachments, [Validators.required]),
        imapUrl: new FormControl(this.imapModel.imapUrl, [Validators.required]),
    });
    constructor() {
        this.imapForm.valueChanges.subscribe(val => {
            this.imapModel.username = val.username;
            this.imapModel.password = val.password;
            this.imapModel.emailToMonitor = val.emailToMonitor;
            this.imapModel.attachments = val.attachments;
            this.imapModel.imapUrl = val.imapUrl;
        });
    }
    setModelVals(res: ImapModel) {
        this.imapForm.controls.username.setValue(res.username);
        this.imapForm.controls.password.setValue(res.password);
        this.imapForm.controls.emailToMonitor.setValue(res.emailToMonitor);
        this.imapForm.controls.attachments.setValue(res.attachments);
        this.imapForm.controls.imapUrl.setValue(res.imapUrl);
        this.imapModel.id = res.id;
    }
}