import { FormControl, Validators } from '@angular/forms';
import { PermissionModel } from './permission-model';
export interface RoleModel {
    id: number;
    name: string;
    description: string;
    permissions: PermissionModel[];
}
export class RoleFormBuilder {
    id = new FormControl();
    name = new FormControl('', [Validators.required]);
    description = new FormControl('', [Validators.required]);
    permissions = new FormControl([], [Validators.required]);
}