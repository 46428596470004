import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { SSOGatewayComponent } from './components/sso-gateway/sso-gateway.component';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { JobTitleComponent } from './components/job-title/job-title.component';
import { JobTitleAddEditComponent } from './components/job-title-add-edit/job-title-add-edit.component';
import { JobPositionComponent } from './components/job-position/job-position.component';
import { JobPositionAddEditComponent } from './components/job-position-add-edit/job-position-add-edit.component';
import { SkillComponent } from './components/skill/skill.component';
import { SkillAddEditComponent } from './components/skill-add-edit/skill-add-edit.component';
import { QualificationComponent } from './components/qualification/qualification.component';
import { QualificationAddEditComponent } from './components/qualification-add-edit/qualification-add-edit.component';
import { JobDescriptionComponent } from './components/job-description/job-description.component';
import { JobDescriptionAddEditComponent } from './components/job-description-add-edit/job-description-add-edit.component';
import { JobRequisitionComponent } from './components/job-requisition/job-requisition.component';
import { JobRequisitionAddEditComponent } from './components/job-requisition-add-edit/job-requisition-add-edit.component';
import { CandidateComponent } from './components/candidate/candidate.component';
import { CandidateDetailsComponent } from './components/candidate-details/candidate-details.component';
import { CandidateAddEditComponent } from './components/candidate-add-edit/candidate-add-edit.component';
import { JobRequisitionActionComponent } from './components/job-requisition-action/job-requisition-action.component';
import { CandidateInterviewComponent } from './components/candidate-interview/candidate-interview.component';
import { InterviewRoundScheduleComponent } from './components/interview-round-schedule/interview-round-schedule.component';
import { InterviewDetailsComponent } from './components/interview-details/interview-details.component';
import { InterviewFeedbackComponent } from './components/interview-feedback/interview-feedback.component';
import { InterviewFeedbackActionComponent } from './components/interview-feedback-action/interview-feedback-action.component';
import { InterviewRoundDetailsComponent } from './components/interview-round-details/interview-round-details.component';
import { InterviewActionComponent } from './components/interview-action/interview-action.component';
import { ShortlistedCandidatesComponent } from './components/shortlisted-candidates/shortlisted-candidates.component';
//import { NotesComponent } from './components/notes/notes.component';
import { RejectedCandidatesComponent } from './components/rejected-candidates/rejected-candidates.component';
import { JobOfferComponent } from './components/job-offer/job-offer.component';
import { ReferComponent } from './components/refer/refer.component';
import { LinkCalendarComponent } from './components/link-calendar/link-calendar.component';
import { LinkCalendarAddEditComponent } from './components/link-calendar-add-edit/link-calendar-add-edit.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { CandidateTagsComponent } from './components/candidate-tags/candidate-tags.component';

import { AuthGuard } from './core/guards/auth.guard';
import { from } from 'rxjs';
import { PanoramicViewComponent } from './components/panoramic-view/panoramic-view.component';
import { CandidateTagsAddEditComponent } from './components/candidate-tags-add-edit/candidate-tags-add-edit.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { KanbanBoardComponent } from './components/kanban-board/kanban-board.component';
import { CandidatePoolComponent } from './components/candidate-pool/candidate-pool.component';
import { CandidatePoolAddEditComponent } from './components/candidate-pool-add-edit/candidate-pool-add-edit.component';
import { LinkImapComponent } from './components/link-imap/link-imap.component';
import { MailLandingComponent } from './components/mail-landing/mail-landing.component';
import { LinkMailAddEditComponent } from './components/link-mail-add-edit/link-mail-add-edit.component';
import { LinkMailComponent } from './components/link-mail/link-mail.component';
import { BulkImportComponent } from './components/bulk-import/bulk-import.component';
import { RevokeFeedbackRequestComponent } from './components/revoke-feedback-request/revoke-feedback-request.component';
import { CandidateQualificationComponent } from './components/candidate-qualification/candidate-qualification.component';
import { CandidateQualificationAddComponent } from './components/candidate-qualification-add/candidate-qualification-add.component';
import { CandidateQualificationActionComponent } from './components/candidate-qualification-action/candidate-qualification-action.component';



const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sso-gateway', component: SSOGatewayComponent },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'job-title', component: JobTitleComponent },
      { path: 'job-title/new', component: JobTitleAddEditComponent },
      { path: 'job-title/:id', component: JobTitleAddEditComponent },
      { path: 'job-position', component: JobPositionComponent },
      { path: 'job-position/new', component: JobPositionAddEditComponent },
      { path: 'job-position/:id', component: JobPositionAddEditComponent },
      { path: 'skill', component: SkillComponent },
      { path: 'skill/new', component: SkillAddEditComponent },
      { path: 'skill/:id', component: SkillAddEditComponent },
      { path: 'qualification', component: QualificationComponent },
      { path: 'qualification/new', component: QualificationAddEditComponent },
      { path: 'qualification/:id', component: QualificationAddEditComponent },
      { path: 'job-description', component: JobDescriptionComponent },
      { path: 'job-description/new', component: JobDescriptionAddEditComponent },
      { path: 'job-description/:id', component: JobDescriptionAddEditComponent },
      { path: 'job-requisition', component: JobRequisitionComponent },
      { path: 'job-requisition/new', component: JobRequisitionAddEditComponent },
      { path: 'job-requisition/:id', component: JobRequisitionAddEditComponent },
      { path: 'job-requisition/view/:id', component: JobRequisitionAddEditComponent },
      { path: 'candidate', component: CandidateComponent },
      { path: 'candidate/interviews/interview/details/:interviewId/:roundId', component: InterviewRoundDetailsComponent },
      { path: 'candidate/candidatefeedback/:interviewId', component: InterviewDetailsComponent },
      { path: 'candidate/new', component: CandidateAddEditComponent },
      { path: 'candidate/:id', component: CandidateAddEditComponent },
      { path: 'candidate-details/:id', component: CandidateDetailsComponent },
      { path: 'interviews', component: CandidateInterviewComponent },
      { path: 'job-requisition-action', component: JobRequisitionActionComponent },
      { path: 'interviews/:interviewId/:roundId', component: InterviewRoundScheduleComponent },
      { path: 'interviews/interview/details/:interviewId', component: InterviewDetailsComponent },
      { path: 'shortlisted-candidates/interview/details/:type/:candidateId/:requisitionId', component: InterviewDetailsComponent },
      { path: 'rejected-candidates/interview/details/:type/:candidateId/:requisitionId', component: InterviewDetailsComponent },
      { path: 'interview-feedbacks', component: InterviewFeedbackComponent },
      { path: 'interview-feedbacks/:roundId', component: InterviewFeedbackActionComponent },
      { path: 'interviews/interview/details/:interviewId/:roundId', component: InterviewRoundDetailsComponent },
      { path: 'interview-actions', component: InterviewActionComponent },
      { path: 'shortlisted-candidates', component: ShortlistedCandidatesComponent },
      { path: 'rejected-candidates', component: RejectedCandidatesComponent },
      { path: 'job-offers', component: JobOfferComponent },
      { path: 'refer-friend', component: ReferComponent },
      { path: 'link-calendar',component: LinkCalendarComponent},
      { path: 'link-calendar/new', component: LinkCalendarAddEditComponent },
      { path: 'link-calendar/:id', component: LinkCalendarAddEditComponent },
      { path: 'link-email',component: LinkMailComponent},
      { path: 'link-email/new', component: LinkMailAddEditComponent },
      { path: 'link-email/:id', component: LinkMailAddEditComponent },
      { path: 'panoramic-view', component: PanoramicViewComponent},
      { path: 'candidate-tags', component: CandidateTagsComponent},
      { path: 'candidate-tags/new', component: CandidateTagsAddEditComponent},
      { path: 'candidate-tags/:id', component: CandidateTagsAddEditComponent},
      { path: 'activities', component: ActivitiesComponent},
      { path: 'board', component: KanbanBoardComponent},
      { path: 'candidate-pool', component: CandidatePoolComponent},
      { path: 'candidate-pool/new', component: CandidatePoolAddEditComponent},
      { path: 'candidate-pool/:id', component: CandidatePoolAddEditComponent},
      { path: 'candidate-view/:id', component: CandidateComponent },
      { path: 'link-imap', component: LinkImapComponent},
      { path: 'candidate-email', component: MailLandingComponent},
      { path: 'bulk-import', component: BulkImportComponent},
      { path: 'candidate-email/:id', component: MailLandingComponent},
     // { path: 'notes/:id', component: NotesComponent },
     {path: 'revoke-feedback', component: RevokeFeedbackRequestComponent},
     {path:'candidate-qualification',component:CandidateQualificationComponent},
     {path:'candidate-qualification/:id',component:CandidateQualificationAddComponent},
     {path:'qualification-action',component:CandidateQualificationActionComponent}
     

    ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] } // need to work on design
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // { enableTracing: true } // <-- debugging purposes only
  )],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
