import { Component, OnInit } from '@angular/core';
import { QualificationModel, QualificationFormBuilder } from '../../shared/models/qualification-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { QualificationService } from '../../core/services/qualification.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-qualification-add-edit',
  templateUrl: './qualification-add-edit.component.html',
  styleUrls: ['./qualification-add-edit.component.css']
})
export class QualificationAddEditComponent implements OnInit {

  qualification: QualificationFormBuilder = new QualificationFormBuilder();
  qualificationModel: QualificationModel = this.qualification.qualificationModel;
  qualificationForm: FormGroup = this.qualification.qualificationForm;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private qualificationService: QualificationService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.qualificationModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.qualificationModel.id ? this.fetchQualification() : null;
  }

  fetchQualification() {
    this.qualificationService.fetchQualification(this.qualificationModel.id).subscribe((res: QualificationModel) => {
      this.qualification.setModelVals(res);
    });
  }

  addUpdateQualification() {
    if (this.qualificationModel.id) {
      this.qualificationService.updateQualification(this.qualificationModel).subscribe((res) => {
        this.messageService.display('Qualification update is successfull! Qualification ID : ' + this.qualificationModel.id, 'OK', 'success');
        this.router.navigate(['/qualification']);
      });
    } else {
      this.qualificationService.addQualification(this.qualificationModel).subscribe((res) => {
        this.messageService.display('Qualification add is successfull! Qualification ID : ' + res, 'OK', 'success');
        this.router.navigate(['/qualification']);
      });
    }
  }

}
